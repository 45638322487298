import { createSelector, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Dayjs } from "dayjs";
import { selectLocalStorage } from "store/localStorage/selector";

type SearchRecord = {
  trip?: {
    from?: string;
    to?: string;
  };
  from?: string;
  to?: string;
};

type FlightBookingState = {
  searchHistory: SearchRecord[];
};

const initialState = { searchHistory: [] } satisfies FlightBookingState as FlightBookingState;

export const flightBookingSlice = createSlice({
  name: "flightBooking",
  initialState,
  reducers: {
    pushSearchRecord(state, action: PayloadAction<SearchRecord>) {
      const newRecord = {
        ...action.payload,
        from: action.payload.from,
        to: action.payload.to,
      };
      const duplicateRecord = state.searchHistory.find(
        (record) =>
          record.trip?.from === newRecord.trip?.from &&
          record.trip?.to === newRecord.trip?.to &&
          record?.from === newRecord?.from &&
          record?.to === newRecord?.to
      );

      if (!duplicateRecord) {
        const newSearchHistory = [newRecord, ...state.searchHistory];

        state.searchHistory = newSearchHistory.slice(0, 3);
      }
    },
  },
  selectors: {
    selectSearchHistory: (storage: FlightBookingState) => storage.searchHistory,
  },
});

export const { pushSearchRecord } = flightBookingSlice.actions;
export default flightBookingSlice.reducer;
export const { selectSearchHistory } = flightBookingSlice.selectors;
