import { createReducer, isAnyOf } from "@reduxjs/toolkit";
import { LongPollingFormAction } from "lib/features/flight-book/payment/definition";
import {
  checkInConfirmationActive,
  clearSelectedPassengers,
  createOLCIFormAction,
  paymentResponseActive,
  updateStepperActive,
} from "./actions";

export type OLCIPageMetaData = {
  checkInConfirmationResponse: boolean;
  clearSelectedPassengerResponse: boolean;
  paymentResponse: boolean;
};
export interface OnlineCheckInReducerProps {
  stepperIndex: number;
  oLCIPageMetaData?: OLCIPageMetaData;
  formAction?: LongPollingFormAction;
}

const initialState: OnlineCheckInReducerProps = {
  stepperIndex: 1,
  oLCIPageMetaData: undefined,
  formAction: undefined,
};

export const OnlineCheckInReducer = createReducer(initialState, (builder) => {
  builder
    .addMatcher(isAnyOf(createOLCIFormAction), (state, action) => {
      state.formAction = action.payload;
    })
    .addMatcher(isAnyOf(updateStepperActive), (state, action) => {
      state.stepperIndex = action.payload;
    })
    .addMatcher(isAnyOf(checkInConfirmationActive), (state, action) => {
      if (state.oLCIPageMetaData) {
        if (state.oLCIPageMetaData.checkInConfirmationResponse) {
          state.oLCIPageMetaData.checkInConfirmationResponse = action.payload;
        }
      } else {
        state.oLCIPageMetaData = {
          checkInConfirmationResponse: action.payload,
          clearSelectedPassengerResponse: action.payload,
          paymentResponse: action.payload,
        };
      }
    })
    .addMatcher(isAnyOf(clearSelectedPassengers), (state, action) => {
      if (state.oLCIPageMetaData) {
        if (state.oLCIPageMetaData.clearSelectedPassengerResponse) {
          state.oLCIPageMetaData.clearSelectedPassengerResponse = action.payload;
        }
      } else {
        state.oLCIPageMetaData = {
          checkInConfirmationResponse: action.payload,
          clearSelectedPassengerResponse: action.payload,
          paymentResponse: action.payload,
        };
      }
    })
    .addMatcher(isAnyOf(paymentResponseActive), (state, action) => {
      if (state.oLCIPageMetaData) {
        if (state.oLCIPageMetaData.paymentResponse) {
          state.oLCIPageMetaData.paymentResponse = action.payload;
        }
      } else {
        state.oLCIPageMetaData = {
          checkInConfirmationResponse: action.payload,
          clearSelectedPassengerResponse: action.payload,
          paymentResponse: action.payload,
        };
      }
    });
});
