import { getApi, memberAuthEndPoints } from "api";
import { GeneralProfile } from "./types";

export type FetchGeneralProfileRes = {
  metadata: {
    app_version: string;
    status_code: number;
    trace_id: string;
  };
} & GeneralProfile;

export const fetchGeneralProfile = async () =>
  getApi(memberAuthEndPoints.getProfile) as Promise<FetchGeneralProfileRes>;

export { decodeTokenProfile } from "@hkexpressairwayslimited/ui";
