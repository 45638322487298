import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { CreditDataExchangeResponse } from "lib/features/flight-book/payment/definition";
import { CCEncryptionToken, creditCMPILookupResp } from "lib/features/flight-book/payment/service";
import { RootState } from "store";

export type CCPaymentMetaData = {
  creditDataExchange?: CreditDataExchangeResponse;
  creditCMPILookup?: creditCMPILookupResp;
  cardinalStatus?: boolean;
  token?: CCEncryptionToken;
  orderNumber?: string;
  amount?: number;
};

const initialState: CCPaymentMetaData = {
  creditDataExchange: undefined,
  creditCMPILookup: undefined,
  cardinalStatus: undefined,
  token: undefined,
  orderNumber: undefined,
  amount: undefined,
};

export const ccPaymentMetaData = createSlice({
  name: "ccPaymentMetaData",
  initialState,
  reducers: {
    reset: (state) => {
      Object.assign(state, initialState);
    },
    storeCCPaymentMetaData: (state, action: PayloadAction<Partial<CCPaymentMetaData>>) => {
      Object.assign(state, action.payload);
    },
  },
  selectors: {
    selectCCPaymentMetaData: (state): CCPaymentMetaData => state,
  },
});

export const { storeCCPaymentMetaData, reset } = ccPaymentMetaData.actions;
export default ccPaymentMetaData.reducer;
export const { selectCCPaymentMetaData } = ccPaymentMetaData.getSelectors((state: RootState) => {
  return state.session.ccPaymentMetaData;
});
