import { createSlice } from "@reduxjs/toolkit";

// interface PaymentState {
//   paymentLink: string;
// }

const initialState = {
  token: "",
  emailTo: "",
  subject: "",
  emailBody: "",
  paymentLink: "",
  summary: [],
};

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    storeToken: (state, action) => {
      console.log("storeToken action.payload", action.payload);
      state.token = action.payload;
    },
    storePaymentLink: (state, action) => {
      console.log("storePaymentLink action.payload", action.payload);
      state.paymentLink = action.payload;
    },
    storeEmailContent: (state, action) => {
      console.log("storeEmailContent action.payload", action.payload);
      state.emailTo = action.payload.emailTo;
      state.subject = action.payload.subject;
      state.emailBody = action.payload.emailBody;
    },
    storeSummary: (state, action) => {
      console.log("storeSummary action.payload", action.payload);
      state.summary = action.payload;
    },
  },
});

export const { storeToken, storePaymentLink, storeEmailContent, storeSummary } = paymentSlice.actions;
export default paymentSlice.reducer;
