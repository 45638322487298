import { persistReducer } from "redux-persist";

import { LocalStorage } from "store/ssr-safe-storage";
import { reducer } from "./reducers";

export const localPersistConfig = {
  key: "local",
  storage: LocalStorage,
};

export * from "./selector";

export const localStorageReducer = persistReducer(localPersistConfig, reducer);
