import { endpoints, fetchAPI } from "api";

export const getFlightRoute = async () => {
  const result = await fetchAPI(endpoints.flightRouteMapping, {
    method: "GET",
    cache: "no-store",
    headers: { "Content-Type": "application/json" },
  });
  return result;
};
