import { createReducer, isAnyOf } from "@reduxjs/toolkit";
import { initApp } from "modules/common/common/actions/initAction";
import { ErrorModel } from "modules/common/common/types/errorType";
import {
  fetchToken,
  fetchTokenFailure,
  fetchTokenSuccess,
  logout,
  refreshToken,
  refreshTokenFailure,
  refreshTokenNotExist,
  refreshTokenSuccess,
} from "../actions/authAction";

interface AuthState {
  isLogin: boolean;
  accessToken: string | undefined;
  idToken: string | undefined;
  refreshToken: string | undefined;
  mlcAccessToken: string | undefined;
  isLoading: boolean;
  error: ErrorModel | undefined;
}

const initialState = {
  accessToken: undefined,
  idToken: undefined,
  refreshToken: undefined,
  mlcAccessToken: undefined,
  isLoading: true, // By default, auth checking will be loaded immediately once app opened
  error: undefined,
} as AuthState;

export const authReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(logout, (state) => {
      state = initialState;
    })
    .addMatcher(isAnyOf(initApp, fetchToken), (state) => {
      state.isLoading = true;
    })
    .addMatcher(isAnyOf(refreshToken), (state, action) => {
      if (!action.payload.backgroundMode) {
        state.isLoading = true;
      }
    })
    .addMatcher(isAnyOf(fetchTokenSuccess, refreshTokenSuccess), (state, action) => {
      Object.assign(state, action.payload);

      state.isLoading = false;
    })
    .addMatcher(isAnyOf(fetchTokenFailure, refreshTokenFailure), (state, action) => {
      state.isLoading = false;
      state.error = action.payload.error;
      state.accessToken = initialState.accessToken;
      state.idToken = initialState.idToken;
      state.refreshToken = initialState.refreshToken;
      state.mlcAccessToken = initialState.mlcAccessToken;
    })
    .addMatcher(isAnyOf(refreshTokenNotExist), (state, action) => {
      state.isLoading = false;
      state.accessToken = initialState.accessToken;
      state.idToken = initialState.idToken;
      state.refreshToken = initialState.refreshToken;
      state.mlcAccessToken = initialState.mlcAccessToken;
    });
});
