import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "store";

type MemberState = {};

const initialState: MemberState = {};

// export const initTokens = createAction<{ mlc_access_token: string; mlcRefreshToken: string }>("member/initTokens");

export const memberSlice = createSlice({
  name: "member",
  initialState,
  reducers: {},
  selectors: {},
});

export const {} = memberSlice.actions;
export default memberSlice.reducer;
export const {} = memberSlice.getSelectors((state: RootState) => {
  return state.session.member;
});
