import { INIT_APP_START } from "modules/common/common/actions/initAction";
// import { postRequest } from "modules/common/common/utils/network-utils";
import { clearAuthTokens, decodeTokenPayLoad } from "@hkexpressairwayslimited/ui";
import { memberAuthEndPoints, postApi } from "api";
import {
  FETCH_TOKEN_SUCCESS,
  LOGOUT,
  REFRESH_TOKEN_END,
  REFRESH_TOKEN_START,
  fetchTokenSuccess as fetchTokenSuccessAction,
  refreshToken as refreshTokenAction,
  refreshTokenEnd,
} from "modules/features/auth/actions/authAction";
import {
  AccessTokenPayload,
  getAccessToken,
  getIdToken,
  getMlcAccessToken,
  getRefreshToken,
  persistAccessToken,
  persistIdToken,
  persistMlcAccessToken,
  persistRefreshToken,
} from "modules/features/auth/utils/authUtil";
import { initTokenProfile, setMlcTokenProfile, setTokenProfile } from "modules/features/member/actions";
import { Effect, call, delay, put, take, takeLatest } from "redux-saga/effects";
import { requestLogout } from "../service";
import { FetchTokenCustomAction } from "../types/authType";

// /**
//  * Normally you need to Form POST redirect to the Login API with a return URL.
//  * The Login API should 302 redirect to the return URL with a code.
//  * Then you should grab the code from the URL for getting access token in next step.
//  * @param action
//  */
// function* doLogin(action: any) {
//   const url = new URL(window.location.href);
//   const code = url.searchParams.get("code");
//   if (code) {
//     yield put(fetchTokenAction({ code }));
//   }
// }

// /** Fetch access token after login */
// function* fetchToken(action: FetchTokenAction) {
//   const { code } = action.payload;

//   // const url = envConfig.tokenUrl;
//   const { response, error } = yield call(postRequest, url, {
//     code,
//   });

//   if (!error) {
//     const { accessToken, refreshToken } = response.data;

//     // Refresh token should be stored for long-term usage. Normally it valids for 30 days.
//     const DAYS_30 = 30;
//     const HOUR_1 = (1 / 24 / 60) * 60;
//     const expires = getRememberMe() ? DAYS_30 : HOUR_1;
//     yield call(persistRefreshToken, refreshToken, expires);

//     // Access token is for runtime usage only
//     // It should be refreshed every 30 mins and do not store it.
//     yield put(fetchTokenSuccess({ accessToken, refreshToken }));
//   } else {
//     yield put(fetchTokenFailure({ error }));
//   }
// }

function* fetchTokenSuccess(action: FetchTokenCustomAction) {
  persistAccessToken(action.payload.accessToken);
  persistIdToken(action.payload.idToken);
  persistMlcAccessToken(action.payload.mlcAccessToken);
  persistRefreshToken(action.payload.refreshToken);
  yield put(initTokenProfile());
}

/**
 * Refresh token when open the app
 */
function* refreshToken(): Generator<Effect, void, any> {
  const access_token = getAccessToken();
  if (access_token) {
    const payload = decodeTokenPayLoad<AccessTokenPayload>(access_token);

    // If token is not expired, do nothing
    if (payload && payload.exp * 1000 > Date.now()) {
      return;
    }
  }
  const refresh_token = getRefreshToken();
  if (!refresh_token) {
    return;
  }

  try {
    const response = yield call(
      postApi,
      memberAuthEndPoints.refreshToken,
      {
        refresh_token,
      },
      undefined,
      {
        credentials: true,
      }
    );

    yield put(
      fetchTokenSuccessAction({
        accessToken: response.access_token,
        idToken: response.id_token,
        mlcAccessToken: response.mlc_access_token,
      })
    );
  } catch (err: any) {
    yield call(clearTokens);
  }
  yield put(refreshTokenEnd());
}

function* runRefreshTokenTimer() {
  const TIMER_INTERVAL = 1000;
  while (true) {
    try {
      const backgroundMode = true;
      const access_token = getAccessToken();
      const refresh_token = getRefreshToken();

      if (access_token || !refresh_token) {
        yield delay(TIMER_INTERVAL);
        continue;
      }

      yield put(refreshTokenAction({ backgroundMode }));
      yield take(REFRESH_TOKEN_END);
      yield delay(TIMER_INTERVAL);
    } catch (err) {
      yield delay(TIMER_INTERVAL);
    }
  }
}

function* initApp() {
  const accessToken = getAccessToken();
  const refreshToken = getRefreshToken();
  const mlcAccessToken = getMlcAccessToken();
  const idToken = getIdToken();

  if (refreshToken) {
    yield put(fetchTokenSuccessAction({ accessToken, refreshToken, mlcAccessToken, idToken }));
  }
}

function* logoutAuth() {
  try {
    yield call(requestLogout);
    yield call(clearTokens);
  } catch (err) {
    yield call(clearTokens);
  }
}

function* clearTokens() {
  clearAuthTokens();
  yield put(setTokenProfile(undefined));
  yield put(setMlcTokenProfile(undefined));
}

export function* authSaga() {
  yield takeLatest(INIT_APP_START, runRefreshTokenTimer);
  yield takeLatest(INIT_APP_START, initApp);

  yield takeLatest(FETCH_TOKEN_SUCCESS, fetchTokenSuccess);
  yield takeLatest(REFRESH_TOKEN_START, refreshToken);

  yield takeLatest(LOGOUT, logoutAuth);
}
