import { ArItem, ArItems, Journey } from "@hkexpressairwayslimited/ui";
import { MMBJourney } from "lib/features/manage-my-booking/my-trips/definition";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectTripJourneys } from "store/sessionStorage/slices/flightBookingSlice";
import { EArItemType } from "ui/features/flight-booking/add-extras";
import { calcAllPickedArItems } from "./calcAllPickedArItems";
export function useSegmentCost(str?: EArItemType, tripArItems?: ArItems[]) {
  const storedTripArItem = getStoredSegmentsArItems(useSelector(selectTripJourneys));
  return useMemo(() => {
    return calcAllPickedArItems(
      tripArItems ? tripArItems : storedTripArItem ? storedTripArItem : [],
      str ? (e) => e.type === str : undefined,
      true
    );
  }, [tripArItems, storedTripArItem, str]);
}

export function getStoredSegmentsArItems(journeys?: Journey[] | MMBJourney[]) {
  return (
    journeys?.reduce<ArItems[]>(
      (a, c) => [
        ...a,
        ...c.segments.reduce((a1: any[], c1) => {
          a1.push(c1.arItems);
          return a1;
        }, []),
      ],
      []
    ) ?? undefined
  );
}

export function getStoredSelectedSegmentArItems(journeys?: Journey[] | MMBJourney[]) {
  return (
    journeys?.reduce((a: ArItem[], c: Journey | MMBJourney) => {
      return a.concat(
        c.segments.reduce((a1: ArItem[], c1) => {
          return a1.concat([...c1.arItems.selected]);
        }, [])
      );
    }, []) ?? undefined
  );
}
