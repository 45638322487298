"use client";
import { ArItem, IBEPassengerDetail, SearchingDetail, TripDetail } from "@hkexpressairwayslimited/ui";
import { createReducer, isAnyOf } from "@reduxjs/toolkit";
import { PassengerKey } from "[lang]/flight-booking/detail/definition";
import { LongPollingFormAction, PaymentMetaData } from "lib/features/flight-book/payment/definition";
import { remove } from "lodash";
import { EArItemType, EBaggageArCode, SelectableArItem } from "ui/features/flight-booking/add-extras";
import {
  addSegmentSelectedSeat,
  confirmPaxMeal,
  confirmSIMCard,
  confirmSelectedBaggage,
  confirmSport,
  confirmUFirst,
  createFormAction,
  ibeNskTokenExpireNotify,
  pushJourney,
  removeSegmentSelectedSeat,
  removeTripDetail,
  resetState,
  storeFlightSearching,
  storePassengerDetail,
  storePassengerKey,
  storePaymentMetaData,
  storeTripDetail,
  updateAddExtrasPageMetaData,
  updateBaggage,
  updateJourney,
  updatePaxMeal,
  updateSIMCard,
  updateSport,
  updateStepperActive,
  updateUFirst,
} from "./actions";
export type SelectedArItems = {
  tripId?: string;
  journeyKey?: string;
  segmentKey?: string;
  arItems: ArItem[];
};
export type AddExtrasPageMetaData = {
  baggageSelected: {
    [paxId: string]: SelectableArItem[];
  };
  confirmedItems: Partial<Record<EArItemType, boolean>>;
  selectedArItems: SelectedArItems[];
};
export interface FlightBookingReducerProps<C = undefined> {
  searchingDetail?: SearchingDetail;
  passengerDetail?: IBEPassengerDetail<C>;
  tripDetail?: TripDetail;
  stepperIndex: number;
  addExtrasPageMetaData: AddExtrasPageMetaData;
  nskTokenExpireNotify: boolean;
  passengerKey?: PassengerKey[];
  formAction?: LongPollingFormAction;
  paymentMetaData?: PaymentMetaData;
}

const initialState: FlightBookingReducerProps = {
  stepperIndex: 0,
  searchingDetail: undefined,
  tripDetail: undefined,
  passengerDetail: undefined,
  addExtrasPageMetaData: {
    baggageSelected: {},
    confirmedItems: {},
    selectedArItems: [],
  },
  nskTokenExpireNotify: false,
  passengerKey: undefined,
  formAction: undefined,
  paymentMetaData: undefined,
};

export const FlightBookingReducer = createReducer(initialState, (builder) => {
  builder
    .addMatcher(isAnyOf(storeFlightSearching), (state, action) => {
      state.searchingDetail = action.payload;
    })
    .addMatcher(isAnyOf(storeTripDetail), (state, action) => {
      state.tripDetail = action.payload;
    })
    .addMatcher(isAnyOf(storePassengerDetail), (state, action) => {
      state.passengerDetail = action.payload;
    })
    .addMatcher(isAnyOf(updateStepperActive), (state, action) => {
      state.stepperIndex = action.payload;
    })
    .addMatcher(isAnyOf(updateJourney), (state, { payload }) => {
      state.tripDetail && (state.tripDetail.journeys = payload);
    })
    .addMatcher(isAnyOf(removeTripDetail), (state, action) => {
      if (state.tripDetail) {
        state.tripDetail.journeys = state.tripDetail?.journeys.filter((journey) => {
          return journey.index !== action.payload;
        });
      }
    })
    .addMatcher(isAnyOf(pushJourney), (state, action) => {
      state.tripDetail && state.tripDetail.journeys[action.payload.index === 1 ? "push" : "unshift"](action.payload);
    })
    .addMatcher(isAnyOf(resetState), (state) => {
      state.passengerDetail = initialState.passengerDetail;
      state.tripDetail = initialState.tripDetail;
      state.addExtrasPageMetaData = initialState.addExtrasPageMetaData;
      state.paymentMetaData = initialState.paymentMetaData;
    })
    .addMatcher(
      isAnyOf(updateBaggage),
      (state, { payload: { journeyKey, selectedBaggage, paxId, active, tripId } }) => {
        const currentItem = state.addExtrasPageMetaData.selectedArItems.find(
          (e) =>
            e.tripId === tripId &&
            e.journeyKey === journeyKey &&
            e.segmentKey === undefined &&
            e.arItems.find((n) => n.type === EArItemType.BAGGAGE)
        );
        if (currentItem) {
          const activeOption = currentItem.arItems.find((e) => e.code === selectedBaggage.code);
          if (activeOption) {
            const activeUserSection = activeOption.byPax?.find((e) => e.paxId === paxId);
            if (activeUserSection) {
              activeUserSection.amount =
                selectedBaggage.code === EBaggageArCode._0KG
                  ? active
                    ? 0
                    : 1
                  : activeUserSection.amount + (active ? -1 : 1);
            } else {
              activeOption?.byPax?.push({
                paxId: paxId,
                amount: active ? 0 : 1,
              });
            }
          } else {
            currentItem.arItems.push({
              type: selectedBaggage.type,
              code: selectedBaggage.code,
              byPax: [
                {
                  paxId: paxId,
                  amount: active ? 0 : 1,
                },
              ],
            });
          }
        } else {
          state.addExtrasPageMetaData.selectedArItems.push({
            tripId,
            journeyKey,
            arItems: [
              {
                type: selectedBaggage.type,
                code: selectedBaggage.code,
                byPax: [
                  {
                    paxId: paxId,
                    amount: active ? 0 : 1,
                  },
                ],
              },
            ],
          });
        }
      }
    )
    .addMatcher(isAnyOf(confirmSelectedBaggage), (state, { payload }) => {
      if (state.tripDetail) {
        state.tripDetail?.journeys.forEach((m) => {
          remove(m.arItems.selected, (e) => e.type === EArItemType.BAGGAGE);
          payload?.forEach((e) => {
            if (m.journey_key === e.journeyKey) {
              m.arItems.selected = m.arItems.selected.concat(e.arItems);
            }
          });
        });
      }
    })
    .addMatcher(isAnyOf(updateUFirst), (state, { payload: { passengers, added, journeyKey, code, tripId } }) => {
      remove(
        state.addExtrasPageMetaData.selectedArItems,
        (e) =>
          e.arItems.find((n) => n.type === EArItemType.U_FIRST) &&
          e.tripId === tripId &&
          e.journeyKey === journeyKey &&
          e.segmentKey === undefined
      );
      if (!added) {
        state.addExtrasPageMetaData.selectedArItems.push({
          tripId,
          journeyKey,
          arItems: [
            {
              type: EArItemType.U_FIRST,
              code,
              byPax: passengers.map((e) => ({
                paxId: e.id,
                amount: 1,
              })),
            },
          ],
        });
      }
    })
    .addMatcher(isAnyOf(confirmUFirst), (state, { payload }) => {
      if (state.tripDetail) {
        state.tripDetail?.journeys.forEach((m) => {
          remove(m.arItems.selected, (e) => e.type === EArItemType.U_FIRST);
          if (payload) {
            m.arItems.selected = m.arItems.selected.concat(
              payload.find((e) => e.journeyKey === m.journey_key)?.arItems ?? []
            );
          }
        });
      }
    })
    .addMatcher(isAnyOf(updateSIMCard), (state, { payload: { added, paxId, code, tripId } }) => {
      const currentItem = state.addExtrasPageMetaData.selectedArItems.find(
        (e) =>
          e.tripId === tripId &&
          e.journeyKey === undefined &&
          e.segmentKey === undefined &&
          e.arItems.find((n) => n.type === EArItemType.SIM_CARD)
      );
      if (currentItem) {
        const arItem = currentItem.arItems.find((e) => e.type === EArItemType.SIM_CARD);
        if (arItem?.byPax) {
          if (added) {
            remove(arItem.byPax, (e) => e.paxId === paxId);
          } else {
            arItem.byPax.push({
              paxId: paxId,
              amount: 1,
            });
          }
        } else {
          if (!added)
            currentItem.arItems.push({
              type: EArItemType.SIM_CARD,
              code,
              byPax: [
                {
                  paxId: paxId,
                  amount: 1,
                },
              ],
            });
        }
      } else {
        state.addExtrasPageMetaData.selectedArItems.push({
          tripId,
          arItems: [
            {
              type: EArItemType.SIM_CARD,
              code,
              byPax: [
                {
                  paxId,
                  amount: 1,
                },
              ],
            },
          ],
        });
      }
    })
    .addMatcher(isAnyOf(confirmSIMCard), (state, { payload }) => {
      if (state.tripDetail) {
        remove(state.tripDetail.arItems.selected, (e) => e.type === EArItemType.SIM_CARD);
        payload.forEach((e) => {
          if (state.tripDetail && e.tripId === state.tripDetail?.id) {
            state.tripDetail.arItems.selected = state.tripDetail.arItems.selected.concat(e.arItems);
          }
        });
      }
    })
    .addMatcher(isAnyOf(updateSport), (state, { payload: { tripId, journeyKey, added, paxId, code } }) => {
      const currentItem = state.addExtrasPageMetaData.selectedArItems.find(
        (e) =>
          e.tripId === tripId &&
          e.journeyKey === journeyKey &&
          e.segmentKey === undefined &&
          e.arItems.find((n) => n.type === EArItemType.SPORT_EQUIPMENT)
      );
      if (currentItem) {
        const arItem = currentItem.arItems.find((e) => e.type === EArItemType.SPORT_EQUIPMENT);
        if (arItem?.byPax) {
          if (added) {
            remove(arItem.byPax, (e) => e.paxId === paxId);
          } else {
            arItem.byPax.push({
              paxId: paxId,
              amount: 1,
            });
          }
        } else {
          if (!added)
            currentItem.arItems.push({
              type: EArItemType.SPORT_EQUIPMENT,
              code,
              byPax: [
                {
                  paxId: paxId,
                  amount: 1,
                },
              ],
            });
        }
      } else {
        state.addExtrasPageMetaData.selectedArItems.push({
          tripId,
          journeyKey,
          arItems: [
            {
              type: EArItemType.SPORT_EQUIPMENT,
              code,
              byPax: [
                {
                  paxId,
                  amount: 1,
                },
              ],
            },
          ],
        });
      }
    })
    .addMatcher(isAnyOf(confirmSport), (state, { payload }) => {
      if (state.tripDetail) {
        state.tripDetail.journeys.forEach((e) => {
          remove(e.arItems.selected, (e) => e.type === EArItemType.SPORT_EQUIPMENT);
          payload.forEach((m) => {
            if (state.tripDetail && m.tripId === state.tripDetail?.id && m.journeyKey === e.journey_key) {
              e.arItems.selected = e.arItems.selected.concat(m.arItems);
            }
          });
        });
      }
    })
    .addMatcher(isAnyOf(updateAddExtrasPageMetaData), (state, action) => {
      const { baggageSelected, confirmedItems } = action.payload;
      if (baggageSelected) {
        Object.keys(baggageSelected).forEach(
          (e) => (state.addExtrasPageMetaData.baggageSelected[e] = baggageSelected[e])
        );
      }
      if (confirmedItems) {
        state.addExtrasPageMetaData.confirmedItems = {
          ...state.addExtrasPageMetaData.confirmedItems,
          ...confirmedItems,
        };
      }
    })
    .addMatcher(
      isAnyOf(updatePaxMeal),
      (state, { payload: { tripId, selectedJourneyKey, selectedSegmentKey, paxId, mealCode, amount } }) => {
        const currentItem = state.addExtrasPageMetaData.selectedArItems.find(
          (e) =>
            e.journeyKey === selectedJourneyKey &&
            e.segmentKey === selectedSegmentKey &&
            e.tripId === tripId &&
            e.arItems.find((n) => n.type === EArItemType.MEAL)
        );
        if (currentItem) {
          const mealSection = currentItem.arItems.find((e) => e.code === mealCode);
          if (mealSection) {
            const mealSelectedSection = mealSection.byPax?.find((e) => e.paxId === paxId);
            if (mealSelectedSection) {
              mealSelectedSection.amount = amount;
            } else {
              mealSection.byPax?.push({
                paxId,
                amount,
              });
            }
          } else {
            currentItem.arItems.push({
              type: EArItemType.MEAL,
              code: mealCode,
              byPax: [
                {
                  paxId,
                  amount,
                },
              ],
            });
          }
        } else {
          state.addExtrasPageMetaData.selectedArItems.push({
            tripId,
            journeyKey: selectedJourneyKey,
            segmentKey: selectedSegmentKey,
            arItems: [
              {
                type: EArItemType.MEAL,
                code: mealCode,
                byPax: [
                  {
                    paxId,
                    amount,
                  },
                ],
              },
            ],
          });
        }
      }
    )
    .addMatcher(isAnyOf(confirmPaxMeal), (state, { payload }) => {
      if (payload) {
        payload.forEach((e) => {
          state.tripDetail?.journeys.forEach((n) => {
            if (n.journey_key === e.journeyKey) {
              n.segments.forEach((m) => {
                if (m.segment_key === e.segmentKey) {
                  remove(m.arItems.selected, (e) => e.type === EArItemType.MEAL);
                  m.arItems.selected = m.arItems.selected.concat(e.arItems);
                }
              });
            }
          });
        });
      } else {
        state.tripDetail?.journeys.forEach((n) => {
          n.segments.forEach((m) => {
            remove(m.arItems.selected, (e) => e.type === EArItemType.MEAL);
          });
        });
        remove(state.addExtrasPageMetaData.selectedArItems, (n) => n.arItems.find((e) => e.type === EArItemType.MEAL));
      }
    })
    .addMatcher(isAnyOf(addSegmentSelectedSeat), (state, action) => {
      state.tripDetail?.journeys.forEach((journey) => {
        const segmentIndex = journey.segments.findIndex((segment) => segment.segment_key === action.payload.segmentKey);
        if (segmentIndex > -1) {
          let seatFlag = false;
          journey.segments[segmentIndex].arItems.selected.forEach((arItem) => {
            if (arItem.type === "SEAT" && arItem.byPax && arItem.byPax[0].paxId === action.payload.paxId) {
              arItem.code = action.payload.seatDesignator;
              arItem.price = action.payload.seatPrice;
              arItem.unit_key = action.payload.unitKey;
              seatFlag = true;
            }
          });
          if (!seatFlag) {
            journey.segments[segmentIndex].arItems.selected.push({
              type: "SEAT",
              code: action.payload.seatDesignator,
              price: action.payload.seatPrice,
              unit_key: action.payload.unitKey,
              byPax: [
                {
                  paxId: action.payload.paxId,
                  amount: 1,
                },
              ],
            });
          }
        }
      });
    })
    .addMatcher(isAnyOf(removeSegmentSelectedSeat), (state, action) => {
      state.tripDetail?.journeys.forEach((journey) => {
        const segmentIndex = journey.segments.findIndex((segment) => segment.segment_key === action.payload.segmentKey);
        if (segmentIndex > -1) {
          const arItems = journey.segments[segmentIndex].arItems.selected.filter((arItem) => {
            return !(arItem.type === "SEAT" && arItem.byPax && arItem.byPax[0].paxId === action.payload.paxId);
          });
          journey.segments[segmentIndex].arItems.selected = arItems;
        }
      });
    })
    .addMatcher(isAnyOf(ibeNskTokenExpireNotify), (state, action) => {
      console.log("saga nskTokenExpireNotify");
      state.nskTokenExpireNotify = action.payload;
    })
    .addMatcher(isAnyOf(storePassengerKey), (state, action) => {
      state.passengerKey = action.payload;
    })
    .addMatcher(isAnyOf(createFormAction), (state, action) => {
      state.formAction = action.payload;
    })
    .addMatcher(isAnyOf(storePaymentMetaData), (state, action) => {
      state.paymentMetaData = action.payload;
    });
});
