import { createReducer, isAnyOf } from "@reduxjs/toolkit";
import { closeLoadingBackdrop, showLoadingBackdrop } from "../actions/LoadingBackdropAction";

export interface LoadingBackdropState {
  loadingCount: number;
}

const initialState = {
  loadingCount: 0,
} as LoadingBackdropState;

export const loadingBackdropReducer = createReducer(initialState, (builder) => {
  builder
    .addMatcher(isAnyOf(showLoadingBackdrop), (state, action) => {
      state.loadingCount += 1;
    })
    .addMatcher(isAnyOf(closeLoadingBackdrop), (state, action) => {
      if (state.loadingCount > 0) {
        state.loadingCount -= 1;
      }
    });
});
