import { ArItem, EPaxType, MMBPax } from "@hkexpressairwayslimited/ui";
import { createSlice } from "@reduxjs/toolkit";
import { AnalyticPaymentDetail } from "analytic";
import { PaymentMetaData, PaymentOption, PaymentPageDetail } from "lib/features/flight-book/payment/definition";
import { ManageMyBookingTripDetail } from "lib/features/manage-my-booking/my-trips/definition";
import { remove } from "lodash";
import { RootState } from "store";
import { EArItemType, EBaggageArCode, SelectableArItem } from "ui/features/flight-booking/add-extras/d";

export type OnlineCheckInPassengersType = MMBPax & { infantDetail?: MMBPax };
export type OnlineCheckInTripDetailType = ManageMyBookingTripDetail & { id?: string };
export type OnlineCheckInConfirmationDetailType = {
  isSuccess: boolean;
  failPassengerId: string[] | null;
};
export type SelectedArItems = {
  tripId?: string;
  journeyKey?: string;
  segmentKey?: string;
  arItems: ArItem[];
};

export type OnlineCheckInAddExtrasPageMetaData = {
  baggageSelected: {
    [paxId: string]: SelectableArItem[];
  };
  confirmedItems: Partial<Record<EArItemType, boolean>>;
  selectedArItems: SelectedArItems[];
};
export interface OnlineCheckInReducerProps {
  stepperIndex: number;
  paymentOption?: PaymentOption[];
  onlineCheckInPassengers?: OnlineCheckInPassengersType[];
  onlineCheckInAddExtraMetaData: OnlineCheckInAddExtrasPageMetaData;
  onlineCheckInTripDetail?: OnlineCheckInTripDetailType;
  onlineCheckInConfirmationDetail: OnlineCheckInConfirmationDetailType | null;
  paymentMetaData?: PaymentMetaData;
  paymentPageDetail: PaymentPageDetail;
  analyticPaymentDetail?: AnalyticPaymentDetail;
}

const initialState: OnlineCheckInReducerProps = {
  stepperIndex: 1,
  paymentOption: undefined,
  onlineCheckInPassengers: undefined,
  onlineCheckInAddExtraMetaData: {
    baggageSelected: {},
    confirmedItems: {},
    selectedArItems: [],
  },
  onlineCheckInTripDetail: undefined,
  onlineCheckInConfirmationDetail: null,
  paymentMetaData: undefined,
  paymentPageDetail: {},
  analyticPaymentDetail: undefined,
};

export const onlineCheckInSlice = createSlice({
  name: "onlineCheckIn",
  initialState,
  reducers: {
    resetOnlineCheckInState: (state, action) => {
      state.paymentOption = initialState.paymentOption;
      state.onlineCheckInPassengers = initialState.onlineCheckInPassengers;
      state.onlineCheckInAddExtraMetaData = initialState.onlineCheckInAddExtraMetaData;
      state.onlineCheckInTripDetail = initialState.onlineCheckInTripDetail;
      state.onlineCheckInConfirmationDetail = initialState.onlineCheckInConfirmationDetail;
      state.paymentPageDetail = initialState.paymentPageDetail;
    },
    storeOnlineCheckInPassengers: (state, action) => {
      state.onlineCheckInPassengers = action.payload;
    },
    updateAnalyticPaymentDetail: (state, action) => {
      if (state.analyticPaymentDetail && action.payload) {
        state.analyticPaymentDetail = {
          ...state.analyticPaymentDetail,
          ...action.payload,
        };
      } else {
        state.analyticPaymentDetail = action.payload;
      }
    },
    storeOnlineCheckInConfirmationDetail: (state, action) => {
      state.onlineCheckInConfirmationDetail = action.payload;
    },
    updateOnlineCheckInBaggage: (state, { payload: { journeyKey, selectedBaggage, paxId, active, tripId } }) => {
      const currentItem = state.onlineCheckInAddExtraMetaData?.selectedArItems.find(
        (e) =>
          e.tripId === tripId &&
          e.journeyKey === journeyKey &&
          e.segmentKey === undefined &&
          e.arItems.find((n) => n.type === EArItemType.BAGGAGE)
      );
      if (currentItem) {
        const activeOption = currentItem.arItems.find((e) => e.code === selectedBaggage.code);
        if (activeOption) {
          const activeUserSection = activeOption.byPax?.find((e) => e.paxId === paxId);
          if (activeUserSection) {
            activeUserSection.amount =
              selectedBaggage.code === EBaggageArCode._0KG
                ? active
                  ? 0
                  : 1
                : activeUserSection.amount + (active ? -1 : 1);
          } else {
            activeOption?.byPax?.push({
              paxId: paxId,
              amount: active ? 0 : 1,
            });
          }
        } else {
          currentItem.arItems.push({
            type: selectedBaggage.type,
            code: selectedBaggage.code,
            byPax: [
              {
                paxId: paxId,
                amount: active ? 0 : 1,
              },
            ],
          });
        }
        if (!active) {
          if (selectedBaggage.code === EBaggageArCode._0KG) {
            const options = currentItem.arItems.filter((e) => e.code !== selectedBaggage.code);
            options?.forEach((item) => {
              const userSection = item.byPax?.find((e) => e.paxId === paxId);
              if (userSection) {
                userSection.amount = 0;
              }
            });
          } else {
            const zeroOption = currentItem.arItems.find((e) => e.code === EBaggageArCode._0KG);
            if (zeroOption) {
              const userSection = zeroOption.byPax?.find((e) => e.paxId === paxId);
              if (userSection) {
                userSection.amount = 0;
              }
            }
          }
        }
      } else {
        state.onlineCheckInAddExtraMetaData?.selectedArItems.push({
          tripId,
          journeyKey,
          arItems: [
            {
              type: selectedBaggage.type,
              code: selectedBaggage.code,
              byPax: [
                {
                  paxId: paxId,
                  amount: active ? 0 : 1,
                },
              ],
            },
          ],
        });
      }
    },
    confirmOnlineCheckInSelectedBaggage: (state, { payload }) => {
      if (state.onlineCheckInTripDetail) {
        state.onlineCheckInTripDetail?.journeys.forEach((m) => {
          remove(m.arItems.selected, (e) => e.type === EArItemType.BAGGAGE);
          payload?.forEach(
            (e: {
              journeyKey: string;
              arItems: ConcatArray<{
                type: string;
                code: string;
                price?: number | undefined;
                currency_code?: string | undefined;
                availability?: number | null | undefined;
                cms_content_key?: string | undefined;
                ssr_by_passengers?: { ssr_key: string; price: number; passenger_key: string }[] | undefined;
                is_pre_order?: boolean | undefined;
                is_vegetarian?: boolean | undefined;
                byPax?: { paxId: string; amount: number }[] | undefined;
                arMetaData?: { [x: string]: string | number | boolean } | undefined;
                unit_key?: string | undefined;
              }>;
            }) => {
              if (m.journey_key === e.journeyKey) {
                m.arItems.selected = m.arItems.selected.concat(e.arItems);
              }
            }
          );
        });
      }
    },
    updateOnlineCheckInAddExtrasPageMetaData: (state, action) => {
      const { baggageSelected, confirmedItems } = action.payload;
      if (baggageSelected) {
        Object.keys(baggageSelected).forEach(
          (e) => (state.onlineCheckInAddExtraMetaData.baggageSelected[e] = baggageSelected[e])
        );
      }
      if (confirmedItems) {
        state.onlineCheckInAddExtraMetaData.confirmedItems = {
          ...state.onlineCheckInAddExtraMetaData?.confirmedItems,
          ...confirmedItems,
        };
      }
    },
    confirmOnlineCheckInPaxMeal: (state, { payload }) => {
      if (payload) {
        payload.forEach(
          (e: {
            journeyKey: string;
            segmentKey: string;
            arItems: ConcatArray<{
              type: string;
              code: string;
              price?: number | undefined;
              currency_code?: string | undefined;
              availability?: number | null | undefined;
              cms_content_key?: string | undefined;
              ssr_by_passengers?: { ssr_key: string; price: number; passenger_key: string }[] | undefined;
              is_pre_order?: boolean | undefined;
              is_vegetarian?: boolean | undefined;
              byPax?: { paxId: string; amount: number }[] | undefined;
              arMetaData?: { [x: string]: string | number | boolean } | undefined;
              unit_key?: string | undefined;
            }>;
          }) => {
            state.onlineCheckInTripDetail?.journeys.forEach((n) => {
              if (n.journey_key === e.journeyKey) {
                n.segments.forEach((m) => {
                  if (m.segment_key === e.segmentKey) {
                    remove(m.arItems.selected, (e) => e.type === EArItemType.MEAL);
                    m.arItems.selected = m.arItems.selected.concat(e.arItems);
                  }
                });
              }
            });
          }
        );
      } else {
        state.onlineCheckInTripDetail?.journeys.forEach((n) => {
          n.segments.forEach((m) => {
            remove(m.arItems.selected, (e) => e.type === EArItemType.MEAL);
          });
        });
        remove(state.onlineCheckInAddExtraMetaData?.selectedArItems, (n) =>
          n.arItems.find((e) => e.type === EArItemType.MEAL)
        );
      }
    },
    updateOnlineCheckInPaxMeal: (
      state,
      { payload: { tripId, selectedJourneyKey, selectedSegmentKey, paxId, mealCode, amount } }
    ) => {
      if (tripId && selectedJourneyKey && selectedSegmentKey) {
        const currentItem = state.onlineCheckInAddExtraMetaData?.selectedArItems.find(
          (e) =>
            e.journeyKey === selectedJourneyKey &&
            e.segmentKey === selectedSegmentKey &&
            e.tripId === tripId &&
            e.arItems.find((n) => n.type === EArItemType.MEAL)
        );
        if (currentItem) {
          const mealSection = currentItem.arItems.find((e) => e.code === mealCode);
          if (mealSection) {
            const mealSelectedSection = mealSection.byPax?.find((e) => e.paxId === paxId);
            if (mealSelectedSection) {
              mealSelectedSection.amount = amount;
            } else {
              mealSection.byPax?.push({
                paxId,
                amount,
              });
            }
          } else {
            currentItem.arItems.push({
              type: EArItemType.MEAL,
              code: mealCode,
              byPax: [
                {
                  paxId,
                  amount,
                },
              ],
            });
          }
        } else {
          state.onlineCheckInAddExtraMetaData?.selectedArItems.push({
            tripId: tripId,
            journeyKey: selectedJourneyKey,
            segmentKey: selectedSegmentKey,
            arItems: [
              {
                type: EArItemType.MEAL,
                code: mealCode,
                byPax: [
                  {
                    paxId,
                    amount,
                  },
                ],
              },
            ],
          });
        }
      }
    },
    updateOnlineCheckInSport: (state, { payload: { tripId, journeyKey, added, paxId, code } }) => {
      const currentItem = state.onlineCheckInAddExtraMetaData?.selectedArItems.find(
        (e) =>
          e.tripId === tripId &&
          e.journeyKey === journeyKey &&
          e.segmentKey === undefined &&
          e.arItems.find((n) => n.type === EArItemType.SPORT_EQUIPMENT)
      );
      if (currentItem) {
        const arItem = currentItem.arItems.find((e) => e.type === EArItemType.SPORT_EQUIPMENT);
        if (arItem?.byPax) {
          if (added) {
            remove(arItem.byPax, (e) => e.paxId === paxId);
          } else {
            arItem.byPax.push({
              paxId: paxId,
              amount: 1,
            });
          }
        } else {
          if (!added)
            currentItem.arItems.push({
              type: EArItemType.SPORT_EQUIPMENT,
              code,
              byPax: [
                {
                  paxId: paxId,
                  amount: 1,
                },
              ],
            });
        }
      } else {
        state.onlineCheckInAddExtraMetaData?.selectedArItems.push({
          tripId,
          journeyKey,
          arItems: [
            {
              type: EArItemType.SPORT_EQUIPMENT,
              code,
              byPax: [
                {
                  paxId,
                  amount: 1,
                },
              ],
            },
          ],
        });
      }
    },
    confirmOnlineCheckInSport: (state, { payload }) => {
      if (state.onlineCheckInTripDetail) {
        state.onlineCheckInTripDetail.journeys.forEach((e) => {
          remove(e.arItems.selected, (e) => e.type === EArItemType.SPORT_EQUIPMENT);
          payload.forEach(
            (m: {
              tripId: string;
              journeyKey: string;
              arItems: ConcatArray<{
                type: string;
                code: string;
                price?: number | undefined;
                currency_code?: string | undefined;
                availability?: number | null | undefined;
                cms_content_key?: string | undefined;
                ssr_by_passengers?: { ssr_key: string; price: number; passenger_key: string }[] | undefined;
                is_pre_order?: boolean | undefined;
                is_vegetarian?: boolean | undefined;
                byPax?: { paxId: string; amount: number }[] | undefined;
                arMetaData?: { [x: string]: string | number | boolean } | undefined;
                unit_key?: string | undefined;
              }>;
            }) => {
              if (
                state.onlineCheckInTripDetail &&
                m.tripId === state.onlineCheckInTripDetail?.id &&
                m.journeyKey === e.journey_key
              ) {
                e.arItems.selected = e.arItems.selected.concat(m.arItems);
              }
            }
          );
        });
      }
    },
    confirmOnlineCheckInUFirst: (state, { payload }) => {
      if (state.onlineCheckInTripDetail) {
        state.onlineCheckInTripDetail?.journeys.forEach((m) => {
          remove(m.arItems.selected, (e) => e.type === EArItemType.U_FIRST);
          if (payload) {
            m.arItems.selected = m.arItems.selected.concat(
              payload.find(
                (e: { journeyKey: string; tripId: string | undefined }) =>
                  e.journeyKey === m.journey_key && e.tripId === state.onlineCheckInTripDetail?.id
              )?.arItems ?? []
            );
          }
        });
      }
    },
    updateOnlineCheckInUFirst: (state, { payload: { passengers, added, journeyKey, code, tripId } }) => {
      remove(
        state.onlineCheckInAddExtraMetaData?.selectedArItems,
        (e) =>
          e.arItems.find((e) => e.type === EArItemType.U_FIRST) &&
          e.tripId === tripId &&
          e.journeyKey === journeyKey &&
          e.segmentKey === undefined
      );
      if (!added) {
        state.onlineCheckInAddExtraMetaData?.selectedArItems.push({
          tripId,
          journeyKey,
          arItems: [
            {
              type: EArItemType.U_FIRST,
              code,
              byPax: passengers.map((e: { id: any }) => ({
                paxId: e.id,
                amount: 1,
              })),
            },
          ],
        });
      }
    },
    updateOnlineCheckInSIMCard: (state, { payload: { tripId, added, paxId, code } }) => {
      const currentItem = state.onlineCheckInAddExtraMetaData?.selectedArItems.find(
        (e) =>
          e.tripId === tripId &&
          e.journeyKey === undefined &&
          e.segmentKey === undefined &&
          e.arItems.find((n) => n.type === EArItemType.SIM_CARD)
      );
      if (currentItem) {
        const arItem = currentItem.arItems.find((e) => e.type === EArItemType.SIM_CARD);
        if (arItem?.byPax) {
          if (added) {
            remove(arItem.byPax, (e) => e.paxId === paxId);
          } else {
            arItem.byPax.push({
              paxId: paxId,
              amount: 1,
            });
          }
        } else {
          if (!added)
            currentItem.arItems.push({
              type: EArItemType.SIM_CARD,
              code,
              byPax: [
                {
                  paxId: paxId,
                  amount: 1,
                },
              ],
            });
        }
      } else {
        state.onlineCheckInAddExtraMetaData?.selectedArItems.push({
          tripId,
          arItems: [
            {
              type: EArItemType.SIM_CARD,
              code,
              byPax: [
                {
                  paxId,
                  amount: 1,
                },
              ],
            },
          ],
        });
      }
    },
    confirmOnlineCheckInSIMCard: (state, { payload }) => {
      if (state.onlineCheckInTripDetail) {
        remove(state.onlineCheckInTripDetail.arItems.selected, (e) => e.type === EArItemType.SIM_CARD);
        payload.forEach(
          (n: {
            tripId: string;
            arItems: ConcatArray<{
              type: string;
              code: string;
              price?: number | undefined;
              currency_code?: string | undefined;
              availability?: number | null | undefined;
              cms_content_key?: string | undefined;
              ssr_by_passengers?: { ssr_key: string; price: number; passenger_key: string }[] | undefined;
              is_pre_order?: boolean | undefined;
              is_vegetarian?: boolean | undefined;
              byPax?: { paxId: string; amount: number }[] | undefined;
              arMetaData?: { [x: string]: string | number | boolean } | undefined;
              unit_key?: string | undefined;
            }>;
          }) => {
            if (state.onlineCheckInTripDetail && n.tripId === state.onlineCheckInTripDetail?.id) {
              state.onlineCheckInTripDetail.arItems.selected = state.onlineCheckInTripDetail?.arItems.selected.concat(
                n.arItems
              );
            }
          }
        );
      }
    },
    storeOnlineCheckInDetail: (state, action) => {
      state.onlineCheckInTripDetail = action.payload;
    },
    removeOnlineCheckSelectedSeat: (state, action) => {
      state.onlineCheckInTripDetail?.journeys.forEach((journey) => {
        const segmentIndex = journey.segments.findIndex((segment) => segment.segment_key === action.payload.segmentKey);
        if (segmentIndex > -1) {
          const arItems = journey.segments[segmentIndex].arItems.selected.filter((arItem) => {
            return !(arItem.type === "SEAT" && arItem.byPax && arItem.byPax[0].paxId === action.payload.paxId);
          });
          journey.segments[segmentIndex].arItems.selected = arItems;
        }
      });
    },
    addOnlineCheckInSelectedSeat: (state, action) => {
      state.onlineCheckInTripDetail?.journeys.forEach((journey) => {
        const segmentIndex = journey.segments.findIndex((segment) => segment.segment_key === action.payload.segmentKey);
        if (segmentIndex > -1) {
          let seatFlag = false;
          journey.segments[segmentIndex].arItems.selected.forEach((arItem) => {
            if (arItem.type === "SEAT" && arItem.byPax && arItem.byPax[0].paxId === action.payload.paxId) {
              arItem.code = action.payload.seatDesignator;
              arItem.price = action.payload.seatPrice;
              arItem.unit_key = action.payload.unitKey;
              arItem.currency_code = action.payload.currency_code;
              seatFlag = true;
            }
          });
          if (!seatFlag) {
            journey.segments[segmentIndex].arItems.selected.push({
              type: "SEAT",
              code: action.payload.seatDesignator,
              price: action.payload.seatPrice,
              unit_key: action.payload.unitKey,
              spoilage: action.payload.purchasedSeatPrice,
              currency_code: action.payload.currency_code,
              byPax: [
                {
                  paxId: action.payload.paxId,
                  amount: 1,
                },
              ],
            });
          }
        }
      });
    },
    storeOnlineCheckInPaymentMetaData: (state, action) => {
      state.paymentMetaData = { ...state.paymentMetaData, ...action.payload };
    },
    resetOnlineCheckInPaymentMetaData: (state) => {
      state.paymentMetaData = undefined;
    },
    updateOnlineCheckInInsurance: (state, action) => {
      const validPax = state.onlineCheckInPassengers?.filter((e) => e.paxType === EPaxType.Adult);
      if (
        state.onlineCheckInTripDetail &&
        action.payload &&
        action.payload === 1 &&
        !state.onlineCheckInTripDetail.arItems.selected.find((e) => e.type === EArItemType.INSURANCE)
      ) {
        state.onlineCheckInTripDetail.arItems.selected.push({
          type: EArItemType.INSURANCE,
          code: EArItemType.INSURANCE,
          byPax: validPax?.map((e) => ({
            amount: 1,
            paxId: e.id,
          })),
        });
      } else if (state.onlineCheckInTripDetail && action.payload !== 1) {
        state.onlineCheckInTripDetail.arItems.selected = state.onlineCheckInTripDetail?.arItems.selected.filter(
          (e) => e.type !== EArItemType.INSURANCE
        );
      }
    },
    updateOnlineCheckInCarbonOffset: (state, action) => {
      if (state.onlineCheckInTripDetail && action.payload === true && state.onlineCheckInPassengers) {
        state.onlineCheckInTripDetail.arItems.selected.push({
          type: EArItemType.CARBON_OFFSET,
          code: EArItemType.CARBON_OFFSET,
          byPax: [
            {
              amount: 1,
              paxId: state?.onlineCheckInPassengers[0].id,
            },
          ],
        });
      } else if (state.onlineCheckInTripDetail && action.payload === false) {
        state.onlineCheckInTripDetail.arItems.selected = state.onlineCheckInTripDetail?.arItems.selected.filter(
          (e) => e.type !== EArItemType.CARBON_OFFSET
        );
      }
    },
    storeOnlineCheckInPaymentPageDetail: (state, action) => {
      state.paymentPageDetail = {
        ...state.paymentPageDetail,
        ...action.payload,
      };
    },
    storeEPaymentFee: (state, action) => {
      if (state.paymentMetaData) {
        state.paymentMetaData.selected_e_payment_fee = action.payload;
      }
    },
  },
  selectors: {
    selectOnlineCheckInPassengers: (storage: OnlineCheckInReducerProps): OnlineCheckInPassengersType[] | undefined =>
      storage.onlineCheckInPassengers,
    selectOnlineCheckInConfirmationDetail: (
      storage: OnlineCheckInReducerProps
    ): OnlineCheckInConfirmationDetailType | null => storage.onlineCheckInConfirmationDetail,
    selectOnlineCheckInAddExtrasPageMetaData: (
      storage: OnlineCheckInReducerProps
    ): OnlineCheckInAddExtrasPageMetaData => storage.onlineCheckInAddExtraMetaData,

    selectStepperActive: (storage: OnlineCheckInReducerProps): number => storage.stepperIndex,
    selectOnlineCheckInTripDetail: (storage: OnlineCheckInReducerProps): OnlineCheckInTripDetailType | undefined =>
      storage.onlineCheckInTripDetail,
    selectOnlineCheckInPaymentOptions: (storage: OnlineCheckInReducerProps): PaymentOption[] | undefined =>
      storage.paymentMetaData?.payment_option_list,
    selectOnlineCheckInInsuranceQuote: (storage: OnlineCheckInReducerProps) => storage.paymentMetaData?.insurance_quote,
    selectOnlineCheckInCarbonQuote: (storage: OnlineCheckInReducerProps) => storage.paymentMetaData?.carbon_quote,
    selectOnlineCheckInPaymentPageDetail: (storage: OnlineCheckInReducerProps) => storage.paymentPageDetail,
    selectOnlineCheckAnalyticPaymentDetail: (storage: OnlineCheckInReducerProps) => storage.analyticPaymentDetail,
    selectOnlineCheckPaymentMetaData: (storage: OnlineCheckInReducerProps) => storage.paymentMetaData,
  },
});

export const {
  storeOnlineCheckInConfirmationDetail,
  storeOnlineCheckInPassengers,
  updateOnlineCheckInBaggage,
  confirmOnlineCheckInSelectedBaggage,
  updateOnlineCheckInAddExtrasPageMetaData,
  confirmOnlineCheckInPaxMeal,
  updateOnlineCheckInPaxMeal,
  updateOnlineCheckInSport,
  confirmOnlineCheckInSport,
  confirmOnlineCheckInUFirst,
  updateOnlineCheckInUFirst,
  updateOnlineCheckInSIMCard,
  confirmOnlineCheckInSIMCard,
  storeOnlineCheckInDetail,
  removeOnlineCheckSelectedSeat,
  addOnlineCheckInSelectedSeat,
  storeOnlineCheckInPaymentMetaData,
  resetOnlineCheckInPaymentMetaData,
  updateOnlineCheckInInsurance,
  updateOnlineCheckInCarbonOffset,
  storeOnlineCheckInPaymentPageDetail,
  resetOnlineCheckInState,
  updateAnalyticPaymentDetail,
  storeEPaymentFee,
} = onlineCheckInSlice.actions;
export default onlineCheckInSlice.reducer;
export const {
  selectOnlineCheckInPassengers,
  selectOnlineCheckInAddExtrasPageMetaData,
  selectOnlineCheckInTripDetail,
  selectOnlineCheckInConfirmationDetail,
  selectStepperActive,
  selectOnlineCheckInCarbonQuote,
  selectOnlineCheckInInsuranceQuote,
  selectOnlineCheckInPaymentOptions,
  selectOnlineCheckInPaymentPageDetail,
  selectOnlineCheckAnalyticPaymentDetail,
  selectOnlineCheckPaymentMetaData,
} = onlineCheckInSlice.getSelectors((state: RootState) => {
  return state.session.onlineCheckIn;
});
