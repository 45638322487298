import { createReducer, isAnyOf } from "@reduxjs/toolkit";
import { LongPollingFormAction } from "lib/features/flight-book/payment/definition";
import { PasswordStore, UsernameStore, createB2BFormAction, storeOriginalPassword } from "./actions";

export type B2BMetaData = {
  userNameStore: boolean;
  passwordStore: boolean;
  originalPasswordStore: boolean;
};

export interface B2BReducerProps {
  b2bMetaData?: B2BMetaData;
  formAction?: LongPollingFormAction;
}

const initialState: B2BReducerProps = {
  b2bMetaData: undefined,
  formAction: undefined,
};

export const B2BReducer = createReducer(initialState, (builder) => {
  builder.addMatcher(isAnyOf(UsernameStore), (state, action) => {
    if (state.b2bMetaData) {
      if (state.b2bMetaData.userNameStore) {
        state.b2bMetaData.userNameStore = action.payload;
      }
    } else {
      state.b2bMetaData = {
        userNameStore: action.payload,
        passwordStore: action.payload,
        originalPasswordStore: action.payload,
      };
    }
  });
  builder.addMatcher(isAnyOf(PasswordStore), (state, action) => {
    if (state.b2bMetaData) {
      if (state.b2bMetaData.passwordStore) {
        state.b2bMetaData.passwordStore = action.payload;
      }
    } else {
      state.b2bMetaData = {
        userNameStore: action.payload,
        passwordStore: action.payload,
        originalPasswordStore: action.payload,
      };
    }
  });
  builder.addMatcher(isAnyOf(storeOriginalPassword), (state, action) => {
    if (state.b2bMetaData) {
      if (state.b2bMetaData.originalPasswordStore) {
        state.b2bMetaData.originalPasswordStore = action.payload;
      }
    } else {
      state.b2bMetaData = {
        userNameStore: action.payload,
        passwordStore: action.payload,
        originalPasswordStore: action.payload,
      };
    }
  });
  builder.addMatcher(isAnyOf(createB2BFormAction), (state, action) => {
    state.formAction = action.payload;
  });
});
