import { b2bEndPoints, fetchAPI, getAccessTokenHeader } from "api";
import { requiredHeader } from "lib/common/service";
import { AgentProfile } from "store/sessionStorage/slices/b2bSlice";

export type AgentProfileRes = {
  metadata: {
    app_version: string;
    status_code: number;
    trace_id: string;
  };
} & AgentProfile;

export const fetchAgentProfile = async () => {
  const accessTokenHeader = getAccessTokenHeader();
  const result = await fetchAPI(b2bEndPoints.b2bProfile, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      ...requiredHeader(),
      ...accessTokenHeader,
    },
    cache: "no-store",
  });
  return result;
};
