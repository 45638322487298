import {
  Button,
  ButtonVariant,
  Card,
  CardContent,
  ChevronLeftIcon,
  CrossOutlineIcon,
  EFont,
  ESpacing,
  Font,
  Drawer as HKEDrawer,
} from "@hkexpressairwayslimited/ui";
import { Box, Stack, SvgIcon } from "@mui/material";
import { useTransContent } from "modules/common/trans-content/transContent";
type DrawerProps = {
  title: string | JSX.Element | JSX.Element[];
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  confirmLabel?: string | JSX.Element | JSX.Element[];
  children?: JSX.Element | JSX.Element[] | string | number;
  avatar: JSX.Element;
  totalCost: string | JSX.Element;
  crossIconClose?: boolean;
  autoClose?: boolean;
  backButton?: boolean;
  backButtonOnClick?: () => void;
};
export function Drawer({
  open,
  onClose,
  title,
  children,
  avatar,
  totalCost,
  onConfirm,
  confirmLabel,
  crossIconClose = true,
  autoClose = false,
  backButton = false,
  backButtonOnClick,
}: DrawerProps) {
  const { t } = useTransContent();
  return (
    <HKEDrawer
      open={open}
      onClose={() => {
        autoClose && onClose();
      }}
    >
      <Box sx={{ minHeight: "100%" }}>
        <Stack direction='row' alignItems='center' justifyContent='space-between'>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent={crossIconClose ? "space-between" : "flex-start"}
            spacing={ESpacing._s}
          >
            {!backButton ? (
              <SvgIcon sx={{ color: "purple.default" }} fontSize='large'>
                {avatar}
              </SvgIcon>
            ) : (
              <SvgIcon fontSize='large' onClick={backButtonOnClick}>
                <ChevronLeftIcon />
              </SvgIcon>
            )}
            <Font variant={EFont.h1}>{title}</Font>
          </Stack>
          {crossIconClose && (
            <SvgIcon onClick={onClose} sx={{ color: "neutral.black", fontSize: ESpacing._xs }}>
              <CrossOutlineIcon />
            </SvgIcon>
          )}
        </Stack>
        <Stack sx={{ minHeight: "100%", paddingBottom: ESpacing._lg }} justifyContent='space-between'>
          <Box mt={ESpacing._sm} display={"flex"} justifyContent={"center"}>
            {children}
          </Box>
          <Card borderless withBGC sx={{ marginTop: ESpacing._lg }}>
            <CardContent>
              <Stack direction='row' justifyContent='space-between'>
                <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={ESpacing._3xs}>
                  <Font>{t("web.addExtras.baggage.subtotal")}</Font>
                  <Font variant={EFont.h3} fontWeight='fontWeightBold'>
                    {totalCost}
                  </Font>
                </Stack>
                <Button variant={ButtonVariant.Secondary} onClick={onConfirm}>
                  {confirmLabel}
                </Button>
              </Stack>
            </CardContent>
          </Card>
        </Stack>
      </Box>
    </HKEDrawer>
  );
}
