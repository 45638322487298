"use client";
import { ArItem, ManageMyBookingSearchingDetail, TripDetail } from "@hkexpressairwayslimited/ui";
import { createReducer, isAnyOf } from "@reduxjs/toolkit";
import { LongPollingFormAction, PaymentOption } from "lib/features/flight-book/payment/definition";
import { ManageMyBookingTripDetail } from "lib/features/manage-my-booking/my-trips/definition";
import { cloneDeep, remove } from "lodash";
import { EArItemType, EBaggageArCode, SelectableArItem } from "ui/features/flight-booking/add-extras";
import {
  addTrips,
  cleanPassengerDetail,
  confirmBaggage,
  confirmChangeFlightPaxMeal,
  confirmChangeFlightSIMCard,
  confirmChangeFlightSelectedBaggage,
  confirmChangeFlightSport,
  confirmChangeFlightUFirst,
  confirmPaxMeal,
  confirmSIMCard,
  confirmSport,
  confirmUFirst,
  createMMBFormAction,
  mmbNskTokenExpireNotify,
  pushMMBJourney,
  removeChangeFlightTripDetail,
  resetChangeFlightState,
  resetMMBState,
  storeChangeFlightTripDetail,
  storeMMBPaymentOption,
  storeManageMyBookingFlightSearching,
  storePassengerDetail,
  storeTrips,
  updateBaggage,
  updateCarbonOffset,
  updateChangeFlightAddExtrasPageMetaData,
  updateChangeFlightBaggage,
  updateChangeFlightPaxMeal,
  updateChangeFlightSIMCard,
  updateChangeFlightSport,
  updateChangeFlightUFirst,
  updateInsurance,
  updatePassengerResultActive,
  updatePaxMeal,
  updateSIMCard,
  updateSport,
  updateStepperActive,
  updateTripDetailPageMetaData,
  updateTrips,
  updateUFirst,
} from "./actions";
export type SelectedArItems = {
  tripId?: string;
  journeyKey?: string;
  segmentKey?: string;
  arItems: ArItem[];
};
export type TripDetailPageMetaData = {
  updatePassengerResult: boolean;
  baggageSelected: {
    [K: string]: SelectableArItem[];
  };
  selectedArItems: SelectedArItems[];
};
export type ChangeFlightAddExtrasPageMetaData = {
  baggageSelected: {
    [paxId: string]: SelectableArItem[];
  };
  confirmedItems: Partial<Record<EArItemType, boolean>>;
  selectedArItems: SelectedArItems[];
};
export interface ManageMyBookingReducerProps {
  manageMyBookingSearchingDetail?: ManageMyBookingSearchingDetail;
  trips?: ManageMyBookingTripDetail[];
  tripDetailPageMetaData: TripDetailPageMetaData;
  stepperIndex: number;
  // passengerDetail?: MMBPassengersDetail;
  mmbNskTokenExpireNotify: boolean;
  changeFlightTripDetail?: TripDetail;
  changeFlightAddExtraMetaData: ChangeFlightAddExtrasPageMetaData;
  formAction?: LongPollingFormAction;
  paymentOption?: PaymentOption[];
}

const initialState: ManageMyBookingReducerProps = {
  manageMyBookingSearchingDetail: undefined,
  stepperIndex: 1,
  trips: undefined,
  tripDetailPageMetaData: {
    updatePassengerResult: false,
    baggageSelected: {},
    selectedArItems: [],
  },
  mmbNskTokenExpireNotify: false,
  changeFlightTripDetail: undefined,
  changeFlightAddExtraMetaData: {
    baggageSelected: {},
    confirmedItems: {},
    selectedArItems: [],
  },
  formAction: undefined,
  paymentOption: undefined,
};

export const ManageMyBookingReducer = createReducer(initialState, (builder) => {
  builder
    .addMatcher(isAnyOf(resetChangeFlightState), (state, action) => {
      state.changeFlightTripDetail = initialState.changeFlightTripDetail;
      state.changeFlightAddExtraMetaData = initialState.changeFlightAddExtraMetaData;
    })
    .addMatcher(isAnyOf(storeManageMyBookingFlightSearching), (state, action) => {
      state.manageMyBookingSearchingDetail = action.payload;
    })
    .addMatcher(isAnyOf(updateStepperActive), (state, action) => {
      state.stepperIndex = action.payload;
    })
    .addMatcher(isAnyOf(storeTrips), (state, action) => {
      state.trips = action.payload;
    })
    .addMatcher(isAnyOf(addTrips), (state, action) => {
      if (!state.trips?.find((trip) => trip.sales_reference === action.payload.sales_reference))
        state.trips?.push(action.payload);
    })
    .addMatcher(isAnyOf(updateTrips), (state, action) => {
      const { sales_reference } = action.payload;
      const tripIndex = state.trips?.findIndex((trip) => trip.sales_reference === sales_reference);
      console.log("tripIndex", tripIndex, state.trips, action.payload);
      if (state.trips && tripIndex !== null && tripIndex !== undefined) {
        state.trips[tripIndex] = action.payload;
      }
    })
    .addMatcher(isAnyOf(storePassengerDetail), (state, { payload: { tripId, data } }) => {
      if (state.trips) {
        const tripIdx = state.trips.findIndex((e) => e.sales_reference === tripId);
        if (tripIdx > -1 && state.trips[tripIdx]) {
          state.trips[tripIdx].passengers = data.passengers;
        }
      }
    })
    .addMatcher(isAnyOf(cleanPassengerDetail), (state, { payload: { tripId } }) => {
      if (state.trips) {
        const tripIdx = state.trips.findIndex((e) => e.sales_reference === tripId);
        if (tripIdx > -1 && state.trips[tripIdx]) {
          state.trips[tripIdx].passengers = [];
        }
      }
    })
    .addMatcher(
      isAnyOf(updatePaxMeal),
      (state, { payload: { tripId, selectedJourneyKey, selectedSegmentKey, paxId, mealCode, amount } }) => {
        if (tripId && selectedJourneyKey && selectedSegmentKey) {
          const currentItem = state.tripDetailPageMetaData.selectedArItems.find(
            (e) =>
              e.journeyKey === selectedJourneyKey &&
              e.segmentKey === selectedSegmentKey &&
              e.tripId === tripId &&
              e.arItems.find((n) => n.type === EArItemType.MEAL)
          );
          if (currentItem) {
            const mealSection = currentItem.arItems.find((e) => e.code === mealCode);
            if (mealSection) {
              const mealSelectedSection = mealSection.byPax?.find((e) => e.paxId === paxId);
              if (mealSelectedSection) {
                mealSelectedSection.amount = amount;
              } else {
                mealSection.byPax?.push({
                  paxId,
                  amount,
                });
              }
            } else {
              currentItem.arItems.push({
                type: EArItemType.MEAL,
                code: mealCode,
                byPax: [
                  {
                    paxId,
                    amount,
                  },
                ],
              });
            }
          } else {
            state.tripDetailPageMetaData.selectedArItems.push({
              tripId: tripId,
              journeyKey: selectedJourneyKey,
              segmentKey: selectedSegmentKey,
              arItems: [
                {
                  type: EArItemType.MEAL,
                  code: mealCode,
                  byPax: [
                    {
                      paxId,
                      amount,
                    },
                  ],
                },
              ],
            });
          }
        }
      }
    )
    .addMatcher(isAnyOf(confirmPaxMeal), (state, { payload }) => {
      payload.forEach((e) => {
        state.trips
          ?.find((n) => n.sales_reference === e.tripId)
          ?.journeys.forEach((n) => {
            if (n.journey_key === e.journeyKey) {
              n.segments.forEach((m) => {
                if (m.segment_key === e.segmentKey) {
                  remove(m.arItems.selected, (e) => e.type === EArItemType.MEAL);
                  m.arItems.selected = m.arItems.selected.concat(e.arItems);
                }
              });
            }
          });
      });
    })
    .addMatcher(isAnyOf(updateUFirst), (state, { payload: { tripId, passengers, added, journeyKey, code } }) => {
      remove(
        state.tripDetailPageMetaData.selectedArItems,
        (e) =>
          e.arItems.find((e) => e.type === EArItemType.U_FIRST) &&
          e.tripId === tripId &&
          e.journeyKey === journeyKey &&
          e.segmentKey === undefined
      );
      if (!added) {
        state.tripDetailPageMetaData.selectedArItems.push({
          tripId,
          journeyKey,
          arItems: [
            {
              type: EArItemType.U_FIRST,
              code,
              byPax: passengers.map((e) => ({
                paxId: e.id,
                amount: 1,
              })),
            },
          ],
        });
      }
    })
    .addMatcher(isAnyOf(confirmUFirst), (state, { payload }) => {
      if (state.trips) {
        state.trips.forEach((e) => {
          e.journeys.forEach((m) => {
            remove(m.arItems.selected, (e) => e.type === EArItemType.U_FIRST);
            if (payload) {
              m.arItems.selected = m.arItems.selected.concat(
                payload.find((n) => n.journeyKey === m.journey_key && n.tripId === e.sales_reference)?.arItems ?? []
              );
            }
          });
        });
      }
    })
    .addMatcher(isAnyOf(updateSIMCard), (state, { payload: { tripId, added, paxId, code } }) => {
      const currentItem = state.tripDetailPageMetaData.selectedArItems.find(
        (e) =>
          e.tripId === tripId &&
          e.journeyKey === undefined &&
          e.segmentKey === undefined &&
          e.arItems.find((n) => n.type === EArItemType.SIM_CARD)
      );
      if (currentItem) {
        const arItem = currentItem.arItems.find((e) => e.type === EArItemType.SIM_CARD);
        if (arItem?.byPax) {
          if (added) {
            remove(arItem.byPax, (e) => e.paxId === paxId);
          } else {
            arItem.byPax.push({
              paxId: paxId,
              amount: 1,
            });
          }
        } else {
          if (!added)
            currentItem.arItems.push({
              type: EArItemType.SIM_CARD,
              code,
              byPax: [
                {
                  paxId: paxId,
                  amount: 1,
                },
              ],
            });
        }
      } else {
        state.tripDetailPageMetaData.selectedArItems.push({
          tripId,
          arItems: [
            {
              type: EArItemType.SIM_CARD,
              code,
              byPax: [
                {
                  paxId,
                  amount: 1,
                },
              ],
            },
          ],
        });
      }
    })
    .addMatcher(isAnyOf(confirmSIMCard), (state, { payload }) => {
      if (state.trips) {
        state.trips.forEach((e) => {
          payload.forEach((m) => {
            if (e.sales_reference === m.tripId) {
              remove(e.arItems.selected, (e) => e.type === EArItemType.SIM_CARD);
              e.arItems.selected = e?.arItems.selected.concat(m.arItems);
            }
          });
        });
      }
    })
    .addMatcher(isAnyOf(updateSport), (state, { payload: { tripId, journeyKey, added, paxId, code } }) => {
      const currentItem = state.tripDetailPageMetaData.selectedArItems.find(
        (e) =>
          e.tripId === tripId &&
          e.journeyKey === journeyKey &&
          e.segmentKey === undefined &&
          e.arItems.find((n) => n.type === EArItemType.SPORT_EQUIPMENT)
      );
      if (currentItem) {
        const arItem = currentItem.arItems.find((e) => e.type === EArItemType.SPORT_EQUIPMENT);
        if (arItem?.byPax) {
          if (added) {
            remove(arItem.byPax, (e) => e.paxId === paxId);
          } else {
            arItem.byPax.push({
              paxId: paxId,
              amount: 1,
            });
          }
        } else {
          if (!added)
            currentItem.arItems.push({
              type: EArItemType.SPORT_EQUIPMENT,
              code,
              byPax: [
                {
                  paxId: paxId,
                  amount: 1,
                },
              ],
            });
        }
      } else {
        state.tripDetailPageMetaData.selectedArItems.push({
          tripId,
          journeyKey,
          arItems: [
            {
              type: EArItemType.SPORT_EQUIPMENT,
              code,
              byPax: [
                {
                  paxId,
                  amount: 1,
                },
              ],
            },
          ],
        });
      }
    })
    .addMatcher(isAnyOf(confirmSport), (state, { payload }) => {
      if (state.trips) {
        state.trips.forEach((e) => {
          e.journeys.forEach((m) => {
            remove(m.arItems.selected, (e) => e.type === EArItemType.SPORT_EQUIPMENT);
            payload.forEach((n) => {
              if (n.journeyKey === m.journey_key && n.tripId === e.sales_reference) {
                m.arItems.selected = m.arItems.selected.concat(n.arItems);
              }
            });
          });
        });
      }
    })
    .addMatcher(
      isAnyOf(updateBaggage),
      (state, { payload: { journeyKey, selectedBaggage, paxId, active, tripId } }) => {
        const currentItem = state.tripDetailPageMetaData.selectedArItems.find(
          (e) =>
            e.journeyKey === journeyKey &&
            e.tripId === tripId &&
            e.segmentKey === undefined &&
            e.arItems.find((n) => n.type === EArItemType.BAGGAGE)
        );
        if (currentItem) {
          const activeOption = currentItem.arItems.find((e) => e.code === selectedBaggage.code);
          if (activeOption) {
            const activeUserSection = activeOption.byPax?.find((e) => e.paxId === paxId);
            if (activeUserSection) {
              activeUserSection.amount =
                selectedBaggage.code === EBaggageArCode._0KG
                  ? active
                    ? 0
                    : 1
                  : activeUserSection.amount + (active ? -1 : 1);
            } else {
              activeOption?.byPax?.push({
                paxId: paxId,
                amount: active ? 0 : 1,
              });
            }
          } else {
            currentItem.arItems.push({
              type: selectedBaggage.type,
              code: selectedBaggage.code,
              byPax: [
                {
                  paxId: paxId,
                  amount: active ? 0 : 1,
                },
              ],
            });
          }
        } else {
          state.tripDetailPageMetaData.selectedArItems.push({
            journeyKey,
            tripId,
            arItems: [
              {
                type: selectedBaggage.type,
                code: selectedBaggage.code,
                byPax: [
                  {
                    paxId: paxId,
                    amount: active ? 0 : 1,
                  },
                ],
              },
            ],
          });
        }
      }
    )
    .addMatcher(isAnyOf(confirmBaggage), (state, { payload }) => {
      if (state.trips) {
        state.trips?.forEach((n) => {
          n.journeys.forEach((m) => {
            remove(m.arItems.selected, (e) => e.type === EArItemType.BAGGAGE);
            payload?.forEach((e) => {
              if (m.journey_key === e.journeyKey && n.sales_reference === e.tripId) {
                m.arItems.selected = m.arItems.selected.concat(e.arItems);
              }
            });
          });
        });
      }
    })
    .addMatcher(isAnyOf(updateInsurance), (state, { payload: { tripId, added, passengers, code } }) => {
      if (state.trips) {
        const tripIdx = state.trips.findIndex((e) => e.sales_reference === tripId);
        if (tripIdx > -1) {
          const selectedCopy = cloneDeep(state.trips[tripIdx].arItems.selected);
          remove(selectedCopy, (e) => e.type === EArItemType.INSURANCE);
          if (added === 1) {
            selectedCopy.push({
              type: EArItemType.INSURANCE,
              code,
              byPax: passengers.map((e) => ({
                paxId: e.id,
                amount: 1,
              })),
            });
          }
          state.trips[tripIdx].arItems.selected = selectedCopy;
        }
      }
    })
    .addMatcher(isAnyOf(updateCarbonOffset), (state, { payload: { tripId, added, passengers, code } }) => {
      if (state.trips) {
        const tripIdx = state.trips.findIndex((e) => e.sales_reference === tripId);
        if (tripIdx > -1) {
          const selectedCopy = cloneDeep(state.trips[tripIdx].arItems.selected);
          remove(selectedCopy, (e) => e.type === EArItemType.CARBON_OFFSET);
          if (added) {
            selectedCopy.push({
              type: EArItemType.CARBON_OFFSET,
              code,
              byPax: passengers.map((e) => ({
                paxId: e.id,
                amount: 1,
              })),
            });
          }
          state.trips[tripIdx].arItems.selected = selectedCopy;
        }
      }
    })
    .addMatcher(isAnyOf(updateTripDetailPageMetaData), (state, action) => {
      const { baggageSelected } = action.payload;
      if (baggageSelected) {
        Object.keys(baggageSelected).forEach((e) => {
          if (state) {
            if (state.tripDetailPageMetaData) {
              if (state.tripDetailPageMetaData.baggageSelected) {
                state.tripDetailPageMetaData.baggageSelected[e] = baggageSelected[e];
              } else {
                state.tripDetailPageMetaData["baggageSelected"] = baggageSelected;
              }
            }
          }
        });
      }
    })
    .addMatcher(isAnyOf(updatePassengerResultActive), (state, action) => {
      if (state.tripDetailPageMetaData) {
        if (state.tripDetailPageMetaData.updatePassengerResult) {
          state.tripDetailPageMetaData.updatePassengerResult = action.payload;
        } else {
          state.tripDetailPageMetaData["updatePassengerResult"] = action.payload;
        }
      }
    })
    .addMatcher(isAnyOf(mmbNskTokenExpireNotify), (state, action) => {
      console.log("saga mmbNskTokenExpireNotify");
      state.mmbNskTokenExpireNotify = action.payload;
    })
    .addMatcher(isAnyOf(resetMMBState), (state) => initialState)
    .addMatcher(isAnyOf(storeChangeFlightTripDetail), (state, action) => {
      state.changeFlightTripDetail = action.payload;
    })
    .addMatcher(isAnyOf(removeChangeFlightTripDetail), (state, action) => {
      if (state.changeFlightTripDetail) {
        state.changeFlightTripDetail.journeys = state.changeFlightTripDetail?.journeys.filter((journey) => {
          return journey.index !== action.payload;
        });
      }
    })
    .addMatcher(isAnyOf(pushMMBJourney), (state, action) => {
      state.changeFlightTripDetail &&
        state.changeFlightTripDetail.journeys[action.payload.index === 1 ? "push" : "unshift"](action.payload);
    })
    .addMatcher(isAnyOf(storeMMBPaymentOption), (state, action) => {
      state.paymentOption = action.payload;
    })
    .addMatcher(isAnyOf(createMMBFormAction), (state, action) => {
      state.formAction = action.payload;
    })
    .addMatcher(isAnyOf(updateChangeFlightAddExtrasPageMetaData), (state, action) => {
      const { baggageSelected, confirmedItems } = action.payload;
      if (baggageSelected) {
        Object.keys(baggageSelected).forEach(
          (e) => (state.changeFlightAddExtraMetaData.baggageSelected[e] = baggageSelected[e])
        );
      }
      if (confirmedItems) {
        state.changeFlightAddExtraMetaData.confirmedItems = {
          ...state.changeFlightAddExtraMetaData.confirmedItems,
          ...confirmedItems,
        };
      }
    })
    .addMatcher(
      isAnyOf(updateChangeFlightBaggage),
      (state, { payload: { journeyKey, selectedBaggage, paxId, active, tripId } }) => {
        const currentItem = state.changeFlightAddExtraMetaData.selectedArItems.find(
          (e) =>
            e.tripId === tripId &&
            e.journeyKey === journeyKey &&
            e.segmentKey === undefined &&
            e.arItems.find((n) => n.type === EArItemType.BAGGAGE)
        );
        if (currentItem) {
          const activeOption = currentItem.arItems.find((e) => e.code === selectedBaggage.code);
          if (activeOption) {
            const activeUserSection = activeOption.byPax?.find((e) => e.paxId === paxId);
            if (activeUserSection) {
              activeUserSection.amount =
                selectedBaggage.code === EBaggageArCode._0KG
                  ? active
                    ? 0
                    : 1
                  : activeUserSection.amount + (active ? -1 : 1);
            } else {
              activeOption?.byPax?.push({
                paxId: paxId,
                amount: active ? 0 : 1,
              });
            }
          } else {
            currentItem.arItems.push({
              type: selectedBaggage.type,
              code: selectedBaggage.code,
              byPax: [
                {
                  paxId: paxId,
                  amount: active ? 0 : 1,
                },
              ],
            });
          }
        } else {
          state.changeFlightAddExtraMetaData.selectedArItems.push({
            tripId,
            journeyKey,
            arItems: [
              {
                type: selectedBaggage.type,
                code: selectedBaggage.code,
                byPax: [
                  {
                    paxId: paxId,
                    amount: active ? 0 : 1,
                  },
                ],
              },
            ],
          });
        }
      }
    )
    .addMatcher(isAnyOf(confirmChangeFlightSelectedBaggage), (state, { payload }) => {
      if (state.changeFlightTripDetail) {
        state.changeFlightTripDetail?.journeys.forEach((m) => {
          remove(m.arItems.selected, (e) => e.type === EArItemType.BAGGAGE);
          payload?.forEach((e) => {
            if (m.journey_key === e.journeyKey) {
              m.arItems.selected = m.arItems.selected.concat(e.arItems);
            }
          });
        });
      }
    })
    .addMatcher(
      isAnyOf(updateChangeFlightPaxMeal),
      (state, { payload: { tripId, selectedJourneyKey, selectedSegmentKey, paxId, mealCode, amount } }) => {
        if (tripId && selectedJourneyKey && selectedSegmentKey) {
          const currentItem = state.changeFlightAddExtraMetaData.selectedArItems.find(
            (e) =>
              e.journeyKey === selectedJourneyKey &&
              e.segmentKey === selectedSegmentKey &&
              e.tripId === tripId &&
              e.arItems.find((n) => n.type === EArItemType.MEAL)
          );
          if (currentItem) {
            const mealSection = currentItem.arItems.find((e) => e.code === mealCode);
            if (mealSection) {
              const mealSelectedSection = mealSection.byPax?.find((e) => e.paxId === paxId);
              if (mealSelectedSection) {
                mealSelectedSection.amount = amount;
              } else {
                mealSection.byPax?.push({
                  paxId,
                  amount,
                });
              }
            } else {
              currentItem.arItems.push({
                type: EArItemType.MEAL,
                code: mealCode,
                byPax: [
                  {
                    paxId,
                    amount,
                  },
                ],
              });
            }
          } else {
            state.changeFlightAddExtraMetaData.selectedArItems.push({
              tripId: tripId,
              journeyKey: selectedJourneyKey,
              segmentKey: selectedSegmentKey,
              arItems: [
                {
                  type: EArItemType.MEAL,
                  code: mealCode,
                  byPax: [
                    {
                      paxId,
                      amount,
                    },
                  ],
                },
              ],
            });
          }
        }
      }
    )
    .addMatcher(isAnyOf(confirmChangeFlightPaxMeal), (state, { payload }) => {
      if (payload) {
        payload.forEach((e) => {
          state.changeFlightTripDetail?.journeys.forEach((n) => {
            if (n.journey_key === e.journeyKey) {
              n.segments.forEach((m) => {
                if (m.segment_key === e.segmentKey) {
                  remove(m.arItems.selected, (e) => e.type === EArItemType.MEAL);
                  m.arItems.selected = m.arItems.selected.concat(e.arItems);
                }
              });
            }
          });
        });
      } else {
        state.changeFlightTripDetail?.journeys.forEach((n) => {
          n.segments.forEach((m) => {
            remove(m.arItems.selected, (e) => e.type === EArItemType.MEAL);
          });
        });
        remove(state.changeFlightAddExtraMetaData.selectedArItems, (n) =>
          n.arItems.find((e) => e.type === EArItemType.MEAL)
        );
      }
    })
    .addMatcher(isAnyOf(updateChangeFlightSport), (state, { payload: { tripId, journeyKey, added, paxId, code } }) => {
      const currentItem = state.changeFlightAddExtraMetaData.selectedArItems.find(
        (e) =>
          e.tripId === tripId &&
          e.journeyKey === journeyKey &&
          e.segmentKey === undefined &&
          e.arItems.find((n) => n.type === EArItemType.SPORT_EQUIPMENT)
      );
      if (currentItem) {
        const arItem = currentItem.arItems.find((e) => e.type === EArItemType.SPORT_EQUIPMENT);
        if (arItem?.byPax) {
          if (added) {
            remove(arItem.byPax, (e) => e.paxId === paxId);
          } else {
            arItem.byPax.push({
              paxId: paxId,
              amount: 1,
            });
          }
        } else {
          if (!added)
            currentItem.arItems.push({
              type: EArItemType.SPORT_EQUIPMENT,
              code,
              byPax: [
                {
                  paxId: paxId,
                  amount: 1,
                },
              ],
            });
        }
      } else {
        state.changeFlightAddExtraMetaData.selectedArItems.push({
          tripId,
          journeyKey,
          arItems: [
            {
              type: EArItemType.SPORT_EQUIPMENT,
              code,
              byPax: [
                {
                  paxId,
                  amount: 1,
                },
              ],
            },
          ],
        });
      }
    })
    .addMatcher(isAnyOf(confirmChangeFlightSport), (state, { payload }) => {
      if (state.changeFlightTripDetail) {
        state.changeFlightTripDetail.journeys.forEach((e) => {
          remove(e.arItems.selected, (e) => e.type === EArItemType.SPORT_EQUIPMENT);
          payload.forEach((m) => {
            if (
              state.changeFlightTripDetail &&
              m.tripId === state.changeFlightTripDetail?.id &&
              m.journeyKey === e.journey_key
            ) {
              e.arItems.selected = e.arItems.selected.concat(m.arItems);
            }
          });
        });
      }
    })
    .addMatcher(
      isAnyOf(updateChangeFlightUFirst),
      (state, { payload: { passengers, added, journeyKey, code, tripId } }) => {
        remove(
          state.changeFlightAddExtraMetaData.selectedArItems,
          (e) =>
            e.arItems.find((e) => e.type === EArItemType.U_FIRST) &&
            e.tripId === tripId &&
            e.journeyKey === journeyKey &&
            e.segmentKey === undefined
        );
        if (!added) {
          state.changeFlightAddExtraMetaData.selectedArItems.push({
            tripId,
            journeyKey,
            arItems: [
              {
                type: EArItemType.U_FIRST,
                code,
                byPax: passengers.map((e) => ({
                  paxId: e.id,
                  amount: 1,
                })),
              },
            ],
          });
        }
      }
    )
    .addMatcher(isAnyOf(confirmChangeFlightUFirst), (state, { payload }) => {
      if (state.changeFlightTripDetail) {
        state.changeFlightTripDetail?.journeys.forEach((m) => {
          remove(m.arItems.selected, (e) => e.type === EArItemType.U_FIRST);
          if (payload) {
            m.arItems.selected = m.arItems.selected.concat(
              payload.find((e) => e.journeyKey === m.journey_key && e.tripId === state.changeFlightTripDetail?.id)
                ?.arItems ?? []
            );
          }
        });
      }
    })
    .addMatcher(isAnyOf(updateChangeFlightSIMCard), (state, { payload: { tripId, added, paxId, code } }) => {
      const currentItem = state.changeFlightAddExtraMetaData.selectedArItems.find(
        (e) =>
          e.tripId === tripId &&
          e.journeyKey === undefined &&
          e.segmentKey === undefined &&
          e.arItems.find((n) => n.type === EArItemType.SIM_CARD)
      );
      if (currentItem) {
        const arItem = currentItem.arItems.find((e) => e.type === EArItemType.SIM_CARD);
        if (arItem?.byPax) {
          if (added) {
            remove(arItem.byPax, (e) => e.paxId === paxId);
          } else {
            arItem.byPax.push({
              paxId: paxId,
              amount: 1,
            });
          }
        } else {
          if (!added)
            currentItem.arItems.push({
              type: EArItemType.SIM_CARD,
              code,
              byPax: [
                {
                  paxId: paxId,
                  amount: 1,
                },
              ],
            });
        }
      } else {
        state.changeFlightAddExtraMetaData.selectedArItems.push({
          tripId,
          arItems: [
            {
              type: EArItemType.SIM_CARD,
              code,
              byPax: [
                {
                  paxId,
                  amount: 1,
                },
              ],
            },
          ],
        });
      }
    })
    .addMatcher(isAnyOf(confirmChangeFlightSIMCard), (state, { payload }) => {
      if (state.changeFlightTripDetail) {
        remove(state.changeFlightTripDetail.arItems.selected, (e) => e.type === EArItemType.SIM_CARD);
        payload.forEach((n) => {
          if (state.changeFlightTripDetail && n.tripId === state.changeFlightTripDetail?.id) {
            state.changeFlightTripDetail.arItems.selected = state.changeFlightTripDetail?.arItems.selected.concat(
              n.arItems
            );
          }
        });
      }
    });
});
