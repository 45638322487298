import { AMPortalPaymentSaga } from "modules/features/amPortal/sagas/paymentSaga";
import { authSaga } from "modules/features/auth/sagas/authSaga";
import { memberSaga } from "modules/features/auth/sagas/memberSaga";
import { nskTokenSaga } from "modules/features/auth/sagas/nskTokenSaga";
import { B2BMMBChangeFlightPaymentSaga } from "modules/features/b2b/agentPortal/sagas/b2bmmbChangeFlightPaymentSaga";
import { mmbB2BPaymentSaga } from "modules/features/b2b/agentPortal/sagas/b2bmmbPaymentSaga";
import { B2BMMBUnsettlePaymentSaga } from "modules/features/b2b/agentPortal/sagas/b2bmmbUnsettledPaymentSaga";
import { B2BPaymentSaga, b2bSaga } from "modules/features/b2b/sagas";
import { paymentSaga } from "modules/features/flightBooking/sagas/paymentSaga";
import { mmbChangeFlightPaymentSaga } from "modules/features/manageMyBooking/sagas/mmbChangeFlightPaymentSaga";
import { mmbPaymentSaga } from "modules/features/manageMyBooking/sagas/mmbPaymentSaga";
import { mmbUnsettlePaymentSaga } from "modules/features/manageMyBooking/sagas/mmbUnsettledPaymentSaga";
import { onlineCheckInPaymentSaga } from "modules/features/onlineCheckIn/sagas/paymentSaga";
import { currencySaga } from "modules/global/currency/currencySaga";
import { all, call, spawn } from "redux-saga/effects";

// Keeping everything alive: https://redux-saga.js.org/docs/advanced/RootSaga/
export function* rootSaga() {
  const sagas = [
    currencySaga,
    memberSaga,
    authSaga,
    paymentSaga,
    nskTokenSaga,
    mmbPaymentSaga,
    b2bSaga,
    mmbChangeFlightPaymentSaga,
    mmbUnsettlePaymentSaga,
    onlineCheckInPaymentSaga,
    B2BPaymentSaga,
    mmbB2BPaymentSaga,
    AMPortalPaymentSaga,
    B2BMMBChangeFlightPaymentSaga,
    B2BMMBUnsettlePaymentSaga,
  ];

  yield all(
    sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            console.log(e);
          }
        }
      })
    )
  );
}
