import { ESpacing } from "@hkexpressairwayslimited/ui";
import { Stack, StackProps, styled } from "@mui/material";

const OnlineCheckIn = styled(Stack)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: ESpacing._2xs,
  backgroundColor: theme.palette.purple.surfaceSubdued,
  color: theme.palette.primary.main,
  padding: `${ESpacing._2xs} ${ESpacing._s}`,
}));

const UnsettlePayment = styled(OnlineCheckIn)(({ theme }) => ({
  border: `1px solid ${theme.palette.critical.default}`,
  backgroundColor: theme.palette.critical.surface,
  color: theme.palette.critical.default,
}));

export default function WarningBox(props: StackProps) {
  return <UnsettlePayment {...props}>{props.children}</UnsettlePayment>;
}
