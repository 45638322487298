import { createAction } from "@reduxjs/toolkit";
import { ErrorModel } from "modules/common/common/types/errorType";

export const SHOW_ERROR_DIALOG = `ERROR_HANDLING/SHOW_ERROR_DIALOG`;
export const showErrorDialog = createAction<{
  title: string;
  content: string;
  onCloseCb: () => void;
  errorModel?: ErrorModel;
}>(SHOW_ERROR_DIALOG);

export const CLOSE_ERROR_DIALOG = `ERROR_HANDLING/CLOSE_ERROR_DIALOG`;
export const closeErrorDialog = createAction(CLOSE_ERROR_DIALOG);
