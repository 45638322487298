import debug from "debug";

const reduxClientLogger = debug("redux:client");
const reduxServerLogger = debug("redux:server");

if (typeof window !== "undefined") {
  reduxClientLogger.log = window.console.log.bind(window.console);
}

export { reduxClientLogger, reduxServerLogger };
