import { createReducer, isAnyOf } from "@reduxjs/toolkit";
import { LongPollingFormAction } from "lib/features/flight-book/payment/definition";
import { createAMPortalFormAction } from "./actions";

export interface AMPortalReducerProps {
  formAction?: LongPollingFormAction;
}

const initialState: AMPortalReducerProps = {
  formAction: undefined,
};

export const AMPortalReducer = createReducer(initialState, (builder) => {
  builder.addMatcher(isAnyOf(createAMPortalFormAction), (state, action) => {
    state.formAction = action.payload;
  });
});
