import { Card, CardContent, EFont, Font } from "@hkexpressairwayslimited/ui";
import { Stack } from "@mui/material";
import { useTransContent } from "modules/common/trans-content/transContent";
import { useCurrency } from "modules/global/currency/useCurrency";
import { EBaggageArCode, SelectableArItem } from "..";

export function BaggageItem({
  arItem,
  active,
  disabled,
  onBaggageSelect,
  purchased,
}: {
  arItem: SelectableArItem;
  active: boolean;
  disabled: boolean;
  purchased: boolean;
  onBaggageSelect: (arItem: SelectableArItem, active: boolean) => void;
}) {
  const { P } = useCurrency();
  const included = !!arItem.arMetaData?.included;
  const { t } = useTransContent();
  return (
    <Card
      onClick={() =>
        (!disabled || active) && !purchased && !included && onBaggageSelect && onBaggageSelect(arItem, active)
      }
      size='s'
      withBGC={active && !included ? "primary-light" : undefined}
      bodC={active && !included ? "active" : disabled ? "disabled" : "default"}
    >
      <CardContent sx={{ height: "100%" }}>
        <Stack
          alignItems='center'
          sx={{ minHeight: "72px", minWidth: "80px", cursor: "pointer" }}
          role='button'
          justifyContent={active === true && arItem.code === EBaggageArCode._0KG ? "flex-start" : "space-between"}
        >
          <Font
            fontWeight='fontWeightBold'
            color={(!active && disabled) || included ? "neutral.disable" : "neutral.black"}
          >
            {`${arItem.code === EBaggageArCode._0KG || included ? "" : "+"}${t(`${arItem.code}.title.short`)}`}
          </Font>
          {(active === false || arItem.code !== EBaggageArCode._0KG) && (
            <>
              {included && (
                <Font
                  variant={EFont.p3}
                  color={(!active && disabled) || included ? "neutral.disable" : "neutral.black"}
                >
                  {included ? t("web.addExtras.baggageSection") : ""}
                </Font>
              )}
              {purchased && (
                <Font
                  variant={EFont.p3}
                  color={(!active && disabled) || included ? "neutral.disable" : "neutral.black"}
                >
                  {purchased && arItem.code !== EBaggageArCode._0KG ? "added" : ""}
                </Font>
              )}
              <Font
                fontWeight='fontWeightBold'
                color={(!active && disabled) || included ? "neutral.disable" : "purple.default"}
              >
                {P(purchased || included ? 0 : arItem.price ?? 0)}
              </Font>
            </>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}
