"use client";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { getHCaptchaSetting } from "lib/features/flight-book/booking/service";

export class hCaptchaInstance {
  private static hCaptcha?: HCaptcha | null;
  private static hCaptchaConfig: Awaited<ReturnType<typeof getHCaptchaSetting>> = {
    isEnable: false,
  };

  static getInstance() {
    return this.hCaptcha;
  }

  static setInstance(hCaptcha?: HCaptcha | null) {
    this.hCaptcha = hCaptcha;
  }

  static getConfig() {
    return this.hCaptchaConfig;
  }

  static setConfig(hCaptchaConfig: Awaited<ReturnType<typeof getHCaptchaSetting>>) {
    this.hCaptchaConfig = hCaptchaConfig;
  }

  static async execute(...args: Parameters<HCaptcha["execute"]>) {
    return this.hCaptcha?.execute({ async: true, ...args[0] });
  }
}
