import { ETripType, PassengerCount, TripDetail } from "@hkexpressairwayslimited/ui";
import { isBefore } from "date-fns";
import { LowFareList } from "ui/features/flight-booking/flight-select/date-slider-selector/definition";
import { APIRequestBodyFlightSelectCreateOrder, FlightDetailAPIRequestBodyType, OrderPassenger } from "./definition";

function formateDate(date: string) {
  //Wed May 15 2024
  const time = new Date(date);
  const options: Intl.DateTimeFormatOptions = { weekday: "short", year: "numeric", month: "long", day: "numeric" };
  return time.toLocaleDateString("en-US", options);
}

function formateDateFormat2(date: string, variant?: string) {
  if (!date) return "";
  const time = new Date(date);
  const year = time.getFullYear();
  const month = (time.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed in JavaScript
  const day = time.getDate().toString().padStart(2, "0");
  return variant === "slash" ? `${day}/${month}/${year}` : `${year}-${month}-${day}`;
}

function formateTime(date: string) {
  const time = new Date(date);

  const hours = time.getHours().toString().padStart(2, "0");
  const minutes = time.getMinutes().toString().padStart(2, "0");

  return `${hours}:${minutes}`;
}

function calculateTimeDifference(departure: string, arrival: string) {
  const departureDate = new Date(departure);
  const arrivalDate = new Date(arrival);
  let minutes = "";
  // Calculate the difference in milliseconds
  const differenceInMs = arrivalDate.getTime() - departureDate.getTime();

  // Convert the difference to hours and minutes
  const differenceInHours = Math.floor(differenceInMs / (1000 * 60 * 60));
  const differenceInMinutes = Math.floor((differenceInMs / (1000 * 60)) % 60);
  if (differenceInMinutes > 0) {
    minutes = `${differenceInMinutes}m `;
  }
  return `${differenceInHours}h ${minutes}`;
}

function calculateDayDifference(departure: string, arrival: string) {
  const departureDate = new Date(departure);
  const arrivalDate = new Date(arrival);

  departureDate.setHours(0, 0, 0, 0);
  arrivalDate.setHours(0, 0, 0, 0);

  const differenceInTime = arrivalDate.getTime() - departureDate.getTime();
  const differenceInDays = differenceInTime / (1000 * 3600 * 24);
  return Math.ceil(differenceInDays);
}

function createFlightDetailRequestBody(data: FlightDetailAPIRequestBodyType, appCode: string, promotionCode?: string) {
  console.log("data", data);
  switch (data.tripType) {
    case ETripType.oneWay:
      return {
        application_code: appCode,
        flights: [
          {
            origin: data.firstOrigin,
            destination: data.firstDestination,
            begin_date: formateDateFormat2(data.firstDate),
          },
        ],
        currency_code: data.currency,
        promotion_code: promotionCode ?? "",
        passengers: data.passengers,
      };
    case ETripType.roundTrip:
      return {
        application_code: appCode,
        flights: [
          {
            origin: data.firstOrigin,
            destination: data.firstDestination,
            begin_date: formateDateFormat2(data.firstDate),
            end_date: formateDateFormat2(data.secondDate),
          },
        ],
        currency_code: data.currency,
        promotion_code: promotionCode ?? "",
        passengers: data.passengers,
      };
    case ETripType.multiCity:
      return {
        application_code: appCode,
        flights: [
          {
            origin: data.firstOrigin,
            destination: data.firstDestination,
            begin_date: formateDateFormat2(data.firstDate),
          },
          {
            origin: data.secondOrigin,
            destination: data.secondDestination,
            begin_date: formateDateFormat2(data.secondDate),
          },
        ].sort((a, b) => new Date(a.begin_date).getTime() - new Date(b.begin_date).getTime()),
        currency_code: data.currency,
        promotion_code: promotionCode ?? "",
        passengers: data.passengers,
      };
  }
}

function expandLowFareOptions(
  lowFareOptions: LowFareList,
  newOptions: LowFareList,
  setOptions: React.Dispatch<React.SetStateAction<LowFareList>>
) {
  const newDataFiltered = newOptions.filter(
    (newOptions) => !lowFareOptions.some((option) => option.date === newOptions.date)
  );
  console.log("New Data Filtered", newDataFiltered, lowFareOptions, newOptions);
  if (
    lowFareOptions.length > 0 &&
    newDataFiltered.length > 0 &&
    isBefore(newDataFiltered[newDataFiltered.length - 1].date, lowFareOptions[0].date)
  ) {
    setOptions([...newDataFiltered, ...lowFareOptions]);
    return [...newDataFiltered, ...lowFareOptions];
  } else {
    setOptions(lowFareOptions.concat(newDataFiltered));
    return lowFareOptions.concat(newDataFiltered);
  }
}

function constructSSRInfoBody(data: TripDetail) {
  const ssrJourney = data.journeys.map((journey) => {
    return {
      journey_key: journey.journey_key,
      segments: journey.segments.map((segment) => {
        return {
          segment_key: segment.segment_key,
          leg_key: segment.legs[0].leg_key,
        };
      }),
    };
  });
  return {
    currency_code: data.journeys[0].fare.currency_code,
    application_code: "IBE",
    journeys: ssrJourney,
  };
}

function constructCreateOrderBody(
  data: TripDetail,
  passengerCount: PassengerCount
): APIRequestBodyFlightSelectCreateOrder {
  const journeys = data.journeys.map((journey) => {
    return {
      journey_key: journey.journey_key,
      fare_availability_key: journey.fare.fare_availability_key,
    };
  });

  const adultCount = passengerCount.Adult;
  const childrenCount = passengerCount.Child > 0 ? passengerCount.Child : undefined;
  const infantCount = passengerCount.Infant > 0 ? passengerCount.Infant : undefined;

  const passengers: OrderPassenger = { adult_count: adultCount };

  if (childrenCount) {
    passengers.children_count = childrenCount;
  }

  if (infantCount) {
    passengers.infant_count = infantCount;
  }
  const currency_code = data.journeys[0].fare.currency_code;

  return {
    application_code: "IBE",
    journeys: journeys,
    passengers: passengers,
    currency_code: currency_code,
  };
}

export {
  calculateDayDifference,
  calculateTimeDifference,
  constructCreateOrderBody,
  constructSSRInfoBody,
  createFlightDetailRequestBody,
  expandLowFareOptions,
  formateDate,
  formateDateFormat2,
  formateTime,
};
