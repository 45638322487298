import { ArItem, EPaxType, TripDetail } from "@hkexpressairwayslimited/ui";
import { fetchAPI, getAccessTokenHeader, mmbEndPoints } from "api";
import { isAfter, isBefore } from "date-fns";
import { requiredHeader } from "lib/common/service";
import { APIFlightAvailability } from "lib/features/flight-book/flight-select/definition";
import { calculateDayDifference, formateDate, formateTime } from "lib/features/flight-book/flight-select/helper";
import { ErrorType, responseApiError } from "lib/features/flight-book/payment/definition";
import { ErrorCodeList, getErrorDetail } from "lib/features/flight-book/payment/errorCodeMapping";
import { closeLoadingBackdrop } from "modules/common/loading-backdrop/actions/LoadingBackdropAction";
import store from "store";
import { storeErrorModalDetail } from "store/sessionStorage/slices/errorModalDetail";
import { EArItemType } from "ui/features/flight-booking/add-extras";
import { LowFare } from "ui/features/flight-booking/flight-select/date-slider-selector/definition";
import {
  APIChangeFlightBody,
  APIChangeFlightSSRBody,
  APIGetBookingSSRlRequestBody,
  APIMMBFlightSearchBody,
  APIMMBOrderCreateBody,
  APIMMBOrderValidationBody,
  APIPnrRetrieveBookingRequestBody,
  APIResendEmailRequestBody,
  APIResendSMSRequestBody,
  APIUpdatePassengerDetailRequestBody,
  ETripStatus,
  ManageMyBookingTripDetail,
} from "./definition";
import { capitalizeFirstLetter, separateCountryCode } from "./helper";
import { MMBOrderValidationResponse } from "./payment/definition";

export function checkIsFlown(finalDate: string) {
  if (isAfter(new Date(), new Date(finalDate))) {
    return true;
  }
  return false;
}

export const retrieveBookingByPNR = async (req: APIPnrRetrieveBookingRequestBody, token: string) => {
  const result = await fetchAPI(mmbEndPoints.retrieveBookingByPNR, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...requiredHeader(),
      nsk_token: token,
    },
    body: JSON.stringify(req),
  });

  if (result.error_code) return;

  if (result.trips && result.trips.length > 0) {
    const simCard = result.trips[0].journeys[0]?.ssrs?.find((ssr: any) => ssr.type === "sim_card");

    const tripLevelSimCard = {
      type: simCard?.type.toUpperCase() ?? "SIM_CARD",
      code: simCard?.product[0].ssr_code ?? "",
      price: simCard?.product.reduce((a: number, c: any) => a + c.item_amount, 0) ?? 0,
      byPax:
        simCard?.product.map((p: any) => ({ paxId: p.passenger_key, amount: p.count, price: p.item_amount })) ?? [],
    };

    const tripLevelInsurance = result.trips[0].insurance.is_sold
      ? {
          type: "INSURANCE",
          code: "INSURANCE",
          price: result.trips[0].insurance_fees_total ?? 0,
          byPax: result.trips[0].passengers.map((pax: any) => ({ paxId: pax.passenger_key, amount: 1 })),
        }
      : undefined;

    const tripLevelCarbonOffset = result.trips[0].is_carbon_offset_sold
      ? {
          type: "CARBON_OFFSET",
          code: "CARBON_OFFSET",
          price: result.trips[0].carbon_offset_fees_total ?? 0,
          byPax: result.trips[0].passengers.map((pax: any) => ({ paxId: pax.passenger_key, amount: 1 })),
        }
      : undefined;

    const newResult: ManageMyBookingTripDetail = {
      sales_reference: result.trips[0].sales_reference,
      payment_detail: checkIsFlown(result.trips[0].journeys[result.trips[0].journeys.length - 1].departure_date_time)
        ? { ...result.trips[0].payment_detail, status: ETripStatus.flown }
        : result.trips[0].payment_detail,
      payment_record: result.trips[0].payment_record,
      change_flight_fees_total: result.trips[0]?.change_flight_fees_total ?? 0,
      convenience_fees_total: result.trips[0]?.convenience_fees_total ?? 0,
      spoilage_fees_total: result.trips[0]?.spoilage_fees_total ?? 0,
      is_customer_editable: result.trips[0].is_customer_editable,
      is_flight_cancel: result.trips[0].is_flight_cancel,
      is_flight_itinerary_viewable: result.trips[0].is_flight_itinerary_viewable,
      insurance: result.trips[0].insurance,
      insurance_fees_total: result.trips[0]?.insurance_fees_total ?? 0,
      carbon_offset: result.trips[0].carbon_offset,
      carbon_offset_fees_total: result.trips[0]?.carbon_offset_fees_total ?? 0,
      contact: result.trips[0]?.contact
        ? {
            ...result.trips[0].contact,
            title: capitalizeFirstLetter(result.trips[0].contact.title),
            phone_number: separateCountryCode(result.trips[0].contact.phone_number).phoneNumber,
            country: separateCountryCode(result.trips[0].contact.phone_number).country,
            countryCode: separateCountryCode(result.trips[0].contact.phone_number).countryCode,
          }
        : undefined,
      passengers: result.trips[0].passengers.map((pax: any) => {
        if (pax.passenger_type === "ADT")
          return {
            id: pax.passenger_key,
            paxType: EPaxType.Adult,
            courtesy: capitalizeFirstLetter(pax.title),
            givenName: pax.first_name,
            surname: pax.last_name,
            DOB: pax.date_of_birth,
            email: pax.email,
            mobile: {
              countryCode: pax.phone_number ? separateCountryCode(pax.phone_number).countryCode : "852",
              mobileNumber: pax.phone_number ? separateCountryCode(pax.phone_number).phoneNumber : "",
            },
            primaryContact: pax.is_primary_contact,
            docType: pax.travel_document ? pax.travel_document.type : "",
            docIssuingCountry: pax.travel_document ? pax.travel_document.issuing_country : "",
            docNationality: pax.travel_document ? pax.travel_document.nationality : "",
            docNumber: pax.travel_document ? pax.travel_document.number : "",
            docExpirationDate: pax.travel_document ? pax.travel_document.expiry_date : "",
          };
        else if (pax.passenger_type === "CHD")
          return {
            id: pax.passenger_key,
            paxType: EPaxType.Child,
            courtesy: capitalizeFirstLetter(pax.title),
            givenName: pax.first_name,
            surname: pax.last_name,
            DOB: pax.date_of_birth,
            // primaryContact: pax.is_primary_contact,
            docType: pax.travel_document ? pax.travel_document.type : "",
            docIssuingCountry: pax.travel_document ? pax.travel_document.issuing_country : "",
            docNationality: pax.travel_document ? pax.travel_document.nationality : "",
            docNumber: pax.travel_document ? pax.travel_document.number : "",
            docExpirationDate: pax.travel_document ? pax.travel_document.expiry_date : "",
          };
        else if (pax.passenger_type === "EXT")
          return {
            id: pax.passenger_key,
            paxType: "EXT",
            courtesy: capitalizeFirstLetter(pax.title),
            givenName: pax.first_name,
            surname: pax.last_name,
            DOB: pax.date_of_birth,
            // primaryContact: pax.is_primary_contact,
            docType: pax.travel_document ? pax.travel_document.type : "",
            docIssuingCountry: pax.travel_document ? pax.travel_document.issuing_country : "",
            docNationality: pax.travel_document ? pax.travel_document.nationality : "",
            docNumber: pax.travel_document ? pax.travel_document.number : "",
            docExpirationDate: pax.travel_document ? pax.travel_document.expiry_date : "",
          };
        else
          return {
            id: "INF",
            paxType: EPaxType.Infant,
            courtesy: capitalizeFirstLetter(pax.title),
            givenName: pax.first_name,
            surname: pax.last_name,
            DOB: pax.date_of_birth,
            // primaryContact: pax.is_primary_contact,
            docType: pax.travel_document ? pax.travel_document.type : "",
            docIssuingCountry: pax.travel_document ? pax.travel_document.issuing_country : "",
            docNationality: pax.travel_document ? pax.travel_document.nationality : "",
            docNumber: pax.travel_document ? pax.travel_document.number : "",
            docExpirationDate: pax.travel_document ? pax.travel_document.expiry_date : "",
          };
      }),
      journeys: result.trips[0].journeys.map((journey: any, index: number) => {
        const minutes = journey.duration.minutes && journey.duration.minutes > 0 ? `${journey.duration.minutes}m` : "";
        return {
          index: index,
          journey_key: journey.journey_key,
          fare: {
            fare_availability_key: journey.fare.fare_availability_key ?? "",
            currency_code: journey.fare.currency_code,
            adult_fare_details: journey.fare.adult_fare_details,
            child_fare_details: journey.fare.child_fare_details,
            infant_details: journey.fare.infant_details,
            bundle_offers: [
              {
                bundle_code: journey.fare.bundle.bundle_code,
                type: journey.fare.bundle.type,
                currency_code: journey.fare.currency_code,
                adult_amount: journey.fare.bundle.adult_price,
                child_amount: journey.fare.bundle.child_price,
                bundles: journey.fare.bundle.bundle_ssr,
              },
            ],
          },
          origin: journey.origin.airport_code,
          destination: journey.destination.airport_code,
          date_time: journey.departure_date_time,
          date: formateDate(journey.departure_date_time),
          day_diff: calculateDayDifference(journey.departure_date_time, journey.arrival_date_time),
          duration: {
            hour: journey.duration.hours,
            minute: journey.duration.minutes,
          },
          eligibilities: journey.eligibilities,
          journey_eligibility: journey.journey_eligibility,
          display_item_amount: 0,
          flight_time: `${journey.duration.hours}h ${minutes}`,
          segments: journey.segments.map((segment: any) => {
            return {
              segment_key: segment.segment_key,
              flight_number: segment.flight_number,
              destination: segment.destination,
              origin: segment.origin,
              arrival: segment.arrival_date_time,
              arrival_time: formateTime(segment.arrival_date_time),
              arrival_date: formateDate(segment.arrival_date_time),
              departure: segment.departure_date_time,
              departure_time: formateTime(segment.departure_date_time),
              departure_date: formateDate(segment.departure_date_time),
              duration: {
                hour: segment.duration.hours,
                minute: segment.duration.minutes,
              },
              is_ferry: segment.is_ferry,
              origin_airport_terminal: segment.origin_airport_terminal,
              destination_airport_terminal: segment.destination_airport_terminal,
              arItems: {
                available: [],
                selected: [],
                purchased: segment.ssrs
                  .reduce((a: Record<string, any>, ssr: any) => {
                    ssr.product.forEach((product: any) => {
                      const index = a.findIndex((ar: any) => ar.code === product.ssr_code);
                      if (index === -1) {
                        a.push({
                          type: ssr.type.toUpperCase(),
                          code: product.ssr_code,
                          price: product.item_amount * product.count,
                          byPax: [
                            {
                              paxId: product.passenger_key,
                              amount: product.count,
                              price: product.item_amount * product.count,
                            },
                          ],
                        });
                      } else {
                        if (!a[index].byPax.some((pax: any) => pax.paxId === product.passenger_key)) {
                          a[index].price = a[index].price + product.item_amount * product.count;
                          a[index].byPax.push({
                            paxId: product.passenger_key,
                            amount: product.count,
                            price: product.item_amount * product.count,
                          });
                        } else {
                          a[index] = {
                            ...a[index],
                            price: a[index].price + product.item_amount * product.count,
                            byPax: a[index].byPax.map((pax: any) =>
                              pax.paxId === product.passenger_key
                                ? {
                                    paxId: pax.paxId,
                                    amount: pax.amount + product.count,
                                    price: pax.price + product.item_amount * product.count,
                                  }
                                : pax
                            ),
                          };
                        }
                      }
                    });
                    return a;
                  }, [])
                  .concat(
                    segment.seats.reduce((a: Record<string, any>, seat: any) => {
                      a.push({
                        type: "SEAT",
                        unit_key: seat.unit_key,
                        is_exit_row: seat.is_exit_row,
                        code: seat.designator,
                        price: seat.item_amount,
                        byPax: [
                          {
                            paxId: seat.passenger_key,
                            amount: 1,
                          },
                        ],
                      });

                      return a;
                    }, [])
                  ),
              },
            };
          }),
          arItems: {
            available: [],
            selected: [],
            purchased: journey.ssrs.reduce((a: Record<string, any>, ssr: any) => {
              ssr.product.forEach((product: any) => {
                const index = a.findIndex((ar: any) => ar.code === product.ssr_code);
                if (index === -1 && ssr.type !== "sim_card") {
                  a.push({
                    type: ssr.type.toUpperCase(),
                    code: product.ssr_code,
                    price: product.item_amount * product.count,
                    byPax: [
                      {
                        paxId: product.passenger_key,
                        amount: product.count,
                        price: product.item_amount * product.count,
                      },
                    ],
                  });
                } else {
                  if (a[index] && !a[index].byPax.some((pax: any) => pax.paxId === product.passenger_key)) {
                    a[index].price = a[index].price + product.item_amount * product.count;
                    a[index].byPax.push({
                      paxId: product.passenger_key,
                      amount: product.count,
                      price: product.item_amount * product.count,
                    });
                  } else if (a[index]) {
                    a[index] = {
                      ...a[index],
                      price: a[index].price + product.item_amount * product.count,
                      byPax: a[index].byPax.map((pax: any) =>
                        pax.paxId === product.passenger_key
                          ? {
                              paxId: pax.paxId,
                              amount: pax.amount + product.count,
                              price: pax.price + product.item_amount * product.count,
                            }
                          : pax
                      ),
                    };
                  }
                }
              });
              return a;
            }, []),
          },
        };
      }),
      arItems: {
        available: [],
        selected: [],
        purchased: [
          ...(simCard ? [tripLevelSimCard] : []),
          ...(tripLevelInsurance ? [tripLevelInsurance] : []),
          ...(tripLevelCarbonOffset ? [tripLevelCarbonOffset] : []),
        ],
      },
      isGDS: result.trips[0]?.contact ? false : true,
    };
    return newResult;
  }
};

export const retrieveBookingByMemberID = async (req: any, token: string) => {
  const accessTokenHeader = getAccessTokenHeader();
  const result = await fetchAPI(mmbEndPoints.retrieveBookingByMemberID, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...accessTokenHeader,
      ...requiredHeader(),
      nsk_token: token,
    },
    body: JSON.stringify(req),
  });

  const newResult: ManageMyBookingTripDetail[] = result.content.map((trip: any) => {
    const simCard = trip.journeys[0]?.ssrs?.find((ssr: any) => ssr.type === "sim_card");

    const tripLevelSimCard = {
      type: simCard?.type.toUpperCase() ?? "SIM_CARD",
      code: simCard?.product[0].ssr_code ?? "",
      price: simCard?.product.reduce((a: number, c: any) => a + c.item_amount, 0) ?? 0,
      byPax:
        simCard?.product.map((p: any) => ({ paxId: p.passenger_key, amount: p.count, price: p.item_amount })) ?? [],
    };

    const tripLevelInsurance = trip.insurance.is_sold
      ? {
          type: "INSURANCE",
          code: "INSURANCE",
          price: trip.insurance_fees_total,
        }
      : undefined;

    const tripLevelCarbonOffset = trip.is_carbon_offset_sold
      ? {
          type: "CARBON_OFFSET",
          code: "CARBON_OFFSET",
          price: trip.carbon_offset_fees_total,
        }
      : undefined;

    return {
      sales_reference: trip.sales_reference,
      payment_detail: checkIsFlown(trip.journeys[trip.journeys.length - 1].departure_date_time)
        ? { ...trip.payment_detail, status: ETripStatus.flown }
        : trip.payment_detail,
      payment_record: trip.payment_record,
      change_flight_fees_total: trip?.change_flight_fees_total ?? 0,
      convenience_fees_total: trip?.convenience_fees_total ?? 0,
      spoilage_fees_total: trip.spoilage_fees_total ?? 0,
      is_customer_editable: trip.is_customer_editable,
      is_flight_cancel: trip.is_flight_cancel,
      is_flight_itinerary_viewable: trip.is_flight_itinerary_viewable,
      insurance: trip.insurance,
      insurance_fees_total: trip?.insurance_fees_total ?? 0,
      carbon_offset: trip.carbon_offset,
      carbon_offset_fees_total: trip?.carbon_offset_fees_total ?? 0,
      contact: trip?.contact
        ? {
            ...trip.contact,
            title: capitalizeFirstLetter(trip.contact.title),
            phone_number: separateCountryCode(trip.contact.phone_number).phoneNumber,
            country: separateCountryCode(trip.contact.phone_number).country,
            countryCode: separateCountryCode(trip.contact.phone_number).countryCode,
          }
        : undefined,
      passengers: trip.passengers.map((pax: any) => {
        if (pax.passenger_type === "ADT")
          return {
            id: pax.passenger_key,
            paxType: EPaxType.Adult,
            courtesy: capitalizeFirstLetter(pax.title),
            givenName: pax.first_name,
            surname: pax.last_name,
            DOB: pax.date_of_birth,
            email: pax.email,
            mobile: {
              countryCode: pax.phone_number ? separateCountryCode(pax.phone_number).countryCode : "852",
              mobileNumber: pax.phone_number ? separateCountryCode(pax.phone_number).phoneNumber : "",
            },
            primaryContact: pax.is_primary_contact,
            docType: pax.travel_document ? pax.travel_document.type : "",
            docIssuingCountry: pax.travel_document ? pax.travel_document.issuing_country : "",
            docNationality: pax.travel_document ? pax.travel_document.nationality : "",
            docNumber: pax.travel_document ? pax.travel_document.number : "",
            docExpirationDate: pax.travel_document ? pax.travel_document.expiry_date : "",
          };
        else if (pax.passenger_type === "CHD")
          return {
            id: pax.passenger_key,
            paxType: EPaxType.Child,
            courtesy: capitalizeFirstLetter(pax.title),
            givenName: pax.first_name,
            surname: pax.last_name,
            DOB: pax.date_of_birth,
            // primaryContact: pax.is_primary_contact,
            docType: pax.travel_document ? pax.travel_document.type : "",
            docIssuingCountry: pax.travel_document ? pax.travel_document.issuing_country : "",
            docNationality: pax.travel_document ? pax.travel_document.nationality : "",
            docNumber: pax.travel_document ? pax.travel_document.number : "",
            docExpirationDate: pax.travel_document ? pax.travel_document.expiry_date : "",
          };
        else if (pax.passenger_type === "EXT")
          return {
            id: pax.passenger_key,
            paxType: "EXT",
            courtesy: capitalizeFirstLetter(pax.title),
            givenName: pax.first_name,
            surname: pax.last_name,
            DOB: pax.date_of_birth,
            // primaryContact: pax.is_primary_contact,
            docType: pax.travel_document ? pax.travel_document.type : "",
            docIssuingCountry: pax.travel_document ? pax.travel_document.issuing_country : "",
            docNationality: pax.travel_document ? pax.travel_document.nationality : "",
            docNumber: pax.travel_document ? pax.travel_document.number : "",
            docExpirationDate: pax.travel_document ? pax.travel_document.expiry_date : "",
          };
        else
          return {
            id: "INF",
            paxType: EPaxType.Infant,
            courtesy: capitalizeFirstLetter(pax.title),
            givenName: pax.first_name,
            surname: pax.last_name,
            DOB: pax.date_of_birth,
            // primaryContact: pax.is_primary_contact,
            docType: pax.travel_document ? pax.travel_document.type : "",
            docIssuingCountry: pax.travel_document ? pax.travel_document.issuing_country : "",
            docNationality: pax.travel_document ? pax.travel_document.nationality : "",
            docNumber: pax.travel_document ? pax.travel_document.number : "",
            docExpirationDate: pax.travel_document ? pax.travel_document.expiry_date : "",
          };
      }),
      journeys: trip.journeys.map((journey: any, index: number) => {
        const minutes = journey.duration.minutes && journey.duration.minutes > 0 ? `${journey.duration.minutes}m` : "";
        return {
          index: index,
          journey_key: journey.journey_key,
          fare: {
            fare_availability_key: journey.fare.fare_availability_key,
            currency_code: journey.fare.currency_code,
            adult_fare_details: journey.fare.adult_fare_details,
            child_fare_details: journey.fare.child_fare_details,
            infant_details: journey.fare.infant_details,
            bundle_offers: [
              {
                bundle_code: journey.fare.bundle.bundle_code,
                type: journey.fare.bundle.type,
                currency_code: journey.fare.currency_code,
                adult_amount: journey.fare.bundle.adult_price,
                child_amount: journey.fare.bundle.child_price,
                bundles: journey.fare.bundle.bundle_ssr,
              },
            ],
          },
          origin: journey.origin.airport_code,
          destination: journey.destination.airport_code,
          date_time: journey.departure_date_time,
          date: formateDate(journey.departure_date_time),
          day_diff: calculateDayDifference(journey.departure_date_time, journey.arrival_date_time),
          duration: {
            hour: journey.duration.hours,
            minute: journey.duration.minutes,
          },
          eligibilities: journey.eligibilities,
          journey_eligibility: journey.journey_eligibility,
          display_item_amount: 0,
          flight_time: `${journey.duration.hours}h ${minutes}`,
          segments: journey.segments.map((segment: any) => {
            return {
              segment_key: segment.segment_key,
              flight_number: segment.flight_number,
              destination: segment.destination,
              origin: segment.origin,
              arrival: segment.arrival_date_time,
              arrival_time: formateTime(segment.arrival_date_time),
              arrival_date: formateDate(segment.arrival_date_time),
              arrival_timezone: "",
              departure: segment.departure_date_time,
              departure_time: formateTime(segment.departure_date_time),
              departure_date: formateDate(segment.departure_date_time),
              departure_timezone: "",
              legs: [
                {
                  leg_key: "",
                },
              ],
              duration: {
                hour: segment.duration.hours,
                minute: segment.duration.minutes,
              },
              is_ferry: segment.is_ferry,
              origin_airport_terminal: segment.origin_airport_terminal,
              destination_airport_terminal: segment.destination_airport_terminal,
              arItems: {
                available: [],
                selected: [],
                purchased: segment.ssrs
                  .reduce((a: Record<string, any>, ssr: any) => {
                    ssr.product.forEach((product: any) => {
                      const index = a.findIndex((ar: any) => ar.code === product.ssr_code);

                      if (index === -1) {
                        a.push({
                          type: ssr.type.toUpperCase(),
                          code: product.ssr_code,
                          price: product.item_amount * product.count,
                          byPax: [
                            {
                              paxId: product.passenger_key,
                              amount: product.count,
                              price: product.item_amount * product.count,
                            },
                          ],
                        });
                      } else {
                        if (!a[index].byPax.some((pax: any) => pax.paxId === product.passenger_key)) {
                          a[index].price = a[index].price + product.item_amount * product.count;
                          a[index].byPax.push({
                            paxId: product.passenger_key,
                            amount: product.count,
                            price: product.item_amount * product.count,
                          });
                        } else {
                          a[index] = {
                            ...a[index],
                            price: a[index].price + product.item_amount * product.count,
                            byPax: a[index].byPax.map((pax: any) =>
                              pax.paxId === product.passenger_key
                                ? {
                                    paxId: pax.paxId,
                                    amount: pax.amount + product.count,
                                    price: pax.price + product.item_amount * product.count,
                                  }
                                : pax
                            ),
                          };
                        }
                      }
                    });
                    return a;
                  }, [])
                  .concat(
                    segment.seats.reduce((a: Record<string, any>, seat: any) => {
                      a.push({
                        type: "SEAT",
                        unit_key: seat.unit_key,
                        is_exit_row: seat.is_exit_row,
                        code: seat.designator,
                        price: seat.item_amount,
                        byPax: [
                          {
                            paxId: seat.passenger_key,
                            amount: 1,
                          },
                        ],
                      });

                      return a;
                    }, [])
                  ),
              },
            };
          }),
          arItems: {
            available: [],
            selected: [],
            purchased: journey.ssrs.reduce((a: Record<string, any>, ssr: any) => {
              ssr.product.forEach((product: any) => {
                const index = a.findIndex((ar: any) => ar.code === product.ssr_code);
                if (index === -1 && ssr.type !== "sim_card") {
                  a.push({
                    type: ssr.type.toUpperCase(),
                    code: product.ssr_code,
                    price: product.item_amount * product.count,
                    byPax: [
                      {
                        paxId: product.passenger_key,
                        amount: product.count,
                        price: product.item_amount * product.count,
                      },
                    ],
                  });
                } else if (a[index] && !a[index].byPax.some((pax: any) => pax.paxId === product.passenger_key)) {
                  a[index].price = a[index].price + product.item_amount * product.count;
                  a[index].byPax.push({
                    paxId: product.passenger_key,
                    amount: product.count,
                    price: product.item_amount * product.count,
                  });
                } else if (a[index]) {
                  a[index] = {
                    ...a[index],
                    price: a[index].price + product.item_amount * product.count,
                    byPax: a[index].byPax.map((pax: any) =>
                      pax.paxId === product.passenger_key
                        ? {
                            paxId: pax.paxId,
                            amount: pax.amount + product.count,
                            price: pax.price + product.item_amount,
                          }
                        : pax
                    ),
                  };
                }
              });
              return a;
            }, []),
          },
        };
      }),
      arItems: {
        available: [],
        selected: [],
        purchased: [
          ...(simCard ? [tripLevelSimCard] : []),
          ...(tripLevelInsurance ? [tripLevelInsurance] : []),
          ...(tripLevelCarbonOffset ? [tripLevelCarbonOffset] : []),
        ],
      },
      isGDS: trip?.contact ? false : true,
    };
  });
  return newResult;
};

export const resendItineraryEmail = async (req: APIResendEmailRequestBody, token: string) => {
  const result = await fetchAPI(mmbEndPoints.resendEmail, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...requiredHeader(),
      nsk_token: token,
    },
    body: JSON.stringify(req),
  });

  return result;
};

export const resendItinerarySMS = async (req: APIResendSMSRequestBody, token: string) => {
  const result = await fetchAPI(mmbEndPoints.resendSMS, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...requiredHeader(),
      nsk_token: token,
    },
    body: JSON.stringify(req),
  });

  return result;
};

export const getBookingSSR = async (req: APIGetBookingSSRlRequestBody, token: string) => {
  const result = await fetchAPI(mmbEndPoints.getBookingSSR, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...requiredHeader(),
      nsk_token: token,
    },
    body: JSON.stringify(req),
  });
  let simCard: ArItem[] = [];

  result.journeys[0]?.ssrs
    .map((ssr: any) => {
      if (ssr.type === EArItemType.SIM_CARD && ssr.is_offer && ssr.product.length > 0)
        simCard = ssr.product.map((ar: any) => ({
          type: ssr.type.toUpperCase(),
          code: ar.ssr_code,
          price: ar.ssr_by_passengers[0].price,
          availability: ar.available,
          currency_code: ar.currency_code,
          ssr_by_passengers: ar.ssr_by_passengers,
          limit_per_passenger: ar.limit_per_passenger,
        }));
    })
    .flat();

  const tripSSR = result.trip;

  const remappedResult = result.journeys.map((journey: any, i: number) => {
    return {
      ...journey,
      ssrs: journey.ssrs
        .flatMap((ssr: any) => {
          if (ssr.is_offer && ssr.type !== "SIM_CARD")
            return ssr.product.map((ar: any) => {
              return {
                type: ssr.type.toUpperCase(),
                code: ar.ssr_code,
                price: ar.ssr_by_passengers[0].price,
                availability: ar.available,
                cms_content_key: ar.cms_content_key,
                currency_code: ar.currency_code,
                ssr_by_passengers: ar.ssr_by_passengers,
                limit_per_passenger: ar.limit_per_passenger,
              };
            });
        })
        .filter(Boolean),
      segments: journey.segments.map((segment: any) => {
        return {
          ...segment,
          ssrs: segment.ssrs?.flatMap((ssr: any) => {
            if (ssr.is_offer)
              return ssr.product.map((ar: any) => ({
                type: ssr.type.toUpperCase(),
                code: ar.ssr_code,
                price: ar.ssr_by_passengers[0].price,
                availability: ar.available,
                cms_content_key: ar.cms_content_key,
                currency_code: ar.currency_code,
                ...(ar.is_pre_order !== undefined && { is_pre_order: ar.is_pre_order }),
                ...(ar.is_vegetarian !== undefined && { is_vegetarian: ar.is_vegetarian }),
                ssr_by_passengers: ar.ssr_by_passengers,
                limit_per_passenger: ar.limit_per_passenger,
              }));
          }),
        };
      }),
    };
  });

  const olci_nsk_token =
    result.journeys.map((journey: any) => {
      return {
        olci_nsk_token: journey?.olci_nsk_token,
        idle_timeout_second: journey?.idle_timeout_second,
        journey_key: journey?.journey_key,
      };
    }) ?? [];

  return { remappedResult, simCard, tripSSR, olci_nsk_token };
};

export const updatePassengerDetail = async (req: APIUpdatePassengerDetailRequestBody, token: string) => {
  const result = await fetchAPI(mmbEndPoints.changePassenger, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...requiredHeader(),
      nsk_token: token,
    },
    body: JSON.stringify(req),
  });
  return result;
};

export const orderValidateAddAncilieo = async (req: APIMMBOrderValidationBody): Promise<MMBOrderValidationResponse> => {
  const accessTokenHeader = getAccessTokenHeader();
  const result = await fetchAPI(mmbEndPoints.orderValidateAddAncilieo, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...requiredHeader(),
      ...accessTokenHeader,
      nsk_token: store.getState().session.nskToken.nsk_token,
    },
    body: JSON.stringify(req),
    errorCodeWhitelist: ErrorCodeList,
  }).catch((error: responseApiError) => {
    const errorDetail = getErrorDetail(error.error_code);
    errorDetail && store.dispatch(storeErrorModalDetail(errorDetail));
  });
  return result;
};

export const orderCreateAddAncilieo = async (req: APIMMBOrderCreateBody) => {
  const accessTokenHeader = getAccessTokenHeader();

  const result = await fetchAPI(mmbEndPoints.orderCreateAddAncilieo, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...requiredHeader(),
      ...accessTokenHeader,
      nsk_token: store.getState().session.nskToken.nsk_token,
    },
    body: JSON.stringify(req),
    errorCodeWhitelist: ErrorCodeList,
    captcha: window.hCaptchaConfig?.isEnable,
  }).catch((error: responseApiError) => {
    const errorDetail = getErrorDetail(error.error_code);
    errorDetail && store.dispatch(storeErrorModalDetail(errorDetail));
  });
  return result;
};

export const retrieveChangeFlightLowFare = async (
  req: APIMMBFlightSearchBody
): Promise<{
  trip: {
    destination: string;
    origin: string;
    low_fare: LowFare[];
  }[];
}> => {
  const result = await fetchAPI(mmbEndPoints.mmbLowFare, {
    method: "POST",
    cache: "no-store",
    headers: {
      "Content-Type": "application/json",
      ...requiredHeader(),
      nsk_token: store.getState().session.nskToken.nsk_token,
    },
    body: JSON.stringify(req),
  });

  return result;
};

export const retrieveChangeFlightAvailability = async (req: APIMMBFlightSearchBody) => {
  const result = await fetchAPI(
    mmbEndPoints.mmbFlightSearch,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...requiredHeader(),
        nsk_token: store.getState().session.nskToken.nsk_token,
      },
      cache: "no-store",
      body: JSON.stringify(req),
    },
    true
  ).catch((error: responseApiError) => {
    const { error_code } = error;
    if (error_code === "FLT_BOOKING_QUERY_SVC_PASSENGER_TYPE_CHANGED") {
      store.dispatch(
        storeErrorModalDetail({
          desc: "Cannot select this travel date",
          title: "An error has occurred",
          type: ErrorType.M,
        })
      );
      store.dispatch(closeLoadingBackdrop());
    }
  });

  const newResult: TripDetail[] = result?.error_code
    ? []
    : result.trip.map((trip: APIFlightAvailability) => {
        return {
          journeys: trip.journey
            .map((journey) => {
              const minutes =
                journey.duration.minute && journey.duration.minute > 0 ? `${journey.duration.minute}m` : "";
              return {
                tripOrigin: trip.origin.airport_code,
                tripDestination: trip.destination.airport_code,
                arrival_date_time: journey.arrival_date_time,
                departure_date_time: journey.departure_date_time,
                journey_key: journey.journey_key,
                fare: journey.fare,
                origin: journey.segment[0].origin,
                destination: journey.segment[journey.segment.length - 1].destination,
                date: formateDate(journey.departure_date_time),
                day_diff: calculateDayDifference(journey.departure_date_time, journey.arrival_date_time),
                duration: journey.duration,
                segments: journey.segment.map((segment) => {
                  return {
                    segment_key: segment.segment_key,
                    flight_number: segment.flight_number,
                    destination: segment.destination,
                    origin: segment.origin,
                    arrival: segment.arrival_date_time,
                    arrival_time: formateTime(segment.arrival_date_time),
                    arrival_date: formateDate(segment.arrival_date_time),
                    departure: segment.departure_date_time,
                    departure_time: formateTime(segment.departure_date_time),
                    departure_date: formateDate(segment.departure_date_time),
                    arrival_timezone: segment.arrival_timezone,
                    departure_timezone: segment.departure_timezone,
                    duration: segment.duration,
                    is_ferry: segment.is_ferry,
                    legs: segment.legs,
                    origin_airport_terminal: segment.origin_airport_terminal,
                    destination_airport_terminal: segment.destination_airport_terminal,
                    arItems: {
                      available: [],
                      selected: [],
                      purchased: [],
                    },
                  };
                }),
                is_promoted: journey.is_promoted,
                display_item_amount: journey.display_item_amount,
                flight_time: `${journey.duration.hour}h ${minutes}`,
                arItems: {
                  available: [],
                  selected: [],
                  purchased: [],
                },
              };
            })
            .sort((a, b) => (isBefore(new Date(a.departure_date_time), new Date(b.departure_date_time)) ? -1 : 1)),
          arItems: {
            available: [],
            selected: [],
            purchased: [],
          },
        };
      });

  return newResult;
};

export const callChangeFlight = async (req: APIChangeFlightBody) => {
  const result = await fetchAPI(mmbEndPoints.changeFlight, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...requiredHeader(),
      nsk_token: store.getState().session.nskToken.nsk_token,
    },
    body: JSON.stringify(req),
  });

  const simCard = result.transferred_ssrs[0]?.ssrs?.find((ssr: any) => ssr.type === "sim_card");

  const tripLevelSimCard = simCard
    ? {
        type: simCard?.type.toUpperCase(),
        code: simCard?.product[0].ssr_code,
        price: simCard?.product.reduce((a: number, c: any) => a + c.item_amount, 0) ?? 0,
        byPax: simCard?.product.map((p: any) => ({ paxId: p.passenger_key, amount: p.count })) ?? [],
      }
    : undefined;

  const purchasedSSR = result.transferred_ssrs.map((journey: any, i: number) => {
    return {
      ...journey,
      ssrs: journey.ssrs
        .filter((ssr: any) => ssr.type !== "sim_card")
        .flatMap((ssr: any) => {
          return ssr.product.reduce((a: Record<string, any>, c: any) => {
            const index = a.findIndex((ar: any) => c.ssr_code === ar.code);
            if (index > -1) {
              a[index].price = a[index].price + c.item_amount * c.count;
              a[index].byPax.push({
                paxId: c.passenger_key,
                amount: c.count,
                price: c.item_amount * c.count,
              });
            } else {
              a.push({
                type: ssr.type.toUpperCase(),
                code: c.ssr_code,
                price: c.item_amount * c.count,
                byPax: [
                  {
                    paxId: c.passenger_key,
                    amount: c.count,
                    price: c.item_amount * c.count,
                  },
                ],
              });
            }
            return a;
          }, []);
        }),
      segments:
        journey?.segments &&
        journey.segments.map((segment: any) => {
          return {
            ...segment,
            ssrs: segment.ssrs.flatMap((ssr: any) => {
              return ssr.product.reduce((a: Record<string, any>, c: any) => {
                const index = a.findIndex((ar: any) => c.ssr_code === ar.code);
                if (index > -1) {
                  a[index].price = a[index].price + c.item_amount * c.count;
                  a[index].byPax.push({
                    paxId: c.passenger_key,
                    amount: c.count,
                    price: c.item_amount * c.count,
                  });
                } else {
                  a.push({
                    type: ssr.type.toUpperCase(),
                    code: c.ssr_code,
                    price: c.item_amount * c.count,
                    byPax: [
                      {
                        paxId: c.passenger_key,
                        amount: c.count,
                        price: c.item_amount * c.count,
                      },
                    ],
                  });
                }
                return a;
              }, []);
            }),
          };
        }),
    };
  });

  const notTransferSSR = result.not_transferred_ssrs.map((journey: any) => {
    return {
      ...journey,
      ssrs: journey.ssrs.flatMap((ssr: any) => {
        return ssr.product
          .filter((ar: any) => ar.ssr_code !== "SIM_CARD")
          .reduce((a: Record<string, any>, c: any) => {
            const index = a.findIndex((ar: any) => c.ssr_code === ar.code);
            if (index > -1) {
              a[index].byPax.push({
                paxId: c.passenger_key,
                amount: c.count,
              });
            } else {
              a.push({
                type: ssr.type.toUpperCase(),
                code: c.ssr_code,
                byPax: [
                  {
                    paxId: c.passenger_key,
                    amount: c.count,
                  },
                ],
              });
            }
            return a;
          }, []);
      }),
      segments:
        journey?.segments &&
        journey.segments.map((segment: any) => {
          return {
            ...segment,
            ssrs: segment.ssrs.flatMap((ssr: any) => {
              return ssr.product.reduce((a: Record<string, any>, c: any) => {
                const index = a.findIndex((ar: any) => c.ssr_code === ar.code);
                if (index > -1) {
                  a[index].byPax.push({
                    paxId: c.passenger_key,
                    amount: c.count,
                  });
                } else {
                  a.push({
                    type: ssr.type.toUpperCase(),
                    code: c.ssr_code,
                    byPax: [
                      {
                        paxId: c.passenger_key,
                        amount: c.count,
                      },
                    ],
                  });
                }
                return a;
              }, []);
            }),
          };
        }),
    };
  });

  const fees = {
    due_balance: result.due_balance ?? 0,
    change_flight_fees_total: result.change_flight_fees_total ?? 0,
    spoilage_fees_total: result.spoilage_fees_total ?? 0,
  };

  return { purchasedSSR, notTransferSSR, fees, tripLevelSimCard };
};

export const retrieveChangeFlightSSR = async (req: APIChangeFlightSSRBody) => {
  const result = await fetchAPI(mmbEndPoints.changeFlightSSR, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...requiredHeader(),
      nsk_token: store.getState().session.nskToken.nsk_token,
    },
    cache: "no-store",
    body: JSON.stringify(req),
  });

  let simCard: ArItem[] = [];

  result.journeys[0].ssrs
    .map((ssr: any) => {
      if (ssr.type === EArItemType.SIM_CARD && ssr.is_offer && ssr.product.length > 0)
        simCard = ssr.product.map((ar: any) => ({
          type: ssr.type.toUpperCase(),
          code: ar.ssr_code,
          price: ar.ssr_by_passengers[0].price,
          availability: ar.available,
          currency_code: ar.currency_code,
          ssr_by_passengers: ar.ssr_by_passengers,
          limit_per_passenger: ar.limit_per_passenger,
        }));
    })
    .flat();

  const remappedResult = result.journeys.map((journey: any, i: number) => {
    return {
      ...journey,
      ssrs: journey.ssrs
        ?.flatMap((ssr: any) => {
          if (ssr.is_offer && ssr.type !== "SIM_CARD")
            return ssr.product.map((ar: any) => {
              return {
                type: ssr.type.toUpperCase(),
                code: ar.ssr_code,
                price: ar.ssr_by_passengers[0].price,
                availability: ar.available,
                cms_content_key: ar.cms_content_key,
                currency_code: ar.currency_code,
                ssr_by_passengers: ar.ssr_by_passengers,
                limit_per_passenger: ar.limit_per_passenger,
              };
            });
        })
        .filter(Boolean),
      segments: journey.segments.map((segment: any) => {
        return {
          ...segment,
          ssrs: segment.ssrs
            ?.flatMap((ssr: any) => {
              if (ssr.is_offer)
                return ssr.product.map((ar: any) => ({
                  type: ssr.type.toUpperCase(),
                  code: ar.ssr_code,
                  price: ar.ssr_by_passengers[0].price,
                  availability: ar.available,
                  cms_content_key: ar.cms_content_key,
                  currency_code: ar.currency_code,
                  ...(ar.is_pre_order !== undefined && { is_pre_order: ar.is_pre_order }),
                  ...(ar.is_vegetarian !== undefined && { is_vegetarian: ar.is_vegetarian }),
                  ssr_by_passengers: ar.ssr_by_passengers,
                  limit_per_passenger: ar.limit_per_passenger,
                }));
            })
            .filter(Boolean),
        };
      }),
    };
  });

  const insurance = result.trip.insurance.quote ?? undefined;
  const carbonQuote = result.trip.carbon_offset.quote ?? undefined;

  return { remappedResult, simCard, insurance, carbonQuote };
};

export const changeFlightOrderValidation = async (req: any) => {
  const accessTokenHeader = getAccessTokenHeader();
  const result = await fetchAPI(mmbEndPoints.changeFlightOrderValidation, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...requiredHeader(),
      ...accessTokenHeader,
      nsk_token: store.getState().session.nskToken.nsk_token,
    },
    cache: "no-store",
    body: JSON.stringify(req),
  });
  return result;
};

export const changeFlightOrderCreation = async (req: any) => {
  const accessTokenHeader = getAccessTokenHeader();
  const result = await fetchAPI(mmbEndPoints.changeFlightOrderCreation, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...requiredHeader(),
      ...accessTokenHeader,
      nsk_token: store.getState().session.nskToken.nsk_token,
    },
    cache: "no-store",
    body: JSON.stringify(req),
    captcha: window.hCaptchaConfig?.isEnable,
  });
  return result;
};

export const unsettledPaymentOrderValidation = async (req: any) => {
  const accessTokenHeader = getAccessTokenHeader();
  const result = await fetchAPI(mmbEndPoints.unsettlePaymentOrderValidation, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...requiredHeader(),
      ...accessTokenHeader,
      nsk_token: store.getState().session.nskToken.nsk_token,
    },
    cache: "no-store",
    body: JSON.stringify(req),
  });
  return result;
};

export const unsettledPaymentOrderCreation = async (req: any) => {
  const accessTokenHeader = getAccessTokenHeader();
  const result = await fetchAPI(mmbEndPoints.unsettlePaymentOrderCreation, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...requiredHeader(),
      ...accessTokenHeader,
      nsk_token: store.getState().session.nskToken.nsk_token,
    },
    cache: "no-store",
    body: JSON.stringify(req),
    captcha: window.hCaptchaConfig?.isEnable,
  });
  return result;
};
