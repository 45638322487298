import debug from "debug";

const i18nBrowserLogger = debug("i18n:browser");
const i18nServerLogger = debug("i18n:server");

if (typeof window !== "undefined") {
  i18nBrowserLogger.log = window.console.info.bind(window.console);
}

export { i18nBrowserLogger, i18nServerLogger };
