import {
  ArItem,
  ArItems,
  Button,
  ButtonVariant,
  EBorders,
  EFont,
  EPaxType,
  ESpacing,
  Font,
  IBEPax,
  SimCardIcon,
  TickCircleIcon,
  TripDetail,
} from "@hkexpressairwayslimited/ui";
import { Box, Stack, SvgIcon } from "@mui/material";
import Image from "next/image";

import { useTransContent } from "modules/common/trans-content/transContent";
import { calcAllPickedArItems } from "modules/features/flightBooking/calculateCost/calcAllPickedArItems";
import { useTripCost } from "modules/features/flightBooking/calculateCost/useTripCost";
import { SelectedArItems } from "modules/features/flightBooking/reducers";
import { useCurrency } from "modules/global/currency/useCurrency";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAddExtrasPageMetaData,
  updateAddExtrasPageMetaData,
} from "store/sessionStorage/slices/flightBookingSlice";
import { OnSimCardSelectedData, SelectItem, Summary } from ".";
import { EArItemType, EOtherArCode, JourneyConf, paxTypeIconMapping } from "..";
import { Drawer } from "../../Drawer";
type SimCardData = {
  unavailable: boolean;
  totalCost: string | number;
  price: string | number;
  code?: string;
  noStock: boolean;
  selected:
    | {
        paxId: string;
        amount: number;
      }[]
    | undefined;
  purchased?:
    | {
        paxId: string;
        amount: number;
      }[]
    | undefined;
};
type SimCardDrawerProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  passengers: IBEPax[];
  code: EOtherArCode | undefined;
  currentConf?: JourneyConf<SimCardData>;
  onUserAddSimCard: (paxId: string, added: boolean, code: string) => void;
  confirmLabel?: string | JSX.Element | JSX.Element[];
  autoClose?: boolean;
};
export function SimCardSection({
  code,
  price,
  passengers,
  tripDetail,
  selectedSimCardArItem = [],
  onSimCardConfirmed = (b: boolean) => {},
  onSimCardSelected,
  onOpenSimCardDrawer,
}: {
  code: EOtherArCode;
  passengers: IBEPax[];
  price?: string | JSX.Element;
  tripDetail: TripDetail;
  selectedSimCardArItem: SelectedArItems[];
  onSimCardConfirmed?: (b: boolean) => void;
  onSimCardSelected: (data: OnSimCardSelectedData) => void;
  onOpenSimCardDrawer?: () => void;
}) {
  const dispatch = useDispatch();
  const metaData = useSelector(selectAddExtrasPageMetaData)?.confirmedItems;
  const [confirmed, setConfirmed] = useState(metaData ? metaData[EArItemType.SIM_CARD] ?? false : false);
  const [open, setOpen] = useState(false);
  const simCardCost = useTripCost(EArItemType.SIM_CARD, tripDetail && [tripDetail.arItems]);
  const selectedUFirstCost = calcAllPickedArItems(
    selectedSimCardArItem?.reduce((a: ArItems[], c) => {
      a.push({
        available: tripDetail.arItems.available ?? [],
        selected: c.arItems ?? [],
        purchased: [],
      });
      return a;
    }, []),
    undefined,
    true
  );
  const simConf = useMemo(() => {
    const passengerId = passengers?.reduce((a: string[], i) => [...a, i.id], []);
    const selectAmount =
      selectedSimCardArItem
        .find((e) => e.tripId === tripDetail.id)
        ?.arItems.reduce((a: number, c: ArItem) => {
          if (c.byPax) {
            return a + c.byPax.reduce((a1, c1) => a1 + c1.amount, 0);
          }
          return a;
        }, 0) ?? 0;

    const confirmedAmount =
      tripDetail.arItems.selected
        .filter((e: ArItem) => e.type === EArItemType.SIM_CARD)
        .reduce((a: number, c: ArItem) => {
          if (c.byPax) {
            return a + c.byPax.reduce((a1, c1) => a1 + c1.amount, 0);
          }
          return a;
        }, 0) +
      tripDetail.arItems.purchased
        .filter((e: ArItem) => e.type === EArItemType.SIM_CARD)
        .reduce((a: number, c: ArItem) => {
          if (c.byPax) {
            return a + c.byPax.reduce((a1, c1) => (passengerId?.includes(c1.paxId) ? a1 + c1.amount : a1), 0);
          }
          return a;
        }, 0);
    const {
      availability = 0,
      price = 0,
      code,
    } = tripDetail.arItems.available.find((e: ArItem) => e.type === EArItemType.SIM_CARD) ?? {};
    return {
      confirmedAmount,
      id: tripDetail.id,
      data: {
        code,
        noStock: (availability ?? 0) === 0,
        unavailable: selectAmount >= (availability ?? 0),
        totalCost: selectedUFirstCost.toString(),
        price: price.toString(),
        selected: selectedSimCardArItem.find((e) => e.tripId === tripDetail.id)?.arItems[0].byPax,
        purchased: tripDetail.arItems.purchased.filter((e) => e.type === EArItemType.SIM_CARD)[0]?.byPax,
        isAllPassengerPurchased: (() => {
          const purchasedUsers = tripDetail.arItems.purchased.filter((e) => e.type === EArItemType.SIM_CARD)[0]?.byPax;
          return passengers.every((passenger) => {
            return purchasedUsers && purchasedUsers.find((purchasedUser) => purchasedUser.paxId === passenger.id);
          });
        })(),
        isSomePurchased: (() => {
          const purchasedUsers = tripDetail.arItems.purchased.filter((e) => e.type === EArItemType.SIM_CARD)[0]?.byPax;
          return passengers.some((passenger) => {
            return purchasedUsers && purchasedUsers.find((purchasedUser) => purchasedUser.paxId === passenger.id);
          });
        })(),
      },
    };
  }, [
    selectedSimCardArItem,
    selectedUFirstCost,
    tripDetail.arItems.available,
    tripDetail.arItems.purchased,
    tripDetail.arItems.selected,
    tripDetail.id,
  ]);

  useEffect(() => {
    if (confirmed === true) {
      dispatch(
        updateAddExtrasPageMetaData({
          confirmedItems: {
            [EArItemType.SIM_CARD]: true,
          },
        })
      );
      setOpen(false);
    }
  }, [confirmed, dispatch]);

  const handelUserAddSimCard = useCallback(
    (paxId: string, added: boolean, code: string) => {
      // dispatch(updateSIMCard({ paxId, added, code }));
      onSimCardSelected({ tripId: tripDetail.id, paxId, added, code });
    },
    [onSimCardSelected, tripDetail.id]
  );
  const { P } = useCurrency();
  return (
    <SelectItem
      totalCost={P(simCardCost)}
      code={code}
      price={price}
      onClick={() => {
        setOpen(true);
        onOpenSimCardDrawer && onOpenSimCardDrawer();
      }}
      summary={
        confirmed || simConf.data.isSomePurchased ? (
          <SimCardSummary selectedAmount={simConf.confirmedAmount} />
        ) : undefined
      }
      isAllPurchased={simConf.data.isAllPassengerPurchased}
    >
      <SimCardDrawer
        autoClose={true}
        currentConf={simConf}
        passengers={passengers}
        open={open}
        code={code}
        onClose={() => setOpen(false)}
        onConfirm={() => {
          setConfirmed(true);
          setOpen(false);
          onSimCardConfirmed(true);
        }}
        onUserAddSimCard={handelUserAddSimCard}
      />
    </SelectItem>
  );
}
export function SimCardDrawer({
  open,
  onClose,
  onConfirm,
  passengers,
  confirmLabel,
  onUserAddSimCard,
  currentConf,
  autoClose = false,
  code,
}: SimCardDrawerProps) {
  const userSelected = useCallback(
    (id: string) => {
      if (currentConf && currentConf.data && currentConf.data.selected) {
        return !!currentConf.data.selected.find((n) => n.paxId === id);
      }
      return false;
    },
    [currentConf]
  );

  const userPurchased = useCallback(
    (paxId: string) => {
      if (currentConf?.data?.purchased) {
        return !!currentConf?.data?.purchased.find((e) => e.paxId === paxId);
      }
      return false;
    },
    [currentConf]
  );
  const { t } = useTransContent();
  const { P } = useCurrency();
  return (
    <Drawer
      autoClose={autoClose}
      title={`${t("web.addExtras.SimCardDrawer.header.title")}`}
      open={open}
      onClose={onClose}
      avatar={<SimCardIcon />}
      totalCost={P(currentConf?.data?.totalCost)}
      onConfirm={onConfirm}
      confirmLabel={t("web.manageMyBooking.myTrips.addItems.btn")}
      crossIconClose={true}
    >
      <Stack spacing={ESpacing._sm}>
        <Stack direction='row' alignItems='center'>
          <Stack spacing={ESpacing._sm}>
            <Font variant={EFont.h3} fontWeight='fontWeightBold'>
              {t("web.addExtras.SimCardDrawer.header")}
            </Font>
            <Font>{t(`web.addExtras.SimCardDrawer.description.${code}`)}</Font>
          </Stack>
          <Box ml={ESpacing._sm}>
            {/* If there is no valid mirror path or the path is empty, the mirror path is defaulted, otherwise an invalid path error is reported */}
            <Image
              src={t(`web.addExtras.SimCardDrawer.image.${code}`) as string}
              width='74'
              height='122'
              alt='SimCard'
            />
          </Box>
        </Stack>
        <Stack>
          {passengers.map((e) => {
            if (currentConf && currentConf.data) {
              const { code = "", noStock, price, unavailable } = currentConf.data;
              return (
                <SimCardItem
                  noStock={noStock}
                  unavailable={unavailable}
                  selected={userSelected(e.id) || userPurchased(e.id)}
                  purchased={userPurchased(e.id)}
                  key={e.id}
                  passenger={e}
                  onClick={(paxId: string, added: boolean) => onUserAddSimCard(paxId, added, code)}
                  price={price}
                />
              );
            }
          })}
        </Stack>
        <Font>{t("web.addExtras.SimCardDrawer.context")}</Font>
      </Stack>
    </Drawer>
  );
}

function SimCardItem({
  passenger: { id, paxType, courtesy, surname, givenName },
  selected,
  onClick,
  price,
  unavailable,
  purchased,
  noStock,
}: {
  passenger: IBEPax;
  selected: boolean;
  onClick: (paxId: string, added: boolean) => void;
  price?: string | number;
  unavailable: boolean;
  noStock: boolean;
  purchased: boolean;
}) {
  const { t } = useTransContent();
  const { P } = useCurrency();
  const [added, setAdded] = useState(selected);
  const addedBackgroundObj = !(purchased || (unavailable && !added)) && added ? { backgroundColor: "#E2D5E9" } : {};
  return (
    <Stack sx={{ paddingY: ESpacing._sm, borderBottom: EBorders.b1 }} direction='row' justifyContent='space-between'>
      <Stack direction='row' alignItems='center' spacing={ESpacing._2xs}>
        {paxTypeIconMapping[paxType as EPaxType]}
        <Font variant={EFont.h3} fontWeight='fontWeightBold'>{`${courtesy} ${surname} ${givenName}`}</Font>
      </Stack>
      <Stack direction='row' alignItems='center' spacing={ESpacing._2xs}>
        <Font variant={EFont.h2} color='purple.default' fontWeight='fontWeightBold'>
          {P(price)}
        </Font>
        <Button
          style={{ minWidth: "96px", ...addedBackgroundObj }}
          variant={ButtonVariant.Secondary}
          disabled={purchased || (unavailable && !added)}
          onClick={() => {
            if (purchased || (unavailable && !added)) return;
            setAdded(!added);
            onClick && onClick(id, added);
          }}
        >
          {noStock
            ? t("web.addExtras.SimCard.noStock")
            : added || purchased
              ? t("web.flightBooking.otherSection.button.added")
              : t("web.flightBooking.otherSection.button.add")}
        </Button>
      </Stack>
    </Stack>
  );
}

export function SimCardSummary({ selectedAmount }: { selectedAmount: number }) {
  const { t } = useTransContent();
  return (
    <Summary>
      <Stack direction='row' spacing={ESpacing._2xs}>
        <SvgIcon>
          <TickCircleIcon />
        </SvgIcon>
        <Stack spacing={ESpacing._2xs} width='100%'>
          <Font color='purple.default'>{t("web.addEXtras.SimCardSummary.add")}</Font>
          <Stack direction='row' justifyContent='space-between'>
            <Stack direction='row' spacing={ESpacing._2xs} alignItems='center'>
              <SvgIcon sx={{ color: "neutral.black" }}>
                <SimCardIcon />
              </SvgIcon>
              <Font fontWeight='fontWeightBold'>{t("web.addExtras.SimCardDrawer.header")}</Font>
            </Stack>
            <Font>X{selectedAmount}</Font>
          </Stack>
        </Stack>
      </Stack>
    </Summary>
  );
}
