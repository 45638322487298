import { ArItem, ArItems, Journey } from "@hkexpressairwayslimited/ui";
import { MMBJourney } from "lib/features/manage-my-booking/my-trips/definition";
import { useMemo } from "react";
import { EArItemType } from "ui/features/flight-booking/add-extras";
import { calcAllPickedArItems } from "./calcAllPickedArItems";
export function useJourneyCost(str?: EArItemType, tripArItems?: ArItems[]) {
  // const storedJourneyArItem = getStoredJourneyArItems(useSelector(selectTripJourneys));
  return useMemo(() => {
    return calcAllPickedArItems(tripArItems ? tripArItems : [], str ? (e) => e.type === str : undefined, true);
  }, [tripArItems, str]);
}

export function getStoredJourneyArItems(journeys?: Journey[] | MMBJourney[]) {
  return journeys?.reduce<ArItems[]>((a, c) => [...a, c.arItems], []) ?? undefined;
}

export function getJourneyExtrasCost(journey: Journey | MMBJourney) {
  const journeyArCost = journey.arItems.purchased.reduce((a, c) => (c.price ? a + c.price : a), 0);
  const segmentArCost = journey.segments.reduce(
    (a, c) => c.arItems.purchased.reduce((a1, c1) => (c1.price ? a1 + c1.price : a1), 0) + a,
    0
  );

  return journeyArCost + segmentArCost;
}

export function getStoredSelectedJourneyArItems(journeys?: Journey[] | MMBJourney[]) {
  return journeys?.reduce((a: ArItem[], c: Journey | MMBJourney) => a.concat([...c.arItems.selected]), []) ?? undefined;
}
