import { CURRENCY_OPTIONS } from "@hkexpressairwayslimited/ui";
import { envConfig } from "env";
import Cookies from "js-cookie";
import { INIT_APP_START } from "modules/common/common/actions/initAction";
import { put, takeLatest } from "redux-saga/effects";
import { storeCurrencyDetail } from "store/sessionStorage/slices/currency";
import { getCurrencyRate } from "./service";

export function* initCurrency(): Generator<any, void, Awaited<ReturnType<typeof getCurrencyRate>>> {
  const currency = Cookies.get("currency") || envConfig.defaultCurrency;
  const selectedCurrency = CURRENCY_OPTIONS.find((option) => option.value === currency)?.value ?? "";
  yield put(storeCurrencyDetail({ selectedCurrency }));
}
export function* currencySaga() {
  yield takeLatest(INIT_APP_START, initCurrency);
}
