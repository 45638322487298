import { createReducer, isAnyOf } from "@reduxjs/toolkit";
import { initSaga, resetNskToken, storeNskToken } from "../actions/nskTokenAction";

export type nskTokenState = {
  nsk_token: string | undefined;
  expiry_time: number | undefined;
  app_code: string | undefined;
  saga_status: boolean | undefined;
};

const initialState: nskTokenState = {
  nsk_token: undefined,
  expiry_time: undefined,
  app_code: undefined,
  saga_status: false,
};

export const nskTokenReducer = createReducer(initialState, (builder) => {
  builder
    .addMatcher(isAnyOf(storeNskToken), (state, action) => {
      state.nsk_token = action.payload.nsk_token;
      state.expiry_time = action.payload.expiry_time;
      state.app_code = action.payload.app_code;
    })
    .addMatcher(isAnyOf(initSaga), (state) => {
      state.saga_status = true;
    })
    .addMatcher(isAnyOf(resetNskToken), () => initialState);
});
