import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "store";

export type FlightRouteMapping = {
  flight_route_mappings: {
    destination: string[];
    origin: string;
  }[];
  sales_port_grouping: {
    market: string;
    ports: {
      airport_code: string;
      create_date_time: string;
      created_by: string;
      is_insurance_enabled: string;
      is_pier: boolean;
      market: string;
      time_zone: string;
      update_date_time: string;
      updated_by: string;
      version: string;
    }[];
    default_currency: string;
  }[];
};

const initialState: FlightRouteMapping = {
  flight_route_mappings: [],
  sales_port_grouping: [],
};

export const flightRouteMappingSlice = createSlice({
  name: "flightRouteMapping",
  initialState,
  reducers: {
    storeFlightRouteMapping: (state, action) => {
      state.flight_route_mappings = action.payload.flight_route_mappings;
      state.sales_port_grouping = action.payload.sales_port_grouping;
    },
  },
  selectors: {
    selectSalesPortGrouping: (storage) => storage.sales_port_grouping ?? [],
  },
});

export const { storeFlightRouteMapping } = flightRouteMappingSlice.actions;
export default flightRouteMappingSlice.reducer;
export const { selectSalesPortGrouping } = flightRouteMappingSlice.getSelectors((state: RootState) => {
  return state.session.flightRouteMapping;
});
