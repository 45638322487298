import { OLCIPassengersDetails } from "@hkexpressairwayslimited/ui";
import { createAction } from "@reduxjs/toolkit";
import { InitPaymentSaga, LongPollingFormAction } from "lib/features/flight-book/payment/definition";
const namespace = "ONLINE_CHECK_IN";

export const UPDATE_STEPPER_INDEX = `${namespace}/UPDATE_STEPPER_INDEX`;
export const updateStepperActive = createAction<number>(UPDATE_STEPPER_INDEX);

export const CHECK_IN_CONFIRMATION_RESPONSE = `${namespace}/CHECK_IN_CONFIRMATION_RESPONSE`;
export const checkInConfirmationActive = createAction<boolean>(CHECK_IN_CONFIRMATION_RESPONSE);

export const CLEAR_SELECTED_PASSENGERS = `${namespace}/SELECT_PASSENGERS_RESPONSE`;
export const clearSelectedPassengers = createAction<boolean>(CLEAR_SELECTED_PASSENGERS);

export const STORE_PASSENGER_DETAIL = `${namespace}/STORE_PASSENGER_DETAIL`;
export const storePassengerDetails = createAction<OLCIPassengersDetails>(STORE_PASSENGER_DETAIL);

export const PAYMENT_RESPONSE_ACTIVE = `${namespace}/PAYMENT_RESPONSE_ACTIVE`;
export const paymentResponseActive = createAction<boolean>(PAYMENT_RESPONSE_ACTIVE);

export const INIT_OLCI_PAYMENT_SAGA = `${namespace}/INIT_OLCI_PAYMENT_SAGA`;
export const initOLCIPaymentSaga = createAction<Partial<InitPaymentSaga>>(INIT_OLCI_PAYMENT_SAGA);

export const CREATE_OLCI_FORM_ACTION = `${namespace}/CREATE_OLCI_FORM_ACTION`;
export const createOLCIFormAction = createAction<LongPollingFormAction>(CREATE_OLCI_FORM_ACTION);
