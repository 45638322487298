import { AppRouterInstance, NavigateOptions } from "next/dist/shared/lib/app-router-context.shared-runtime";
import { getLangLocale } from "modules/common/common/utils/i18n-utils";

export class RouterInstance {
  private static router: AppRouterInstance;

  static getRouterInstance(): AppRouterInstance {
    return RouterInstance.router;
  }

  static setRouterInstance(router: AppRouterInstance) {
    RouterInstance.router = router;
  }

  static parsePath = (path: string) => {
    const currLang = getLangLocale();
    const parsedPath = path.replace(":lang", currLang);
    return parsedPath;
  };

  public static push = (url: any, options?: NavigateOptions) => {
    if (typeof url === "string") {
      this.router.push(this.parsePath(url), options);
    } else {
      this.router.push(url, options);
    }
  };

  public static replace = (url: any, options?: NavigateOptions) => {
    if (typeof url === "string") {
      this.router.replace(this.parsePath(url), options);
    } else {
      this.router.replace(url, options);
    }
  };
  public static back = () => this.router.back();
}
