import { createAction } from "@reduxjs/toolkit";
import config from "../package.json";

export type NskTokenPayload = {
  app_code: string;
  access_token: string;
  expiry_date: Date;
  expiry_time: number;
};

export const INIT_NSK_TOKEN = `${config.name}/INIT_NSK_TOKEN`;
export const initNskToken = createAction<NskTokenPayload>(INIT_NSK_TOKEN);

export const CANCEL_INIT_NSK_TOKEN = `${config.name}/CANCEL_INIT_NSK_TOKEN`;
export const cancelInitNskToken = createAction(CANCEL_INIT_NSK_TOKEN);

export const STORE_NSK_TOKEN = `${config.name}/STORE_NSK_TOKEN`;
export const storeNskToken = createAction<{ nsk_token: string; expiry_time: number; app_code: string }>(
  STORE_NSK_TOKEN
);

export const RESET_NSK_TOKEN = `${config.name}/RESET_NSK_TOKEN`;
export const resetNskToken = createAction(RESET_NSK_TOKEN);

export const INIT_SAGA = `${config.name}/INIT_SAGA`;
export const initSaga = createAction(INIT_SAGA);
