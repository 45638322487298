import { EMAIL_REGEXP as EMAIL_REGEXP_UI } from "@hkexpressairwayslimited/ui";
import { addDays } from "date-fns";

export { COUNTRY_CODE } from "@hkexpressairwayslimited/ui";

export const DEFAULT_NEXT_DAY = addDays(new Date(), 1);

export const EMAIL_REGEXP = EMAIL_REGEXP_UI;

export const MOBILE_NUMBER_MIN = 6;

export const STORED_CONTACT_KEY = "contactDetails";

export const MOBILE_NUMBER = /^\d{6,}\d$/;
