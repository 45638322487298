import { Journey, Segment } from "@hkexpressairwayslimited/ui";
import { SelectedArItems } from "modules/features/flightBooking/reducers";
import { useMemo } from "react";
import { JourneyConf, NavButtons } from "..";
type MealNavButtonData = {
  // selectAmount:number
  journey_key: string;
};
export type SegmentsSwitcherClickData = {
  journey_key?: string;
  segment_key: string;
  tripId?: string;
};
type SegmentsSwitcherProps = {
  journeys: Journey[];
  data: SegmentsSwitcherClickData;
  onClick: (data: SegmentsSwitcherClickData) => void;
  selectedArItem: SelectedArItems[];
};
export default function SegmentsSwitcher({
  journeys,
  data: { segment_key, tripId },
  onClick,
  selectedArItem,
}: SegmentsSwitcherProps) {
  // const [selectedJourneyKey, setSelectedJourneyKey] = useState(journey_key || journeys[0].journey_key);
  // const [selectedSegment, setSelectedSelectedSegment] = useState(segment_key || journeys[0].segments[0].segment_key);
  const buttonsConf = useMemo<JourneyConf<MealNavButtonData>[]>(() => {
    return journeys.reduce((a: JourneyConf<MealNavButtonData>[], c: Journey) => {
      return [
        ...a,
        ...c.segments.reduce((a1: JourneyConf<MealNavButtonData>[], c1: Segment) => {
          const selectAmount = selectedArItem
            .filter((e) => e.journeyKey === c.journey_key && e.segmentKey === c1.segment_key && e.tripId === tripId)
            .reduce(
              (a2, c2) =>
                (a2 += c2.arItems.reduce(
                  (a3, c3) => (a3 += c3.byPax?.reduce((a4, c4) => (a4 += c4.amount), 0) ?? 0),
                  0
                )),
              0
            );
          a1.push({
            id: c1.segment_key,
            label: `${c1.origin} - ${c1.destination}`,
            selectAmount,
            data: {
              journey_key: c.journey_key,
            },
          } as JourneyConf<MealNavButtonData>);
          return a1;
        }, []),
      ];
    }, []);
  }, [journeys, selectedArItem, tripId]);
  return (
    <NavButtons<MealNavButtonData>
      buttons={buttonsConf}
      selected={segment_key}
      onClick={(id: string, data?: MealNavButtonData) => {
        // setSelectedSelectedSegment(id);
        // // setSelectMeal('')
        // data && data.journey_key && setSelectedJourneyKey(data.journey_key);
        onClick &&
          onClick({
            segment_key: id,
            journey_key: data?.journey_key,
          });
      }}
    />
  );
}
