"use client";
import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { Reducer, combineReducers } from "redux";
import { createLogger } from "redux-logger";
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from "redux-persist";
import createSagaMiddleware, { Saga } from "redux-saga";
import { createStateSyncMiddleware, initMessageListener } from "redux-state-sync";

import { errorModalReducer } from "modules/common/error-dialog/reducers/errorDialogReducer";
import { loadingBackdropReducer } from "modules/common/loading-backdrop/reducers/loadingBackdropReducer";
import { authReducer } from "modules/features/auth/reducers/authReducer";
import { rootSaga } from "store/rootSaga";

import { reduxClientLogger, reduxServerLogger } from "loggers";
import { AMPortalReducer } from "modules/features/amPortal/reducer";
import { nskTokenReducer } from "modules/features/auth/reducers/nskTokenReducer";
import { B2BReducer } from "modules/features/b2b/reducer";
import { FlightBookingReducer } from "modules/features/flightBooking/reducers";
import { ManageMyBookingReducer } from "modules/features/manageMyBooking/reducers";
import { MemberReducer } from "modules/features/member/reducers";
import { OnlineCheckInReducer } from "modules/features/onlineCheckIn/reducer";
import paymentReducer from "modules/features/payment/reducers/paymentReducer";
import { generalReducer } from "modules/global/general";
import { localStorageReducer } from "./localStorage";
import { sessionStorageReducer } from "./sessionStorage";

// Create the saga middleware
const sagaMiddleware = createSagaMiddleware();

// Reducer registry
const staticReducers: { [key: string]: unknown } = {
  local: localStorageReducer,
  session: sessionStorageReducer,
  general: generalReducer,
  loadingBackdrop: loadingBackdropReducer,
  errorDialog: errorModalReducer,
  auth: authReducer,
  payment: paymentReducer,
  flightBooking: FlightBookingReducer,
  b2b: B2BReducer,
  manageMyBooking: ManageMyBookingReducer,
  onlineCheckIn: OnlineCheckInReducer,
  member: MemberReducer,
  nskToken: nskTokenReducer,
  amPortal: AMPortalReducer,
  // [postApi.reducerPath]: postApi.reducer,
};

export type RootState = ReturnType<typeof store.getState>;

// Saga registry
const staticSagas: { [key: string]: Saga } = {
  // auth: authSaga,
};

const rootReducer = combineReducers({
  ...staticReducers,
});

// Mount it on the Store
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    //https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
    getDefaultMiddleware({
      serializableCheck: { ignoreState: true, ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER] },
    }).concat(
      [
        sagaMiddleware,
        typeof window !== "undefined"
          ? (createStateSyncMiddleware({
              whitelist: [],
              receiveState: (prevState, nextState) => ({
                ...prevState,
                [localStorageReducer.name]: nextState[localStorageReducer.name],
              }),
            }) as any)
          : undefined,
        (typeof window === "undefined" ? reduxServerLogger.enabled : reduxClientLogger.enabled) &&
          createLogger({
            logger: typeof window === "undefined" ? { log: reduxServerLogger } : { log: reduxClientLogger },
          }),
      ].filter(Boolean)
    ),
  // .concat(postApi.middleware),
});
sagaMiddleware.run(rootSaga);
setupListeners(store.dispatch);
initMessageListener(store);

export const injectAsyncReducer = (moduleName: string, reducer: Reducer) => {
  store.replaceReducer(createReducers(moduleName, reducer) as any);
};

export const createReducers = (moduleName: string, reducer: Reducer) => {
  if (!staticReducers[moduleName]) {
    staticReducers[moduleName] = reducer;
  }

  return combineReducers(staticReducers);
};

export const runSaga = (moduleName: string, saga: Saga) => {
  if (!staticSagas[moduleName]) {
    staticSagas[moduleName] = saga;
    sagaMiddleware.run(saga);
  }
};

export default store;
export { store };
