import { combineSlices } from "@reduxjs/toolkit";
import { onlineCheckInSlice } from "store/sessionStorage/slices/onlineCheckInSlice";

import { B2BSlice } from "store/sessionStorage/slices/b2bSlice";
import { flightBookingSlice } from "store/sessionStorage/slices/flightBookingSlice";
import { manageB2BBookingSlice } from "store/sessionStorage/slices/manageB2BBookingSlice";
import { manageMyBookingSlice } from "store/sessionStorage/slices/manageMyBookingSlice";
import { memberSlice } from "store/sessionStorage/slices/memberSlice";
import { nskTokenSlice } from "store/sessionStorage/slices/nskTokenSlice";
import { AMPortal } from "../slices/AMPortal";
import { ccPaymentMetaData } from "../slices/ccPaymentMetaData";
import { currencySlice } from "../slices/currency";
import { errorModalDetailSlice } from "../slices/errorModalDetail";
import { flightRouteMappingSlice } from "../slices/flightRouteMapping";
import { globalMetaData } from "../slices/globalMetaData";

export const reducer = combineSlices(
  errorModalDetailSlice,
  currencySlice,
  memberSlice,
  flightBookingSlice,
  AMPortal,
  manageMyBookingSlice,
  manageB2BBookingSlice,
  nskTokenSlice,
  B2BSlice,
  flightRouteMappingSlice,
  onlineCheckInSlice,
  ccPaymentMetaData,
  globalMetaData
);
