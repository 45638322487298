import { Box } from "@hkexpressairwayslimited/ui";
import { RouterInstance } from "router/router-utils";
import { ErrorMapping, ErrorMessageDetail, ErrorType } from "./definition";

export const ErrorCodeMapping: ErrorMapping = {
  // ErrorType.Detail
  ORDER_SVC_SELL_TRIP_FAILURE: {
    type: ErrorType.D,
    title: "ORDER_SVC_SELL_TRIP_FAILURE.title",
    desc: "ORDER_SVC_SELL_TRIP_FAILURE.desc",
    ctaText: "ORDER_SVC_SELL_TRIP_FAILURE.ctaText",
    onClose: () => {},
    onConfirm: () => {
      window.location.reload();
    },
    detailsElm: (details: any[]) => {
      return details.map((e) => <Box key={e.errorCode}>{e.desc}</Box>);
    },
  },
  ORDER_SVC_FLIGHT_TIME_CHANGED: {
    type: ErrorType.D,
    title: "ORDER_SVC_FLIGHT_TIME_CHANGED.title",
    desc: "ORDER_SVC_FLIGHT_TIME_CHANGED.desc",
    ctaText: "ORDER_SVC_FLIGHT_TIME_CHANGED.ctaText",
    onClose: () => {},
    onConfirm: () => {
      //dispatch()
      RouterInstance.replace("/:lang/flight-booking/select");
    },
    detailsElm: (details: any[]) => {
      return details.map((e) => <Box key={e.errorCode}>{e.desc}</Box>);
    },
  },
  ORDER_SVC_JOURNEY_NOT_FOUND: {
    type: ErrorType.D,
    title: "ORDER_SVC_JOURNEY_NOT_FOUND.title",
    desc: "ORDER_SVC_JOURNEY_NOT_FOUND.desc",
    ctaText: "ORDER_SVC_JOURNEY_NOT_FOUND.ctaText",
    onClose: () => {},
    onConfirm: () => {
      //dispatch()
      RouterInstance.replace("/:lang/flight-booking/select");
    },
    detailsElm: (details: any[]) => {
      return details.map((e) => <Box key={e.errorCode}>{e.desc}</Box>);
    },
  },
  ORDER_SVC_JOURNEY_TOTAL_AMOUNT_NOT_MATCH: {
    type: ErrorType.D,
    title: "ORDER_SVC_JOURNEY_TOTAL_AMOUNT_NOT_MATCH.title",
    desc: "ORDER_SVC_JOURNEY_TOTAL_AMOUNT_NOT_MATCH.desc",
    ctaText: "ORDER_SVC_JOURNEY_TOTAL_AMOUNT_NOT_MATCH.ctaText",
    onClose: () => {},
    onConfirm: () => {
      //dispatch()
      RouterInstance.replace("/:lang/flight-booking/select");
    },
    detailsElm: (details?: any[]) => {
      return details && details.map((e) => <Box key={e.errorCode}>{e.desc}</Box>);
    },
  },
  ORDER_SVC_SEAT_AMOUNT_NOT_MATCH: {
    type: ErrorType.D,
    title: "ORDER_SVC_SEAT_AMOUNT_NOT_MATCH.title",
    desc: "ORDER_SVC_SEAT_AMOUNT_NOT_MATCH.desc",
    ctaText: "ORDER_SVC_SEAT_AMOUNT_NOT_MATCH.ctaText",
    onClose: () => {},
    onConfirm: () => {
      //dispatch()
      RouterInstance.replace("/:lang/flight-booking/add-extras");
    },
    detailsElm: (details: any[]) => {
      return details.map((e) => <Box key={e.errorCode}>{e.desc}</Box>);
    },
  },
  ORDER_SVC_ORDER_ADD_SEAT_FAIL: {
    type: ErrorType.D,
    title: "ORDER_SVC_ORDER_ADD_SEAT_FAIL.title",
    desc: "ORDER_SVC_ORDER_ADD_SEAT_FAIL.desc",
    onClose: () => {},
    onConfirm: () => {
      //dispatch()
      RouterInstance.replace("/:lang/flight-booking/add-extras");
    },
    detailsElm: (details: any[]) => {
      return details.map((e) => <Box key={e.errorCode}>{e.desc}</Box>);
    },
  },

  // ErrorType.Confirm
  ORDER_SVC_SSR_AMOUNT_NOT_MATCH: {
    type: ErrorType.C,
    title: "ORDER_SVC_SSR_AMOUNT_NOT_MATCH.title",
    desc: "ORDER_SVC_SSR_AMOUNT_NOT_MATCH.desc",
    ctaText: "ORDER_SVC_SSR_AMOUNT_NOT_MATCH.ctaText",
    image: "ORDER_SVC_SSR_AMOUNT_NOT_MATCH.image",
    onClose: () => {},
    onConfirm: () => {
      //dispatch()
      RouterInstance.replace("/:lang/flight-booking/add-extras");
    },
  },
  ORDER_SVC_ORDER_ADD_SSRS_FAIL: {
    type: ErrorType.C,
    title: "ORDER_SVC_ORDER_ADD_SSRS_FAIL.tilte",
    desc: "ORDER_SVC_ORDER_ADD_SSRS_FAIL.desc",
    ctaText: "ORDER_SVC_ORDER_ADD_SSRS_FAIL.ctaText",
    image: "ORDER_SVC_ORDER_ADD_SSRS_FAIL.image",
    onClose: () => {},
    onConfirm: () => {
      //dispatch()
      RouterInstance.replace("/:lang/flight-booking/add-extras");
    },
  },
  ORDER_VALIDATION_ERROR: {
    type: ErrorType.C,
    title: "ORDER_VALIDATION_ERROR.title",
    desc: "ORDER_VALIDATION_ERROR.desc",
    ctaText: "ORDER_VALIDATION_ERROR.ctaText",
    image: "ORDER_VALIDATION_ERROR.image",
    onClose: () => {},
    onConfirm: () => {
      //dispatch()
      RouterInstance.replace("/:lang");
    },
  },
  ORDER_SVC_ORDER_INSUFFICIENT_MILES: {
    type: ErrorType.C,
    title: "ORDER_SVC_ORDER_INSUFFICIENT_MILES.title",
    desc: "ORDER_SVC_ORDER_INSUFFICIENT_MILES.desc",
    ctaText: "ORDER_SVC_ORDER_INSUFFICIENT_MILES.ctaText",
    image: "ORDER_SVC_ORDER_INSUFFICIENT_MILES.image",
    onClose: () => {},
    onConfirm: () => {
      //dispatch()
      RouterInstance.replace("/:lang");
    },
  },
  ORDER_SVC_INVALID_CASH_AMOUNT: {
    type: ErrorType.C,
    title: "ORDER_SVC_INVALID_CASH_AMOUNT.title",
    desc: "ORDER_SVC_INVALID_CASH_AMOUNT.desc",
    ctaText: "ORDER_SVC_INVALID_CASH_AMOUNT.ctaText",
    image: "ORDER_SVC_INVALID_CASH_AMOUNT.image",
    onClose: () => {},
    onConfirm: () => {
      //dispatch()
      RouterInstance.replace("/:lang");
    },
  },
  ORDER_SVC_ORDER_MAXIMUM_PAYMENT_ATTEMPT_EXCEED: {
    type: ErrorType.C,
    title: "ORDER_SVC_ORDER_MAXIMUM_PAYMENT_ATTEMPT_EXCEED.title",
    desc: "ORDER_SVC_ORDER_MAXIMUM_PAYMENT_ATTEMPT_EXCEED.desc",
    ctaText: "ORDER_SVC_ORDER_MAXIMUM_PAYMENT_ATTEMPT_EXCEED.ctaText",
    image: "ORDER_SVC_ORDER_MAXIMUM_PAYMENT_ATTEMPT_EXCEED.image",
    onClose: () => {},
    onConfirm: () => {
      //dispatch()
      RouterInstance.replace("/:lang");
    },
  },
  ORDER_SVC_INVALID_MCP_AMOUNT: {
    type: ErrorType.C,
    title: "ORDER_SVC_INVALID_MCP_AMOUNT.title",
    desc: "ORDER_SVC_INVALID_MCP_AMOUNT.desc",
    ctaText: "ORDER_SVC_INVALID_MCP_AMOUNT.ctaText",
    image: "ORDER_SVC_INVALID_MCP_AMOUNT.image",
    onClose: () => {},
    onConfirm: () => {
      //dispatch()
      RouterInstance.replace("/:lang");
    },
  },
  INVALID_MILES_AMOUNT: {
    type: ErrorType.C,
    title: "INVALID_MILES_AMOUNT.title",
    desc: "INVALID_MILES_AMOUNT.desc",
    ctaText: "INVALID_MILES_AMOUNT.ctaText",
    image: "INVALID_MILES_AMOUNT.image",
    onClose: () => {},
    onConfirm: () => {
      //dispatch()
      RouterInstance.replace("/:lang");
    },
  },

  // ErrorType.Message
  // B2B
  ORDER_SVC_TOTAL_AMOUNT_NOT_MATCH: {
    // not allowed to go back previous page
    type: ErrorType.M,
    title: "ORDER_SVC_TOTAL_AMOUNT_NOT_MATCH.title",
    desc: "ORDER_SVC_TOTAL_AMOUNT_NOT_MATCH.desc",
    onClose: () => {},
  },
  PAYMENT_FAILURE: {
    type: ErrorType.M,
    title: "PAYMENT_FAILURE.title",
    desc: "PAYMENT_FAILURE.desc",
    onClose: () => {},
  },

  // ErrorType.Function
  ORDER_SVC_CREATE_INSURANCE_QUOTATION_FAIL: {
    type: ErrorType.F,
    fn: () => {
      //dispatch()
    },
  },
  ORDER_SVC_CREATE_CHOOOSE_QUOTATION_FAIL: {
    type: ErrorType.F,
    fn: () => {
      //dispatch()
    },
  },
  ORDER_SVC_GET_ASIA_MILES_INFO_FAIL: {
    type: ErrorType.F,
    fn: () => {
      //dispatch()
    },
  },
};

export const ErrorCodeList = Object.keys(ErrorCodeMapping);

export function getErrorDetail<T, C>(errorCode: string, details?: T[], data?: C): ErrorMessageDetail<T, C> | undefined {
  const errorMessage = { ...ErrorCodeMapping[errorCode], details, data };
  if (errorMessage.type === ErrorType.F) {
    errorMessage.fn && errorMessage.fn();
    return undefined;
  }

  return errorMessage;
}
