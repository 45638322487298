import { ArItem, EFont, ESpacing, Font, Journey, Pax, Tag } from "@hkexpressairwayslimited/ui";
import { Stack } from "@mui/material";
import { useTransContent } from "modules/common/trans-content/transContent";
import { OnBaggageSelectedData } from "..";
import { PassengerSection } from "./PassengerSection";
interface JourneySectionProps {
  title: string;
  journey: Journey;
  passenger: Pax[];
  tripId: string;
  noPreSelection?: boolean;
  preSelectionDisabled?: boolean;
  selectedArItem: ArItem[];
}
export function JourneySection({
  title,
  passenger,
  journey: { origin, destination, fare, arItems, journey_key },
  onBaggageSelect,
  summary,
  tripId,
  noPreSelection = true,
  preSelectionDisabled = false,
  selectedArItem,
}: JourneySectionProps & {
  onBaggageSelect: (data: OnBaggageSelectedData) => void;
  summary?: JSX.Element;
}) {
  const { t } = useTransContent();
  return (
    <Stack rowGap={ESpacing._s}>
      <Font color='purple.default'>{title}</Font>
      <Stack direction='row' spacing={ESpacing._2xs}>
        <Font variant={EFont.h3}>
          {t(`airportCodeToCityName.${origin}`)} {t("web.addExtras.baggage.to")}{" "}
          {t(`airportCodeToCityName.${destination}`)}
        </Font>
        {fare.bundle_offers.map((e) => (
          <Tag key={e.type} variant='promo_message' value={e.type ? t(`${e.type}.title`) : "****"} />
        ))}
      </Stack>
      {summary
        ? summary
        : passenger.map((e) => (
            <PassengerSection
              preSelectionDisabled={preSelectionDisabled}
              selectedArItem={selectedArItem}
              noPreSelection={noPreSelection}
              bundleFare={fare.bundle_offers}
              journeyKey={journey_key}
              key={e.id}
              tripId={tripId}
              passenger={e}
              arItems={arItems}
              onBaggageSelect={onBaggageSelect}
            />
          ))}
    </Stack>
  );
}
