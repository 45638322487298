"use client";

import localStorage from "redux-persist/lib/storage";
import sessionStorage from "redux-persist/lib/storage/session";

interface NoopStorageReturnType {
  getItem: (_key: any) => Promise<null>;
  setItem: (_key: any, value: any) => Promise<any>;
  removeItem: (_key: any) => Promise<void>;
}

const createNoopStorage = (): NoopStorageReturnType => {
  return {
    getItem(_key: any): Promise<null> {
      return Promise.resolve(null);
    },
    setItem(_key: any, value: any): Promise<any> {
      return Promise.resolve(value);
    },
    removeItem(_key: any): Promise<void> {
      return Promise.resolve();
    },
  };
};

export const SessionStorage = typeof window !== "undefined" ? sessionStorage : createNoopStorage();
export const LocalStorage = typeof window !== "undefined" ? localStorage : createNoopStorage();
