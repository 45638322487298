import { CloseIcon, EPixel, ERadius, ESpacing, SvgIcon, lightTheme } from "@hkexpressairwayslimited/ui";
import { Breakpoint, Dialog, DialogContent, IconButton, styled } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { SxProps } from "@mui/system";
import React from "react";

type PopUpModalProps = {
  maxWidth: Breakpoint;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>> | ((open: boolean) => void);
  children: React.ReactNode;
  customHandleClose?: () => void;
  disableClose?: boolean;
  sx?: SxProps<Theme>;
};

const StyledDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    borderRadius: ERadius.r300,
    overflowY: "auto",
    padding: `${ESpacing._lg} ${ESpacing._sm}`,
  },
  "& .MuiDialogContent-root": {
    padding: "1px",
    overflowY: "auto",
  },
});

const PopUpModal = ({ maxWidth, open, setOpen, children, customHandleClose, disableClose, sx }: PopUpModalProps) => {
  function handleClose() {
    setOpen(false);
  }

  return (
    <StyledDialog
      open={open}
      onClose={customHandleClose ? customHandleClose : disableClose ? undefined : handleClose}
      maxWidth={maxWidth}
      fullWidth
      sx={{ ...sx, zIndex: 1300 }}
    >
      {!disableClose ? (
        <IconButton
          aria-label='close'
          onClick={customHandleClose ? customHandleClose : disableClose ? undefined : handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 32,
            color: (theme) => theme.palette.neutral.grey,
          }}
        >
          <SvgIcon sx={{ color: lightTheme.palette.neutral.black, width: EPixel.px32, height: EPixel.px32 }}>
            <CloseIcon />
          </SvgIcon>
        </IconButton>
      ) : null}
      <DialogContent>{children}</DialogContent>
    </StyledDialog>
  );
};

export default PopUpModal;
