"use client";
import {
  AdultIcon,
  ArItem,
  Button,
  Card,
  CardContent,
  CardMedia,
  ChildIcon,
  EFont,
  EPaxType,
  EPixel,
  ESpacing,
  Font,
  Form,
  InfantIcon,
  lightTheme,
  PlusSvg,
  SvgIcon,
  useForm,
  useMediaQueries,
  WarningIcon,
} from "@hkexpressairwayslimited/ui";
import { Box, List, ListItem, Stack } from "@mui/material";
import { useTransContent } from "modules/common/trans-content/transContent";
import { useMemo, useState } from "react";
import WarningBox from "ui/features/manage-my-booking/my-trips/trip-detail/WarningBox";
import { AddExtrasPageProps } from ".";
import PopUpModal from "../flight-select/pop-up-modal/PopUpModal";
import TitleIcon from "../title-icon";
import { BaggageSection } from "./BaggageSection";
import { MealSection } from "./MealSection/";
import { OtherSection } from "./OthersSection/";

export function AddExtrasPage({
  tripDetail,
  passengers,
  tripType,
  totalCost,
  noPreSelection = true,
  noBanner = false,
  noTags = false,
  selectedBaggageArItem,
  onBaggageSelected,
  onBaggageConfirmed = (b: boolean) => {},
  onMealSelected,
  selectedMealArItem,
  onMealConfirmed = (b: boolean, cleanAction?: boolean) => {},
  selectedSportArItem,
  onSportConfirmed = (b: boolean, cleanAction?: boolean) => {},
  onSportSelected,
  selectedUFirstArItem,
  onUFirstConfirmed = (b: boolean) => {},
  onUFirstSelected,
  selectedSimCardArItem,
  onSimCardConfirmed = (b: boolean) => {},
  onSimCardSelected,
  baggageConfirmed,
  mealConfirmed,
  onSubmit,
  notTransferSSR,
  submitLabel,
  onOpenMealDrawer,
  onOpenSportDrawer,
  onOpenUFirstDrawer,
  onOpenSimCardDrawer,
}: AddExtrasPageProps) {
  function handleSubmit() {
    if (!baggageConfirmed || !mealConfirmed) {
      setHasError(true);
    } else {
      onSubmit && onSubmit();
    }
  }
  const formHook = useForm<{}>({
    defaultValues: {},
  });
  const [baggageConfirm, setBaggageConfirm] = useState(baggageConfirmed);
  const [mealConfirm, setMealConfirm] = useState(mealConfirmed);
  const [hasError, setHasError] = useState(false);
  const [modalOpen, setModalOpen] = useState(!!(notTransferSSR && notTransferSSR.length > 0));
  const { t } = useTransContent();
  const noInfantPax = useMemo(() => passengers?.filter((e) => e.paxType !== EPaxType.Infant), [passengers]);
  const { isMobile } = useMediaQueries();

  const notTransferSSRList: Record<string, { ssrs: string[]; paxType: string }> = useMemo(() => {
    const groupedByPaxId = notTransferSSR?.reduce((acc, curr) => {
      curr.ssrs.forEach((ssr: ArItem) => {
        if (ssr.byPax) {
          ssr.byPax.forEach((pax) => {
            const passenger = passengers.find((p) => p.id === pax.paxId);
            const key = `${passenger?.courtesy} ${passenger?.surname} ${passenger?.givenName}`;
            if (!acc[key]) {
              acc[key] = { ssrs: [], paxType: "" };
            }
            acc[key].paxType = passenger?.paxType;
            acc[key].ssrs.push(t(`${ssr.code}.title`) as string);
          });
        }
      });
      if (curr.segments) {
        curr.segments.forEach((segment: { ssrs: ArItem[] }) => {
          segment.ssrs.forEach((ssr: ArItem) => {
            if (ssr.byPax) {
              ssr.byPax.forEach((pax) => {
                const passenger = passengers.find((p) => p.id === pax.paxId);
                const key = `${passenger?.courtesy} ${passenger?.surname} ${passenger?.givenName}`;
                if (!acc[key]) {
                  acc[key] = { ssrs: [], paxType: "" };
                }
                acc[key].paxType = passenger?.paxType;
                acc[key].ssrs.push(t(`${ssr.code}.title`) as string);
              });
            }
          });
        });
      }
      return acc;
    }, {});
    return groupedByPaxId;
  }, [notTransferSSR, passengers, t]);
  return (
    <Box
      sx={{
        marginBottom: ["134px", "134px", "unset"],
        marginTop: ESpacing._md,
      }}
    >
      <TitleIcon icon={<PlusSvg />}>{`${t("web.flightBooking.addExtras.title")}`}</TitleIcon>
      {notTransferSSR && notTransferSSR.length > 0 ? (
        <>
          <WarningBox direction={"column"} borderRadius={EPixel.px16} marginBlock={ESpacing._md}>
            <Box display={"flex"} flexDirection={"row"}>
              <SvgIcon sx={{ width: EPixel.px24, height: EPixel.px24, mr: EPixel.px8 }}>
                <WarningIcon />
              </SvgIcon>
              <Font variant={EFont.p1}>{t("web.flightBook.addExtras.baggage.notTransferSSR.title")}</Font>
            </Box>
            <Box display={"flex"} flexDirection={"column"} marginLeft={ESpacing._md}>
              <Font variant={EFont.p1} color={lightTheme.palette.neutral.black}>
                {t("web.flightBook.addExtras.baggage.notTransferSSR.desc")}
              </Font>
              {Object.entries(notTransferSSRList).map(([passenger, value]) => {
                return (
                  <Box key={passenger} display={"flex"} flexDirection={"column"}>
                    <Box display={"flex"} flexDirection={"row"} marginTop={ESpacing._2xs}>
                      <SvgIcon
                        sx={{ width: EPixel.px24, height: EPixel.px24, mr: EPixel.px8 }}
                        color={lightTheme.palette.neutral.black}
                      >
                        {value.paxType === EPaxType.Adult ? (
                          <AdultIcon />
                        ) : value.paxType === EPaxType.Child ? (
                          <ChildIcon />
                        ) : (
                          <InfantIcon />
                        )}
                      </SvgIcon>
                      <Font variant={EFont.p1} color={lightTheme.palette.neutral.black} fontWeight={"fontWeightBold"}>
                        {passenger}
                      </Font>
                    </Box>
                    <List sx={{ listStyleType: "disc", color: "black", ml: "22px" }}>
                      {value.ssrs.map((ssr, index) => (
                        <ListItem
                          color={lightTheme.palette.neutral.black}
                          key={index}
                          sx={{ display: "list-item", pb: 0 }}
                        >
                          <Font variant={EFont.p1} color={lightTheme.palette.neutral.black}>
                            {ssr}
                          </Font>
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                );
              })}
              <Box display={"flex"} flexDirection={"row"} marginTop={ESpacing._2xs}>
                <Font variant={EFont.p1} color={lightTheme.palette.neutral.black}>
                  {t("web.flightBook.addExtras.baggage.notTransferSSR.text")}
                </Font>
              </Box>
            </Box>
          </WarningBox>
          <PopUpModal maxWidth='xs' open={modalOpen} setOpen={setModalOpen}>
            <Stack display={"flex"}>
              <Box display={"flex"} flexDirection={"row"}>
                <SvgIcon sx={{ width: EPixel.px24, height: EPixel.px24, mr: EPixel.px8 }}>
                  <WarningIcon />
                </SvgIcon>
                <Font variant={EFont.p1}>{t("web.flightBook.addExtras.baggage.notTransferSSR.title")}</Font>
              </Box>
              <Box display={"flex"} flexDirection={"column"} marginLeft={ESpacing._md}>
                <Font variant={EFont.p1} color={lightTheme.palette.neutral.black}>
                  {t("web.flightBook.addExtras.baggage.notTransferSSR.desc")}
                </Font>
                {Object.entries(notTransferSSRList).map(([passenger, value]) => {
                  return (
                    <Box key={passenger} display={"flex"} flexDirection={"column"}>
                      <Box display={"flex"} flexDirection={"row"} marginTop={ESpacing._2xs}>
                        <SvgIcon
                          sx={{ width: EPixel.px24, height: EPixel.px24, mr: EPixel.px8 }}
                          color={lightTheme.palette.neutral.black}
                        >
                          {value.paxType === EPaxType.Adult ? (
                            <AdultIcon />
                          ) : value.paxType === EPaxType.Child ? (
                            <ChildIcon />
                          ) : (
                            <InfantIcon />
                          )}
                        </SvgIcon>
                        <Font variant={EFont.p1} color={lightTheme.palette.neutral.black} fontWeight={"fontWeightBold"}>
                          {passenger}
                        </Font>
                      </Box>
                      <List sx={{ listStyleType: "disc", color: "black", ml: "22px" }}>
                        {value.ssrs.map((ssr, index) => (
                          <ListItem
                            color={lightTheme.palette.neutral.black}
                            key={index}
                            sx={{ display: "list-item", pb: 0 }}
                          >
                            <Font variant={EFont.p1} color={lightTheme.palette.neutral.black}>
                              {ssr}
                            </Font>
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  );
                })}
                <Box display={"flex"} flexDirection={"row"} marginTop={ESpacing._2xs}>
                  <Font variant={EFont.p1} color={lightTheme.palette.neutral.black}>
                    {t("web.flightBook.addExtras.baggage.notTransferSSR.text")}
                  </Font>
                </Box>
              </Box>
              <Button fullWidth onClick={() => setModalOpen(false)}>
                {t("web.flightBooking.seatSelection.popUp.btn.ok")}
              </Button>
            </Stack>
          </PopUpModal>
        </>
      ) : (
        <Card withBGC noPadding sx={{ my: ESpacing._md }} size='l'>
          <CardContent>
            <Stack direction='row'>
              <CardMedia
                component='img'
                src={`${t("web.flightBooking.addExtras.banner.image")}`}
                sx={{ width: "auto", display: ["none", "none", "block"] }}
              />
              <Stack
                className='MediaContent'
                justifyContent='space-between'
                spacing={ESpacing._xs}
                sx={{
                  padding: [
                    `${ESpacing._s} !important`,
                    `${ESpacing._s} !important`,
                    `${ESpacing._sm} ${ESpacing._lg}`,
                  ],
                }}
              >
                <Font variant={EFont.h2}>{t("web.flightBooking.addExtras.banner.content")}</Font>
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      )}
      <Font m={`${ESpacing._sm} 0`} display={noBanner && !isMobile ? "block" : "none"}>
        {t("web.OnlineCheckIn.addExtras.desc")}
      </Font>
      <Font variant={EFont.h2} mt={ESpacing._sm} mb={ESpacing._sm}>
        {t("web.flightBooking.addExtras.title1")}
      </Font>

      <Form formHook={formHook} onSubmit={handleSubmit}>
        <BaggageSection
          noPreSelection={noPreSelection}
          error={hasError && !baggageConfirm}
          onConfirm={(b) => {
            onBaggageConfirmed(b);
            setBaggageConfirm(b);
          }}
          onBaggageSelected={onBaggageSelected}
          tripDetail={tripDetail}
          passengers={noInfantPax}
          tripType={tripType}
          selectedArItem={selectedBaggageArItem}
          baggageConfirmed={baggageConfirmed}
          noTags={noTags}
        />
        <MealSection
          tripId={tripDetail.id}
          tripType={tripType}
          onMealSelected={onMealSelected}
          onOpenMealDrawer={onOpenMealDrawer}
          passengers={noInfantPax}
          error={hasError && !mealConfirm}
          selectedArItem={selectedMealArItem}
          mealConfirmed={mealConfirmed}
          onConfirm={(b, clean) => {
            onMealConfirmed(b, clean);
            setMealConfirm(b);
          }}
          journeys={tripDetail.journeys}
        />
        <OtherSection
          tripDetail={tripDetail}
          tripType={tripType}
          passengers={noInfantPax}
          selectedSportArItem={selectedSportArItem}
          onSportConfirmed={onSportConfirmed}
          onSportSelected={onSportSelected}
          onUFirstConfirmed={onUFirstConfirmed}
          onUFirstSelected={onUFirstSelected}
          selectedUFirstArItem={selectedUFirstArItem}
          selectedSimCardArItem={selectedSimCardArItem}
          onSimCardConfirmed={onSimCardConfirmed}
          onSimCardSelected={onSimCardSelected}
          onOpenSportDrawer={onOpenSportDrawer}
          onOpenUFirstDrawer={onOpenUFirstDrawer}
          onOpenSimCardDrawer={onOpenSimCardDrawer}
        />
        {submitLabel}
      </Form>
    </Box>
  );
}
