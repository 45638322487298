import { endpoints, fetchAPI, getAccessTokenHeader, getApi } from "api";
import { requiredHeader } from "lib/common/service";
import { closeLoadingBackdrop } from "modules/common/loading-backdrop/actions/LoadingBackdropAction";
import store from "store";
import { storeErrorModalDetail } from "store/sessionStorage/slices/errorModalDetail";
import {
  APICreateOrderRequestBody,
  APICreateOrderValidationRequestBody,
  AddVoucherRequestBody,
  CreatePaymentApiProps,
  CreditCMPILookupRequest,
  CreditDataExchangeRequest,
  CreditDataExchangeResponse,
  DeleteVoucherRequestBody,
  PaymentMethod,
  responseApiError,
} from "./definition";
import { ErrorCodeList, getErrorDetail } from "./errorCodeMapping";
const forge = require("node-forge");

export const orderValidation = async (data: APICreateOrderValidationRequestBody) => {
  const result = await fetchAPI(endpoints.orderValidation, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...requiredHeader(),
      nsk_token: store.getState().session.nskToken.nsk_token,
    },
    body: JSON.stringify(data),
    errorCodeWhitelist: ErrorCodeList,
  }).catch((error: responseApiError) => {
    const errorDetail = getErrorDetail(error.error_code);
    if (errorDetail) {
      store.dispatch(storeErrorModalDetail(errorDetail));
      store.dispatch(closeLoadingBackdrop());
    }
  });
  return result;
};

export const createOrder = async (data: APICreateOrderRequestBody, captcha?: boolean) => {
  const result = await fetchAPI(endpoints.order, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...requiredHeader(),
      nsk_token: store.getState().session.nskToken.nsk_token,
    },
    body: JSON.stringify(data),
    errorCodeWhitelist: ErrorCodeList,
    captcha: window.hCaptchaConfig?.isEnable,
  }).catch((error: responseApiError) => {
    const errorDetail = getErrorDetail(error.error_code);
    if (errorDetail) {
      store.dispatch(storeErrorModalDetail(errorDetail));
      store.dispatch(closeLoadingBackdrop());
    }
  });

  return result;
};

export const getOrderStatus = async (orderId: string, b2b?: boolean) => {
  const accessTokenHeader = getAccessTokenHeader();

  const headers = b2b
    ? {
        "Content-Type": "application/json",
        ...requiredHeader(),
        ...accessTokenHeader,
      }
    : {
        "Content-Type": "application/json",
        ...requiredHeader(),
        nsk_token: store.getState().session.nskToken.nsk_token,
      };

  const result = await fetchAPI(`${endpoints.getOrderStatus}/${orderId}/polling-status`, {
    method: "GET",
    cache: "no-store",
    headers: headers,
    errorCodeWhitelist: ErrorCodeList,
    showBackDrop: false,
  }).catch((error: responseApiError) => {
    const errorDetail = getErrorDetail(error.error_code);
    if (errorDetail) {
      store.dispatch(storeErrorModalDetail(errorDetail));
      store.dispatch(closeLoadingBackdrop());
    }
  });

  return result;
};

export const createPayment = async (data: CreatePaymentApiProps, b2b?: boolean) => {
  const accessTokenHeader = getAccessTokenHeader();
  const headers = b2b
    ? {
        "Content-Type": "application/json",
        ...requiredHeader(),
        ...accessTokenHeader,
      }
    : {
        "Content-Type": "application/json",
        ...requiredHeader(),
        nsk_token: store.getState().session.nskToken.nsk_token,
      };

  const result = await fetchAPI(endpoints.createPayment, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data),
  });

  return result;
};
export type AddVoucherResp = {
  added_vouchers: { currency_code: string; voucher_reference_code: string; redeemed_amount: number }[];
};
export const addVoucher = async (body: AddVoucherRequestBody) => {
  return await fetchAPI(endpoints.voucher, {
    method: "POST",
    cache: "no-store",
    headers: {
      "Content-Type": "application/json",
      nsk_token: store.getState().session.nskToken.nsk_token,
    },
    body: JSON.stringify(body),
  });
};
export type DeleteVoucherResp = {
  remaining_vouchers: { voucher_reference_code: string; redeemed_amount: number; remaining_amount: number }[];
};
export const deleteVoucher = async (body: DeleteVoucherRequestBody): Promise<DeleteVoucherResp> => {
  return await fetchAPI(endpoints.voucher, {
    method: "PUT",
    cache: "no-store",
    headers: {
      "Content-Type": "application/json",
      nsk_token: store.getState().session.nskToken.nsk_token,
    },
    body: JSON.stringify(body),
  });
};
export type CCEncryptionToken = {
  kid: string;
  alg: string;
  kty: string;
  use: string;
  n: string;
  e: string;
};

export const getCCEncryptionToken = async (): Promise<CCEncryptionToken> => {
  return await getApi(endpoints.endpointPaymentHost + "/v1/payment/key-discovery");
};
export const createCCpayment = async (body: any, b2b?: boolean) => {
  const accessTokenHeader = getAccessTokenHeader();
  const headers = b2b
    ? {
        "Content-Type": "application/json",
        ...requiredHeader(),
        ...accessTokenHeader,
      }
    : {
        "Content-Type": "application/json",
        ...requiredHeader(),
        nsk_token: store.getState().session.nskToken.nsk_token,
      };

  return await fetchAPI(endpoints.endpointPaymentHost + "/external/v1/payment/create-payment", {
    method: "POST",
    cache: "no-store",
    headers: headers,
    body: JSON.stringify(body),
  });
};

export function encryptWithJwk(jwk: CCEncryptionToken, data: any) {
  data = forge.util.createBuffer(data, "utf8");

  // turn jwk.n and jwk.e from base64url to base64
  const n = base64url_to_hex(jwk.n);
  const e = base64url_to_hex(jwk.e);

  const publicKey = forge.rsa.setPublicKey(new forge.jsbn.BigInteger(n, 16), new forge.jsbn.BigInteger(e, 16));

  const encrypted = publicKey.encrypt(data.bytes(), "RSA-OAEP", {
    md: forge.md.sha256.create(),
    mgf1: {
      md: forge.md.sha1.create(),
    },
  });

  return forge.util.encode64(encrypted);
}

function base64url_to_base64(input: string) {
  // Replace non-url compatible chars with base64 standard chars
  input = input.replace(/-/g, "+").replace(/_/g, "/");

  // Pad out with standard base64 required padding characters
  const pad = input.length % 4;
  if (pad) {
    if (pad === 1) {
      throw new Error("InvalidLengthError: Input base64url string is the wrong length to determine padding");
    }
    input += new Array(5 - pad).join("=");
  }

  return input;
}

function base64url_to_hex(x: string) {
  const b64 = base64url_to_base64(x);
  return forge.util.createBuffer(atob(b64), "raw").toHex();
}

export function generateSignature(cn: string, ce: string, cc: string, nonce: string, timestamp: string) {
  const md = forge.md.sha256.create();
  const md2 = forge.md.sha256.create();
  md2.update(cn + ce + cc + nonce + timestamp);
  md.update(md2.digest().toHex());
  return md.digest().toHex();
}

export const creditDataExchange = async (data: CreditDataExchangeRequest): Promise<CreditDataExchangeResponse> => {
  const result = await fetchAPI(`${endpoints.endpointPaymentHost}/v1/cardinal/data-exchange-api-get-info`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
  return result;
};

export type creditCMPILookupResp = {
  frictionless?: boolean;
  step_up_url: string;
  three_dsversion: string;
  md: string;
  jwt: string;
};
export const getCreditCMPILookup = async (data: CreditCMPILookupRequest | any): Promise<creditCMPILookupResp> => {
  const result = await fetchAPI(`${endpoints.endpointPaymentHost}/v1/cardinal/cmpi-look-up`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
  return result;
};

export type DCCQuote = {
  currency_code: string;
  amount: number;
  card_number: string;
  payment_method: PaymentMethod;
};
export type DCCQuoteResp = {
  convert_amount: number;
  convert_currency_code: PaymentMethod;
  dcc_key: string;
  dcc_accept_offer: boolean;
  original_amount: number;
  original_currency_code: PaymentMethod;
  rate: number;
};
export const getDccQuote = async (data: DCCQuote): Promise<DCCQuoteResp> => {
  const result = await fetchAPI(
    `${endpoints.endpointPaymentHost}/external/v1/payment/get-dcc-quote`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        nsk_token: store.getState().session.nskToken.nsk_token,
      },
      body: JSON.stringify({
        ...data,
      }),
      // errorCodeWhitelist: ["PAY_NSK_001"],
    },
    true
  ).catch(() => {});
  return { ...result, dcc_accept_offer: true };
};

export const MPCOtpVerification = async (order_id: string, otp: string) => {
  return await fetchAPI(`${endpoints.endpointPaymentHost}/v1/payment/miles-otp`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      nsk_token: store.getState().session.nskToken.nsk_token,
    },
    body: JSON.stringify({
      order_id,
      otp,
    }),
  });
};
