"use client";
import { getNextLocaleUrl } from "@hkexpressairwayslimited/ui";
import { i18n } from "i18next";
import _ from "lodash";
import { DEFAULT_LNG, SUPPORTED_LANGUAGES_WITH_LOCALES } from "modules/common/i18n/conf";
import {
  closeLoadingBackdrop,
  showLoadingBackdrop,
} from "modules/common/loading-backdrop/actions/LoadingBackdropAction";
import { RouterInstance } from "router/router-utils";
import store from "store";

export class I18nInstance {
  private static i18n: i18n;

  static getI18nInstance(): i18n {
    return I18nInstance.i18n;
  }

  static setI18nInstance(i18n: i18n) {
    I18nInstance.i18n = i18n;
  }
}

/**
 * return a content by cotnent key
 * - Please note that this function doesn't support rerender.
 * - Please make sure you are using 'const { t } = useTranslation();' in component.
 * @param key the content key
 */
export function t(key: string, defaultVal?: string) {
  const content = I18nInstance.getI18nInstance().t(key);
  if (content === key && defaultVal) {
    return defaultVal;
  }
  return content;
}

/**
 * change language to i18n
 * @param langLocale - accept format like 'en-HK' but NOT 'en_HK'.
 */
export function changeLang(lang: string, currentPathName: string, query?: string) {
  // console.log(lang, currentPathName)
  // const paths = currentPathName.split('/')
  // console.log(paths)
  // paths.splice(1, 1, lang)
  // console.log(paths.join('/'))
  // Ask NextJS Router to update route to corrspondend language
  const nextPathname = getNextLocaleUrl({
    pathname: currentPathName,
    locale: lang,
    locales: SUPPORTED_LANGUAGES_WITH_LOCALES,
    defaultLocale: DEFAULT_LNG,
    query,
  });
  if (nextPathname !== currentPathName) {
    RouterInstance.getRouterInstance().replace(
      // NextJS support both '/index' or '/en/index' as default language.
      // Special handling for default language to keep language selector always in URL.
      nextPathname
    );
  }

  // Ask i18next to load language content if not loaded
  // if (!isResourceLoaded(lang)) {
  //   loadResource(lang);
  // }
}

export function isResourceLoaded(lang: string) {
  return !_.isEmpty(I18nInstance.getI18nInstance().getResourceBundle(lang, "translation"));
}

export function loadResource(lang: string) {
  store.dispatch(showLoadingBackdrop());
  I18nInstance.getI18nInstance().reloadResources(lang, undefined, () => {
    store.dispatch(closeLoadingBackdrop());
  });
}

/**
 * return language and locale
 * e.g. en-HK
 */
export function getLangLocale(i18n?: any) {
  if (I18nInstance.getI18nInstance()) {
    return I18nInstance.getI18nInstance().language || DEFAULT_LNG;
  } else if (i18n) {
    return i18n.language || DEFAULT_LNG;
  } else {
    return DEFAULT_LNG;
  }
}

export function getLangLocaleWithMappingAsHKE() {
  const locale = getLangLocale();

  switch (locale) {
    case "en":
      return "en-US";
    case "en-HK":
      return "en-US";
    case "zh-HK":
      return "zh-HK";
    case "zh-TW":
      return "zh-HK";
    case "zh-CN":
      return "zh-CN";
    case "ko":
      return "ko-KR";
    case "ja":
      return "ja-JP";
    default:
      return locale;
  }
}
