import {
  BicycleSvg,
  Button,
  ButtonVariant,
  Card,
  CardContent,
  CardHeader,
  EFont,
  ESpacing,
  ETripType,
  Font,
  IBEPax,
  Journey,
  SimCardIcon,
  SvgIcon,
  UFirstIcon,
} from "@hkexpressairwayslimited/ui";
import { Stack } from "@mui/material";
import { getTripTitle } from "lib/features/flight-book/helper.ts";
import { useTransContent } from "modules/common/trans-content/transContent";
import { EArItemType, EOtherArCode } from "../d";
export * from "./otherSection.tsx";
export type OnSportSelectedData = { tripId: string; journeyKey: string; paxId: string; added: boolean; code: string };
export type OnUFirstSelectedData = {
  tripId: string;
  journeyKey: string;
  added: boolean;
  passengers: IBEPax[];
  code: string;
};
export type OnSimCardSelectedData = { tripId: string; paxId: string; added: boolean; code: string };
export const otherArItemConf: { [k in EOtherArCode]: { avatar: JSX.Element; title: string; des: string } } = {
  [EOtherArCode.SPORT_EQUIPMENT]: {
    avatar: <BicycleSvg />,
    title: "web.flightBooking.otherSection.sportEquipment.title",
    des: "web.flightBooking.otherSection.sportEquipment.desc",
  },
  [EOtherArCode.U_FIRST]: {
    avatar: <UFirstIcon />,
    title: "web.flightBooking.otherSection.uFirst.title",
    des: "web.flightBooking.otherSection.uFirst.desc",
  },
  [EOtherArCode.SIM_CARD]: {
    avatar: <SimCardIcon />,
    title: "web.flightBooking.otherSection.simCard.title",
    des: "web.flightBooking.otherSection.simCard.title",
  },
  [EOtherArCode.SIM_CARD_B]: {
    avatar: <SimCardIcon />,
    title: "web.flightBooking.otherSection.simCard.title",
    des: "web.flightBooking.otherSection.simCard.title",
  },
};

export function Summary({ children }: { children: JSX.Element }) {
  return (
    <Card bodC='active' withBGC='primary-light'>
      <CardContent>{children}</CardContent>
    </Card>
  );
}

export function SelectItem({
  code,
  children,
  onClick,
  price,
  totalCost,
  summary,
  isAllPurchased,
}: {
  code: EOtherArCode;
  onClick: () => void;
  children: JSX.Element;
  totalCost: string | JSX.Element;
  summary?: JSX.Element;
  price?: string | JSX.Element;
  isAllPurchased?: boolean;
}) {
  const { avatar, title, des } = otherArItemConf[code];
  const { t } = useTransContent();
  return (
    <>
      <Card sx={{ height: "100%" }}>
        <CardHeader
          avatar={<SvgIcon sx={{ color: "purple.default", width: "32px", height: "32px" }}>{avatar}</SvgIcon>}
          title={
            <Font variant={EFont.h2} fontWeight='fontWeightBold'>
              {t(title)}
            </Font>
          }
        ></CardHeader>
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          {summary ? summary : <Font sx={{ mb: ESpacing._sm }}>{t(des)}</Font>}
          <Card withBGC borderless sx={{ marginTop: ESpacing._s }}>
            <CardContent>
              <Stack direction='row' justifyContent='space-between'>
                {summary ? (
                  <Stack direction='row' alignItems='center' columnGap={ESpacing._2xs}>
                    <Font variant={EFont.p2}>{t("web.addExtras.baggage.subtotal")}</Font>
                    <Font variant={EFont.h3} fontWeight='fontWeightBold'>
                      {totalCost}
                    </Font>
                  </Stack>
                ) : (
                  <Stack direction='row' alignItems='center' columnGap={ESpacing._2xs}>
                    <Font color='purple.default' variant={EFont.h3} fontWeight='fontWeightBold'>
                      {price}
                    </Font>
                    <Font variant={EFont.p2}>{t("web.flightBooking.otherSection.prePax")}</Font>
                  </Stack>
                )}

                <Button
                  disabled={isAllPurchased}
                  onClick={() => onClick && onClick()}
                  variant={ButtonVariant.Secondary}
                >
                  <Font>
                    {summary
                      ? t("web.flightBooking.otherSection.button.edit")
                      : t("web.flightBooking.otherSection.button.add")}
                  </Font>
                </Button>
              </Stack>
            </CardContent>
          </Card>
        </CardContent>
      </Card>
      {children}
    </>
  );
}

export function formatJourneySelectedArItemSummary(journeys: Journey[], type: EArItemType, tripType: ETripType) {
  return journeys.map((e, idx) => ({
    label: getTripTitle(idx, tripType),
    amount: e.arItems.selected
      .filter((e) => e.type === type)
      .reduce((a, c) => {
        if (c.byPax) {
          return a + c.byPax?.reduce((a1, c1) => a1 + c1.amount, 0) ?? 0;
        }
        return a;
      }, 0),
    //   +
    // e.arItems.purchased
    //   .filter((e) => e.type === type)
    //   .reduce((a, c) => {
    //     if (c.byPax) {
    //       return a + c.byPax?.reduce((a1, c1) => a1 + c1.amount, 0) ?? 0;
    //     }
    //     return a;
    //   }, 0),
  }));
}

export function formatJourneySelectedAndPurchasedArItemSummary(
  journeys: Journey[],
  type: EArItemType,
  tripType: ETripType,
  passengers: IBEPax[]
) {
  const passengerId = passengers?.reduce((a: string[], i) => [...a, i.id], []);
  return journeys.map((e, idx) => ({
    label: getTripTitle(e?.index ?? idx, tripType),
    amount:
      e.arItems.selected
        .filter((e) => e.type === type)
        .reduce((a, c) => {
          if (c.byPax) {
            return a + c.byPax?.reduce((a1, c1) => a1 + c1.amount, 0) ?? 0;
          }
          return a;
        }, 0) +
      e.arItems.purchased
        .filter((e) => e.type === type)
        .reduce((a, c) => {
          if (c.byPax) {
            return a + c.byPax?.reduce((a1, c1) => (passengerId?.includes(c1.paxId) ? a1 + c1.amount : a1), 0) ?? 0;
          }
          return a;
        }, 0),
  }));
}

export function formatJourneySelectedAndTransferred(journeys: Journey[], type: EArItemType, tripType: ETripType) {
  return journeys.map((e, idx) => ({
    label: getTripTitle(idx, tripType),
    amount:
      e.arItems.selected
        .filter((e) => e.type === type)
        .reduce((a, c) => {
          if (c.byPax) {
            return a + c.byPax?.reduce((a1, c1) => a1 + c1.amount, 0) ?? 0;
          }
          return a;
        }, 0) +
      e.arItems.purchased
        .filter((e) => e.type === type)
        .reduce((a, c) => {
          if (c.byPax) {
            return a + c.byPax?.reduce((a1, c1) => a1 + c1.amount, 0) ?? 0;
          }
          return a;
        }, 0),
  }));
}
