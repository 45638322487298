import { envConfig } from "env";
import { getDomain, getHostname } from "tldts";

const domain = getDomain(envConfig.bookingHost);
const cookieDomain = (domain ? `.${domain}` : getHostname(envConfig.bookingHost)) || undefined;

const i18nConfig = {
  locales: envConfig.languageList,
  defaultLocale: envConfig.defaultLanguage,
  prefixDefault: true,
  localeCookie: "i18nextLng",
  cookieOptions: {
    maxAge: 365 * 24 * 60 * 60, // 1 year
    domain: cookieDomain,
  },
};

export default i18nConfig;
