import { persistReducer } from "redux-persist";

import { SessionStorage } from "store/ssr-safe-storage";
import { reducer } from "./reducers";

export const sessionPersistConfig = {
  key: "session",
  storage: SessionStorage,
};

export const sessionStorageReducer = persistReducer(sessionPersistConfig, reducer);
