import { createReducer, isAnyOf } from "@reduxjs/toolkit";
import { ErrorModel } from "modules/common/common/types/errorType";
import { closeErrorDialog, showErrorDialog } from "../actions/ErrorDialogAction";

export interface ErrorDialogState {
  isShow: boolean;
  title: string | undefined;
  content: string | undefined;
  onCloseCb: () => void;
  errorModel: ErrorModel | undefined;
}

const initialState = {
  isShow: false,
  title: undefined,
  content: undefined,
  onCloseCb: () => {},
  errorModel: undefined,
} as ErrorDialogState;

export const errorModalReducer = createReducer(initialState, (builder) => {
  builder
    .addMatcher(isAnyOf(showErrorDialog), (state, action) => {
      state.isShow = true;
      state.title = action.payload.title;
      state.content = action.payload.content;
      state.onCloseCb = action.payload.onCloseCb;
      state.errorModel = action.payload.errorModel;
    })
    .addMatcher(isAnyOf(closeErrorDialog), (state, action) => {
      state.isShow = initialState.isShow;
      state.title = initialState.title;
      state.content = initialState.content;
      state.onCloseCb = initialState.onCloseCb;
      state.errorModel = initialState.errorModel;
    });
});
