import {
  COUNTRY_CODE,
  ECourtesy,
  EPaxType,
  ETripType,
  IBEPassengerDetail,
  IBEPax,
  SearchingDetail,
  TripDetail,
} from "@hkexpressairwayslimited/ui";
import { formatDate } from "date-fns";
import { toFixedNumber } from "lib/common/helper";
import { APICreateOrderValidationRequestBody } from "lib/features/flight-book/payment/definition";
import {
  calculateFareItemAmount,
  calculateSurcharge,
  calculateTax,
  getAllSSR,
  getSeats,
} from "lib/features/flight-book/payment/helper";
import { ManageMyBookingTripDetail } from "lib/features/manage-my-booking/my-trips/definition";
import { CurrencyDetail } from "modules/global/currency/d";
import store from "store";
import { ChangeFlightJourneyDate, Fees } from "store/sessionStorage/slices/manageMyBookingSlice";
import { getFlightRoute } from "../booking/service";
import { formateDateFormat2 } from "../flight-select/helper";

const constructOrderValidationRequestBody = async (appCode: string): Promise<APICreateOrderValidationRequestBody> => {
  const passengerDetail: IBEPassengerDetail = store.getState().session.B2B.passengerDetail;
  const tripDetail: TripDetail = store.getState().session.B2B.tripDetail;
  const searchingDetail: SearchingDetail = store.getState().session.B2B.searchingDetail;
  const uoUsername = "";
  const { selectedCurrency }: CurrencyDetail = store.getState().session.currency;

  const ssrs = getAllSSR(tripDetail);

  const infant = passengerDetail.passengers.filter((passenger) => passenger.paxType === EPaxType.Infant);
  const flightRoute = await getFlightRoute();

  return {
    // uo_username: uoUsername,
    promotion_code: searchingDetail.promoCode,
    market:
      flightRoute.sales_port_grouping.find((route: any) =>
        route.ports.some((port: any) => port.airport_code === tripDetail.journeys[0].origin)
      ).market ?? "",
    trip_type:
      searchingDetail.tripType === ETripType.oneWay
        ? "OW"
        : searchingDetail.tripType === ETripType.multiCity
          ? "COMPLEX"
          : "RT",
    selected_currency_code: selectedCurrency,
    currency_code: tripDetail.journeys[0].fare.currency_code,
    customer_country: COUNTRY_CODE.find((e) => e.value === passengerDetail.contactsMobile.countryCode)?.country ?? "",
    infant_total_amount: tripDetail.journeys.reduce((acc, journey) => {
      return (
        acc +
        calculateFareItemAmount(journey.fare, searchingDetail.passenger_count.Infant, "INF") +
        calculateTax(journey.fare, searchingDetail.passenger_count.Infant, "INF")
      );
    }, 0),
    application_code: appCode,
    journeys: tripDetail.journeys.map((journey, index) => ({
      origin_country:
        flightRoute.sales_port_grouping.find((route: any) =>
          route.ports.some((port: any) => port.airport_code === tripDetail.journeys[index].origin)
        ).market ?? "",
      destination_country:
        flightRoute.sales_port_grouping.find((route: any) =>
          route.ports.some((port: any) => port.airport_code === tripDetail.journeys[index].destination)
        ).market ?? "",
      origin_port: journey.segments[0].origin,
      destination_port: journey.segments[journey.segments.length - 1].destination,
      fare_type: journey.fare.bundle_offers[0].type,
      flight_numbers: journey.segments.map((segment) => segment.flight_number),
      departure_date_time: journey.segments[0].departure,
      arrival_date_time: journey.segments[journey.segments.length - 1].arrival,
      journey_key: journey.journey_key,
      fare_availability_key: journey.fare.fare_availability_key ?? "",
      item_amount: toFixedNumber(
        calculateFareItemAmount(journey.fare, searchingDetail.passenger_count.Adult, "ADT") +
          calculateFareItemAmount(journey.fare, searchingDetail.passenger_count.Child, "CHD")
      ),
      surcharge: toFixedNumber(
        calculateSurcharge(journey.fare, searchingDetail.passenger_count.Adult, "ADT") +
          calculateSurcharge(journey.fare, searchingDetail.passenger_count.Child, "CHD")
      ),
      tax: toFixedNumber(
        calculateTax(journey.fare, searchingDetail.passenger_count.Adult, "ADT") +
          calculateTax(journey.fare, searchingDetail.passenger_count.Child, "CHD")
      ),
      is_first_journey: index === 0,
      bundle: {
        bundle_code: journey.fare.bundle_offers[0].bundle_code,
        adult_price: journey.fare.bundle_offers[0].adult_amount * searchingDetail.passenger_count.Adult,
        child_price: journey.fare.bundle_offers[0].child_amount * searchingDetail.passenger_count.Child,
      },
    })),
    passengers: passengerDetail.passengers
      .filter((passenger) => passenger.paxType !== EPaxType.Infant)
      .map((passenger, index) => ({
        title: passenger.courtesy ? passenger.courtesy.toUpperCase() : "",
        first_name: passenger.givenName,
        last_name: passenger.surname,
        date_of_birth: formateDateFormat2(passenger.DOB),
        gender: passenger.courtesy?.toUpperCase()
          ? passenger.courtesy === ECourtesy.Mr || passenger.courtesy === ECourtesy.Master
            ? "MALE"
            : "FEMALE"
          : "",
        passport: "",
        passenger_key: passenger.id,
        passenger_type: passenger.paxType === EPaxType.Adult ? "ADT" : "CHD",
        seats: getSeats(passenger.id, tripDetail),
        infant: infant[index]
          ? {
              title: infant[index]?.courtesy?.toUpperCase() ?? "",
              first_name: infant[index]?.givenName ?? "",
              last_name: infant[index]?.surname ?? "",
              date_of_birth: formateDateFormat2(infant[index]?.DOB) ?? "",
              gender: infant[index]?.courtesy?.toUpperCase()
                ? passenger.courtesy === ECourtesy.Master
                  ? "MALE"
                  : "FEMALE"
                : "",
            }
          : undefined,
        travel_doc: {
          doc_type: passenger.docType,
          issuing_country: passenger.docIssuingCountry,
          doc_nationality: passenger.docNationality,
          doc_number: passenger.docNumber,
          expire_date: formatDate(passenger.docExpirationDate ?? "", "yyyy-MM-dd"),
        },
      })),
    contact_person: {
      language: "en",
      phone_number: {
        country_code: passengerDetail.contactsMobile.countryCode,
        number: passengerDetail.contactsMobile.mobileNumber,
      },
      first_name: passengerDetail.contactsGivenName,
      last_name: passengerDetail.contactsSurname,
      email_address: passengerDetail.contactsEmail,
      title: passengerDetail.contactsCourtesy.toUpperCase(),
    },
    ssrs: ssrs,
  };
};

const constructB2BMMBOrderCreateBody = async ({
  booking,
  cash_amount,
  currency,
  changeFlightTrip,
  appCode,
  pay_by_organization_credit,
}: {
  booking: ManageMyBookingTripDetail;
  cash_amount: number;
  currency: string;
  changeFlightTrip?: TripDetail & {
    passengers: IBEPax[];
    notTransferSSR: any[];
    fees: Fees;
    changeFlightJourneyDate: {
      first_journey?: ChangeFlightJourneyDate;
      second_journey?: ChangeFlightJourneyDate;
    };
  };
  appCode?: string;
  pay_by_organization_credit: boolean;
}) => {
  const sales_port_grouping = (await getFlightRoute()).sales_port_grouping;
  const ssrs = getAllSSR(changeFlightTrip ?? booking);
  return {
    sales_channel: "ONLINE",
    sales_market:
      sales_port_grouping.find((route: any) =>
        route.ports.some((port: any) => port.airport_code === booking.journeys[0].origin)
      )?.market ?? "",
    sales_port: booking.journeys[0].origin,
    sales_reference: booking.sales_reference,
    selected_currency_code: currency,
    cash_amount: cash_amount,
    application_code: appCode ? appCode : store.getState().session.nskToken.app_code,
    dcc_key: null,
    // trip_type:
    //   booking.journeys.length === 1
    //     ? "OW"
    //     : booking.journeys[0].origin === booking.journeys[1].destination
    //       ? "RT"
    //       : "COMPLEX",

    passengers: booking.passengers
      .filter((passenger) => passenger.paxType !== EPaxType.Infant)
      .map((passenger) => ({
        passenger_key: passenger.id,
        seats: getSeats(passenger.id, booking),
      })),
    ssrs: ssrs,
    pay_by_organization_credit: pay_by_organization_credit,
  };
};

export { constructB2BMMBOrderCreateBody, constructOrderValidationRequestBody };
