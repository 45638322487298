import { MlcTokenProfile } from "@hkexpressairwayslimited/ui";
import { createAction } from "@reduxjs/toolkit";
import { MemberDetails } from "./reducers";
import { CxProfile, GeneralProfile, MilesInfo, TokenProfile } from "./types";

const namespace = "MEMBER";

export const FETCH_TOKENS = `${namespace}/FETCH_TOKENS`;
export const fetchTokens = createAction<undefined>(FETCH_TOKENS);

export const INIT_TOKEN_PROFILE = `${namespace}/INIT_TOKEN_PROFILE`;
export const initTokenProfile = createAction<undefined>(INIT_TOKEN_PROFILE);

export const FETCH_CX_PROFILE = `${namespace}/FETCH_CX_PROFILE`;
export const fetchCxProfile = createAction<undefined>(FETCH_CX_PROFILE);

export const SET_TOKEN_PROFILE = `${namespace}/SET_TOKEN_PROFILE`;
export const setTokenProfile = createAction<TokenProfile | undefined>(SET_TOKEN_PROFILE);

export const SET_MLC_TOKEN_PROFILE = `${namespace}/SET_MLC_TOKEN_PROFILE`;
export const setMlcTokenProfile = createAction<MlcTokenProfile | undefined>(SET_MLC_TOKEN_PROFILE);

export const FETCH_PROFILE = `${namespace}/FETCH_PROFILE`;
export const fetchProfile = createAction<undefined>(FETCH_PROFILE);

export const SET_PROFILE = `${namespace}/SET_PROFILE`;
export const setCxProfile = createAction<CxProfile | undefined>(SET_PROFILE);

export const SET_GENERAL_PROFILE = `${namespace}/SET_GENERAL_PROFILE`;
export const setGeneralProfile = createAction<GeneralProfile | undefined>(SET_GENERAL_PROFILE);

export const SET_MILES_INFO = `${namespace}/SET_MILES_INFO`;
export const setMilesInfo = createAction<MilesInfo>(SET_MILES_INFO);

// export const STORE_TRIP_DETAIL = `${namespace}/STORE_FLIGHT_DETAIL`;
// export const storeTripDetail = createAction<TripDetail>(STORE_TRIP_DETAIL);

export const STORE_PASSENGER_DETAIL = `${namespace}/STORE_PASSENGER_FROM_DATA`;
export const storePassengerDetail = createAction<MemberDetails>(STORE_PASSENGER_DETAIL);

// export const UPDATE_STEPPER_INDEX = `${namespace}/UPDATE_STEPPER_INDEX`;
// export const updateStepperActive = createAction<number>(UPDATE_STEPPER_INDEX);
