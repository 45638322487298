import { endpoints, postApi } from "api";
import Cookies from "js-cookie";
import { getMlcAccessToken } from "modules/features/auth/utils/authUtil";
import store from "store";

type GetCxMemberProfileRequest = {
  member_number: string;
};

type GetMemberAndMilesInfoRequest = {
  member_number: string;
  convertible_amount: number;
  currency_code: string;
  application_code: string;
};

export type GetCxMemberProfileRes = Pick<GetMemberAndMilesInfoRes, "member_info">;

export type GetMemberAndMilesInfoRes = {
  member_info: {
    member_number: string;
    member_name: {
      embossed_name: string;
      title: string;
      naming_convention: string;
      given_name: string;
      family_name: string;
    };
    total_miles_available: number;
    expiry_time: number;
    tier_code: string;
    mobile_phone_number: string;
    email: string;
  };
  miles_info: {
    rounding_logic: {
      method: string;
      decimal_place: number;
    };
    min_spending_miles: number;
    max_spending_miles: number;
    max_cash_eq_amount: number;
    currency_conversion_rate: number;
    currency_code: string;
    miles_bands: {
      from: number;
      rate: number;
    }[];
    is_eligible: boolean;
  };
};

export const getCxMemberProfile = async (data: GetCxMemberProfileRequest) => {
  const result = await postApi<GetCxMemberProfileRes>(endpoints.cxMemberProfile, data, {
    "X-MLC-ACCESS-TOKEN": getMlcAccessToken(),
  });

  return result;
};

export const getMemberAndMilesInfo = async (data: GetMemberAndMilesInfoRequest) => {
  const result = await postApi<GetMemberAndMilesInfoRes>(endpoints.memberAndMilesInfo, data, {
    "X-MLC-ACCESS-TOKEN": getMlcAccessToken(),
    nsk_token: store.getState().session.nskToken.nsk_token,
  });

  return result;
};

export const isLogin = (disabled?: boolean) => (disabled === true ? true : Boolean(Cookies.get("access_token")));
