import { createSlice } from "@reduxjs/toolkit";
import { addSeconds } from "date-fns";
import { RootState } from "store";

type nskTokenState = {
  nsk_token: string | undefined;
  expiry_date: Date | undefined;
  app_code: string | undefined;
  expiry_time: number | undefined;
};

const initialState: nskTokenState = {
  nsk_token: undefined,
  expiry_date: undefined,
  app_code: undefined,
  expiry_time: undefined,
};

export const nskTokenSlice = createSlice({
  name: "nskToken",
  initialState,
  reducers: {
    storeNskToken: (state, action) => {
      state.nsk_token = action.payload.nsk_token;
      state.expiry_date = action.payload.expiry_date;
      state.app_code = action.payload.app_code;
      state.expiry_time = action.payload.expiry_time;
    },
    resetNskToken: () => initialState,
    updateNskToken: (state) => {
      if (state.expiry_time) {
        state.expiry_date = addSeconds(new Date(), state.expiry_time - 150);
      }
    },
  },
  selectors: {
    selectNskToken: (storage) => storage,
    selectNskExpiryDate: (storage) => storage.expiry_date,
  },
});

export const { storeNskToken, resetNskToken, updateNskToken } = nskTokenSlice.actions;
export default nskTokenSlice.reducer;
export const { selectNskToken, selectNskExpiryDate } = nskTokenSlice.getSelectors((state: RootState) => {
  return state.session.nskToken;
});
